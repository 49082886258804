import { createClient } from 'contentful'

import { transformQuarter, transformMarketingMaterials } from './transforms'

class ContentfulManager {
  constructor() {
    this.client = createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: 'qdjvwbdpzhpv',
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: 'NZGbubHN-K31wKN2y0564TkfLYCJq4oqsEeYtg9iOWE',
      // https://app.contentful.com/spaces/qdjvwbdpzhpv/settings/environments
      environment:
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : 'master',
    })
  }

  getQuarter = (uid) => {
    return new Promise((resolve, reject) => {
      this.client
        .getEntry(uid, {
          include: 10,
        })
        .then((response) => {
          resolve(transformQuarter(response))
        })
        .catch((error) => {
          console.warn(error)
          reject(error)
        })
    })
  }

  getMarketingMaterials = () => {
    return new Promise((resolve, reject) => {
      this.client
        .getEntries({
          content_type: 'marketingMaterial',
          include: 10,
        })
        .then((response) => {
          resolve(transformMarketingMaterials(response))
        })
        .catch((error) => {
          console.warn(error)
          reject(error)
        })
    })
  }
}

export default new ContentfulManager()
