import React, { useState } from 'react'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Card, Row } from 'antd'
import { Document, Page } from 'react-pdf'

const MAX_WIDTH = 200

export default function PDFView({ data, setDetail, isDetail }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 })

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function onPageLoad({ width, height }) {
    let adjustedWidth = width
    let adjustedHeight = height
    let ratio
    if (width > MAX_WIDTH) {
      ratio = width / height
      adjustedWidth = MAX_WIDTH
      adjustedHeight *= ratio
    }
    setPageDimensions({ width: adjustedWidth, height: adjustedHeight })
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function handleClick() {
    setDetail(data)
  }

  return (
    <Card hoverable>
      <h3 style={{ padding: 0 }} className="ant-card-head-title">
        {data.name}
      </h3>
      {data.description ? (
        <p dangerouslySetInnerHTML={{ __html: data.description }} />
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Document
          file={data.url}
          onLoadSuccess={onDocumentLoadSuccess}
          onClick={isDetail ? undefined : handleClick}
        >
          <Page
            onLoadSuccess={onPageLoad}
            pageNumber={pageNumber}
            width={pageDimensions.width}
            height={pageDimensions.height}
          />
        </Document>
        {numPages > 1 ? (
          <Row
            align="center"
            justify="space-between"
            style={{ paddingTop: 24 }}
          >
            <Button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
            />
            <div>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </div>
            <Button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
            />
          </Row>
        ) : null}
      </div>
    </Card>
  )
}
