/* eslint-disable arrow-body-style */
import React from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Card, Col, Row, Form, Input, Button, message } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Header } from 'antd/lib/layout/layout'
import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import {
  setUser,
  logout,
  setRememberMe,
} from '../../features/session/redux/actions'
import { CLIENT_USER_TYPE } from '../../features/session/redux/types'
import FirebaseManager from '../../FirebaseManager'
import { returnMessageForFirebaseError } from '../../FirebaseManager/helpers'

const LOGO_SOURCE = '/logo.png'

const layout = {}

const tailLayout = {
  wrapperCol: { span: 16 },
}

export default function Login() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)

  const newUser = searchParams.get('newUser')

  const initialParams = React.useMemo(() => {
    const email = searchParams.get('email')
    const hash = searchParams.get('hash')
    if (email) {
      return {
        email: decodeURIComponent(email),
        hash,
      }
    } else {
      return {}
    }
  }, [searchParams])

  React.useEffect(() => {
    ReactGA.event({
      category: 'Login View',
      action: 'login',
    })
    FirebaseManager.logEvent('Login View')
    dispatch(logout())
    dispatch(setUser(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    setLoading(true)
    if (newUser) {
      FirebaseManager.changeUserPassword(
        values.Email,
        values['Current Password'],
        values['New Password']
      )
        .then((user) => {
          dispatch(setRememberMe(values.rememberMe))
          dispatch(setUser(user))
          if (user.attributes.type === CLIENT_USER_TYPE) {
            const redirect = searchParams.get('redirect')
            navigate(redirect || '/', { replace: true })
          } else {
            navigate('/admin/users', { replace: true })
          }
        })
        .catch((error) => {
          console.warn(error)
          message.error(returnMessageForFirebaseError(error))
          setLoading(false)
        })
    } else {
      FirebaseManager.signInWithEmailAndPassword(values.Email, values.Password)
        .then((user) => {
          if (user.attributes?.active) {
            dispatch(setRememberMe(values.rememberMe))
            dispatch(setUser(user))
            if (user.attributes.type === CLIENT_USER_TYPE) {
              const redirect = searchParams.get('redirect')
              navigate(redirect || '/', { replace: true })
            } else {
              navigate('/admin/users', { replace: true })
            }
          } else {
            message.error(
              'User does not exist. Please contact us if you think this is a mistake.'
            )
          }
        })
        .catch((error) => {
          console.warn('Error logging in', error)
          message.error(returnMessageForFirebaseError(error))
          setLoading(false)
        })
    }
  }

  return (
    <div>
      <Header
        className="landing-page-header"
        style={{
          padding: '25px 50px',
          height: 85,
          position: 'fixed',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 900,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img
              src={LOGO_SOURCE}
              style={{ width: 250, objectFit: 'contain' }}
            />
          </a>
        </div>
      </Header>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '100vh' }}
      >
        <Col lg={12} xs={24}>
          <Card bordered title={newUser ? 'Welcome!' : 'Welcome back!'}>
            <Form {...layout} form={form} onFinish={onFinish}>
              <Form.Item
                initialValue={initialParams?.email}
                name="Email"
                labelCol={{ span: 24 }}
                labelWrap
                label="Email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email.',
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              {newUser ? (
                <>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    labelWrap
                    initialValue={initialParams?.hash}
                    name="Current Password"
                    label="Current Password"
                    rules={[
                      {
                        required: true,
                        secure: true,
                        message: 'Please enter your current password.',
                      },
                      {
                        min: 6,
                        message: 'Password must be at least 6 characters.',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="New Password"
                    label="New Password"
                    labelCol={{ span: 24 }}
                    labelWrap
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your new password.',
                      },
                      {
                        min: 6,
                        message: 'Password must be at least 6 characters.',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="Confirm New Password"
                    label="Confirm New Password"
                    labelCol={{ span: 24 }}
                    labelWrap
                    dependencies={['New Password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your new password.',
                      },
                      {
                        min: 6,
                        message: 'Password must be at least 6 characters.',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('New Password') === value
                          ) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              'The passwords that you entered do not match.'
                            )
                          )
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="Password"
                    label="Password"
                    labelCol={{ span: 24 }}
                    labelWrap
                    rules={[
                      {
                        required: true,
                        secure: true,
                        message: 'Please enter your password.',
                      },
                      {
                        min: 6,
                        message: 'Password must be at least 6 characters.',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    {...tailLayout}
                    name="rememberMe"
                    initialValue={{
                      checked: true,
                    }}
                    valuePropName="checked"
                  >
                    <Checkbox>Remember Me</Checkbox>
                  </Form.Item>
                </>
              )}
              <Form.Item {...tailLayout}>
                <Button loading={loading} type="primary" htmlType="submit">
                  {newUser ? 'Sign Up' : 'Login'}
                </Button>
              </Form.Item>
              {newUser ? null : (
                <>
                  <div>
                    Need to create an account?{' '}
                    <Link to="/signup">Advisor Sign Up</Link>
                  </div>
                  <div>
                    Forgot your password?{' '}
                    <Link to="/request-password-reset">Reset Password</Link>
                  </div>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
