import { Empty } from 'antd'

function FPEEmptyState({ description, style }) {
  return (
    <Empty
      description={description}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      style={style}
    />
  )
}

export default FPEEmptyState
