/* eslint-disable arrow-body-style */
import React from 'react'

import { UserOutlined } from '@ant-design/icons'
import { Card, Col, Row, Form, Input, Button, message } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logout, setUser } from '../../features/session/redux/actions'
import FirebaseManager from '../../FirebaseManager'
import { returnMessageForFirebaseError } from '../../FirebaseManager/helpers'

const LOGO_SOURCE = '/logo.png'

const layout = {}

const tailLayout = {
  wrapperCol: { span: 16 },
}

export default function RequestPasswordReset() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ReactGA.event({
      category: 'Request Password Reset View',
      action: 'request password reset',
    })
    FirebaseManager.logEvent('Request Password Reset View')
    dispatch(logout())
    dispatch(setUser(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    setLoading(true)
    FirebaseManager.sendForgotPasswordEmail(values.email)
      .then(() => {
        navigate('/', { replace: true })
        message.success(`Password reset email sent to ${values.email}!`)
      })
      .catch((error) => {
        console.warn(error)
        if (error?.response?.status === 404) {
          message.error("We couldn't find an account for that email.")
        } else {
          message.error(returnMessageForFirebaseError(error))
        }
        setLoading(false)
      })
  }

  return (
    <div>
      <Header
        className="landing-page-header"
        style={{
          padding: '25px 50px',
          height: 85,
          position: 'fixed',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 900,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img
              src={LOGO_SOURCE}
              style={{ width: 250, objectFit: 'contain' }}
            />
          </a>
        </div>
      </Header>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '100vh' }}
      >
        <Col lg={12} xs={24}>
          <Card bordered title="Password Reset">
            <Form {...layout} form={form} onFinish={onFinish}>
              <Form.Item
                name="email"
                labelCol={{ span: 24 }}
                labelWrap
                label="Email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email.',
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button loading={loading} type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
