import React from 'react'

import {
  UploadOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  Upload,
  Button,
  Space,
  message,
  Table,
  Alert,
  ConfigProvider,
  Switch,
  Tooltip,
  Select,
  Modal,
  Input,
  Result,
  Typography,
  Divider,
  Card,
} from 'antd'
import Papa from 'papaparse'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { fetchAllAppData } from '../../../../features/session/redux/operations'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import { returnUserTagName } from '../../../../shared/helpers/returnUserTagColor'
import { returnUserTypeBasedOnCurrentUserRole } from '../../../../shared/helpers/returnUserTypeBasedOnCurrentUserRole'
import { defaultSorter } from '../../../../shared/helpers/tableHelpers'
import useAuth from '../../../../shared/hooks/useAuth'
import TeamCreateForm from '../TeamCreateForm'

const ACCOUNT_EXISTS = 'Account already exists'
const EMAIL_IS_REQUIRED = 'Email is required'
const EMAIL_IS_DUPLICATED = 'Email is duplicated'

const uuidv4 = require('uuid').v4

const ACCEPTABLE_USER_TYPES = [
  AGENCY_ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  CLIENT_USER_TYPE,
]

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

function ImportUsersForm({
  fetchAllAppData,
  currentUser,
  agencies = [],
  advisorsMap = {},
  agenciesMap = {},
  teams = [],
  groupedAdvisors = {},
}) {
  const [sendWelcomeEmails, setSendWelcomeEmails] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [validating, setValidating] = React.useState(false)
  const [remoteErrors, setRemoteErrors] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [usersList, setUsersList] = React.useState([])
  const [userEmails, setUserEmails] = React.useState([])
  const [result, setResult] = React.useState(null)
  const [emailErrors, setEmailErrors] = React.useState({})
  const [masterAdvisorUid, setMasterAdvisorUid] = React.useState(null)
  const [masterFirmUid, setMasterFirmUid] = React.useState(null)
  const [numberOfErrors, setNumberOfErrors] = React.useState(0)
  const [tableUserType, setTableUserType] = React.useState(
    returnInitialTableType
  )
  const [inlineTeamCreateAgencyUid, setInlineTeamCreateAgencyUid] =
    React.useState(null)

  function returnInitialTableType() {
    switch (currentUser?.attributes?.type) {
      case ADMIN_USER_TYPE:
        return ADVISOR_USER_TYPE
      default:
        return CLIENT_USER_TYPE
    }
  }

  const navigate = useNavigate()

  const user = useAuth()

  function renderAgencyOptions() {
    return agencies.map((agency) => {
      return (
        <Select.Option key={`agency_dropdown_${agency.uid}`} value={agency.uid}>
          {agency.name}
        </Select.Option>
      )
    })
  }

  function renderTeamOptions(agencyUid) {
    const filteredTeams = (teams || []).filter((team) => {
      return team.agencyUid === agencyUid
    })
    return filteredTeams.map((team) => {
      return (
        <Select.Option key={`agency_dropdown_${team.uid}`} value={team.uid}>
          {team.name}
        </Select.Option>
      )
    })
  }

  function renderAdvisorOptions() {
    const els = []
    for (var agencyName in groupedAdvisors) {
      const agency = groupedAdvisors[agencyName]
      els.push(
        <Select.OptGroup
          key={`advisor_dropdown_${agencyName}`}
          label={agencyName}
        >
          {agency.advisors.map((advisor) => {
            return (
              <Select.Option
                key={`advisor_dropdown_${advisor.uid}`}
                value={advisor.uid}
              >
                {advisor.displayName}
              </Select.Option>
            )
          })}
        </Select.OptGroup>
      )
    }
    return els
  }

  function assignAdvisorToUser(userUid, advisorUid) {
    const agencyUid = advisorsMap[advisorUid]?.agencyUid
    setUsersList((prevUsers) => {
      const user = prevUsers.find((user) => user.uid === userUid)
      user.advisorUid = advisorUid
      user.agencyUid = agencyUid
      user.error = null
      return [...prevUsers]
    })
  }

  function assignAgencyToUser(userUid, agencyUid) {
    setUsersList((prevUsers) => {
      const user = prevUsers.find((user) => user.uid === userUid)
      user.agencyUid = agencyUid
      user.error = null
      return [...prevUsers]
    })
  }

  function assignTeamToUser(userUid, teamUid) {
    setUsersList((prevUsers) => {
      const user = prevUsers.find((user) => user.uid === userUid)
      user.teamUid = teamUid
      // user.error = null
      return [...prevUsers]
    })
  }

  function handleChangeAllAdvisors(e) {
    const uid = e || null
    setMasterAdvisorUid(uid)
    if (uid) {
      const agencyUid = advisorsMap[uid]?.agencyUid
      setUsersList((prevUsers) => {
        prevUsers = prevUsers.map((user) => {
          user.advisorUid = uid
          user.teamUid = null
          user.agencyUid = agencyUid
          user.error = null
          return user
        })
        return [...prevUsers]
      })
    }
  }

  function handleChangeAllFirms(e) {
    const uid = e || null
    setMasterFirmUid(uid)
    if (uid) {
      setUsersList((prevUsers) => {
        prevUsers = prevUsers.map((user) => {
          user.agencyUid = uid
          user.teamUid = null
          user.error = null
          return user
        })
        return [...prevUsers]
      })
    }
  }

  function setUserProperty(userUid, key, value) {
    setUsersList((prevUsers) => {
      const user = prevUsers.find((user) => user.uid === userUid)
      user[key] = value
      return [...prevUsers]
    })
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'First Name',
      uid: 'First Name',
      sorter: (a, b) => {
        return defaultSorter(a, b, 'First Name')
      },
      render(text, record) {
        return {
          props: {
            style: {
              backgroundColor: record.error?.['First Name']
                ? '#fff2f0'
                : undefined,
            },
          },
          children:
            !text || record.error?.['First Name'] ? (
              <div>
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 75px)' }}
                    onChange={(e) => {
                      setUserProperty(record.uid, 'First Name', e.target.value)
                    }}
                  />
                  <Button
                    style={{ width: 75 }}
                    status={record.error?.['First Name'] ? 'error' : undefined}
                    placeholder="First Name"
                    type="primary"
                    onClick={() => {
                      setUserProperty(record.uid, 'error', null)
                    }}
                  >
                    Save
                  </Button>
                </Input.Group>
                <div style={{ color: '#cf1322' }}>
                  {record.error?.['First Name']}
                </div>
              </div>
            ) : (
              text
            ),
        }
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'Last Name',
      uid: 'Last Name',
      sorter: (a, b) => {
        return defaultSorter(a, b, 'Last Name')
      },
      render(text, record) {
        return {
          props: {
            style: {
              backgroundColor: record.error?.['Last Name']
                ? '#fff2f0'
                : undefined,
            },
          },
          children:
            !text || record.error?.['Last Name'] ? (
              <>
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 75px)' }}
                    onChange={(e) => {
                      setUserProperty(record.uid, 'Last Name', e.target.value)
                    }}
                  />
                  <Button
                    style={{ width: 75 }}
                    status={record.error?.['Last Name'] ? 'error' : undefined}
                    placeholder="Last Name"
                    type="primary"
                    onClick={() => {
                      setUserProperty(record.uid, 'error', null)
                    }}
                  >
                    Save
                  </Button>
                </Input.Group>
                <div style={{ color: '#cf1322' }}>
                  {record.error?.['Last Name']}
                </div>
              </>
            ) : (
              text
            ),
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      uid: 'Email',
      sorter: (a, b) => {
        return defaultSorter(a, b, 'Email')
      },
      render(text, record) {
        return {
          children:
            !text || record.error?.['Email'] ? (
              <>
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 75px)' }}
                    onChange={(e) => {
                      setUserProperty(record.uid, 'Email', e.target.value)
                    }}
                  />
                  <Button
                    style={{ width: 75 }}
                    status={record.error?.['Email'] ? 'error' : undefined}
                    placeholder="Email"
                    type="primary"
                    onClick={() => {
                      setUserProperty(record.uid, 'error', null)
                    }}
                  >
                    Save
                  </Button>
                </Input.Group>
                <div style={{ color: '#cf1322' }}>
                  {record.error?.['Email']}
                </div>
              </>
            ) : (
              text
            ),
        }
      },
    },
    currentUser?.attributes?.type === ADMIN_USER_TYPE &&
      [ADVISOR_USER_TYPE].includes(tableUserType) && {
        title: 'Calendly Username',
        dataIndex: 'calendlyUsername',
        uid: 'calendlyUsername',
        render(text, record) {
          return {
            children: (
              <Input
                placeholder="janesmith"
                style={{ width: 'calc(100% - 75px)' }}
                onChange={(e) => {
                  setUserProperty(
                    record.uid,
                    'calendlyUsername',
                    e.target.value
                  )
                }}
              />
            ),
          }
        },
      },
    currentUser?.attributes?.type === ADMIN_USER_TYPE &&
      [ADVISOR_USER_TYPE].includes(tableUserType) && {
        title: 'Calendly URL',
        dataIndex: 'primarySchedulingUrl',
        uid: 'primarySchedulingUrl',
        render(text, record) {
          return {
            children: (
              <Input
                placeholder="https://calendly.com/janesmith/fpe"
                style={{ width: 'calc(100% - 75px)' }}
                onChange={(e) => {
                  setUserProperty(
                    record.uid,
                    'primarySchedulingUrl',
                    e.target.value
                  )
                }}
              />
            ),
          }
        },
      },
    currentUser?.attributes?.type === ADMIN_USER_TYPE &&
      [AGENCY_ADMIN_USER_TYPE, ADVISOR_USER_TYPE].includes(tableUserType) && {
        title: 'Firm',
        dataIndex: 'agencyUid',
        uid: 'agencyUid',
        render(text, record) {
          return {
            children: (
              <>
                <ConfigProvider
                  renderEmpty={() => (
                    <FPEEmptyState description="Could not find any firms" />
                  )}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }}
                    style={{ width: '100%' }}
                    status={record.error?.['agencyUid'] ? 'error' : undefined}
                    placeholder="Assign a firm"
                    value={record.agencyUid}
                    onChange={(value) => {
                      setMasterFirmUid(null)
                      assignAgencyToUser(record.uid, value)
                    }}
                  >
                    {renderAgencyOptions()}
                  </Select>
                </ConfigProvider>
                {record.error?.['agencyUid'] ? (
                  <div style={{ color: '#cf1322' }}>Please assign a firm</div>
                ) : null}
              </>
            ),
          }
        },
      },
    currentUser?.attributes?.type === ADMIN_USER_TYPE &&
      [ADVISOR_USER_TYPE].includes(tableUserType) && {
        title: 'Team',
        dataIndex: 'teamUid',
        uid: 'teamUid',
        render(text, record) {
          return {
            children: (
              <>
                <ConfigProvider
                  renderEmpty={() => (
                    <FPEEmptyState description="Could not find any teams" />
                  )}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }}
                    style={{ width: '100%' }}
                    status={record.error?.['teamUid'] ? 'error' : undefined}
                    placeholder="Assign a team"
                    value={record.teamUid}
                    onChange={(value) => {
                      assignTeamToUser(record.uid, value)
                    }}
                    disabled={!record.agencyUid}
                    dropdownRender={(menu) => (
                      <>
                        <Space
                          align="center"
                          style={{ padding: '5px 10px 7px 10px' }}
                        >
                          <Typography.Link
                            onClick={() => {
                              handleAddNewTeam(record.uid, record.agencyUid)
                            }}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <PlusOutlined /> Add Team
                          </Typography.Link>
                        </Space>
                        <Divider style={{ margin: 0 }} />
                        {menu}
                      </>
                    )}
                  >
                    {renderTeamOptions(record.agencyUid)}
                  </Select>
                </ConfigProvider>
                {record.error?.['teamUid'] ? (
                  <div style={{ color: '#cf1322' }}>Please assign a team</div>
                ) : null}
              </>
            ),
          }
        },
      },
    currentUser?.attributes?.type === ADMIN_USER_TYPE &&
      [CLIENT_USER_TYPE].includes(tableUserType) && {
        title: 'Financial Planner',
        dataIndex: 'advisorUid',
        uid: 'advisorUid',
        render(text, record) {
          return {
            children: (
              <>
                <ConfigProvider
                  renderEmpty={() => (
                    <FPEEmptyState description="Could not find any financial planners" />
                  )}
                >
                  <Select
                    style={{ width: '100%' }}
                    status={record.error?.['advisorUid'] ? 'error' : undefined}
                    placeholder="Assign a financial planner"
                    value={record.advisorUid}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      if (option.children) {
                        return option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                          ? true
                          : false
                      } else if (option.label) {
                        return option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                          ? true
                          : false
                      }
                    }}
                    onChange={(value) => {
                      setMasterAdvisorUid(null)
                      assignAdvisorToUser(record.uid, value)
                    }}
                  >
                    {renderAdvisorOptions()}
                  </Select>
                </ConfigProvider>
                {record.error?.['advisorUid'] ? (
                  <div style={{ color: '#cf1322' }}>
                    Please assign a financial planner
                  </div>
                ) : null}
              </>
            ),
          }
        },
      },
  ].filter((x) => x)

  // https://www.papaparse.com/docs#config
  const csvConfig = {
    header: true,
    skipEmptyLines: true,
    error: () => {
      message.error('Unable to parse CSV')
      setLoading(false)
      setValidating(false)
    },
    complete: (results /* file */) => {
      setLoading(false)
      if (results.data > 1000) {
        message.error(
          'We only support importing a maximum of 1000 users at a time'
        )
      } else {
        preValidateUsersList(results.data || []).catch((error) => {
          console.warn(error)
          message.error(
            'Unable to validate CSV. Please make sure your CSV format matches the sample CSV.'
          )
          setValidating(false)
          setLoading(false)
        })
      }
    },
  }

  function handleAddNewTeam(userUid, agencyUid) {
    setInlineTeamCreateAgencyUid({ userUid, agencyUid })
  }

  function postValidateUsersList(data) {
    let errorCount = 0
    data = data.map((user) => {
      user.error = {}
      if (
        currentUser?.attributes?.type === ADMIN_USER_TYPE &&
        [ADVISOR_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(tableUserType) &&
        !user.agencyUid
      ) {
        errorCount += 1
        user.error = {
          ...user.error,
          agencyUid: 'Firm is required',
        }
      }
      if (
        currentUser?.attributes?.type === ADMIN_USER_TYPE &&
        [CLIENT_USER_TYPE].includes(tableUserType) &&
        !user.advisorUid
      ) {
        errorCount += 1
        user.error = {
          ...user.error,
          advisorUid: 'Financial planner is required',
        }
      }
      return user
    })
    if (errorCount) {
      setUsersList(data)
      return true
    } else {
      return false
    }
  }

  async function preValidateUsersList(data, shouldSetLoading = true) {
    return new Promise((resolve, reject) => {
      setValidating(true)
      setRemoteErrors(null)
      FirebaseManager.getAllUserEmails()
        .then((response) => {
          const localEmailErrors = {}
          setUserEmails(response)
          const existingUserEmails = response
          data = data.map((user) => {
            return {
              uid: uuidv4(),
              ...user,
            }
          })
          const runningListOfEmails = {}
          let errorCount = 0
          data = data.map((user) => {
            user.error = {}
            if (!user.Email) {
              localEmailErrors[user.uid] = {
                ...user,
                error: EMAIL_IS_REQUIRED,
              }
            }
            if (runningListOfEmails[user.Email]) {
              localEmailErrors[user.uid] = {
                ...user,
                error: EMAIL_IS_DUPLICATED,
              }
            }
            if (existingUserEmails[user.Email]) {
              localEmailErrors[user.uid] = {
                ...user,
                error: ACCOUNT_EXISTS,
              }
            }
            if (!localEmailErrors[user.uid]) {
              if (!user['First Name']) {
                errorCount += 1
                user.error = {
                  ...user.error,
                  'First Name': 'First Name is required',
                }
              }
              if (!user['Last Name']) {
                errorCount += 1
                user.error = {
                  ...user.error,
                  'Last Name': 'Last Name is required',
                }
              }
              if (!validateEmail(user.Email)) {
                errorCount += 1
                user.error = {
                  ...user.error,
                  Email: 'Email is invalid',
                }
              }
              if (
                currentUser?.attributes?.type === ADMIN_USER_TYPE &&
                tableUserType === CLIENT_USER_TYPE &&
                !user.advisorUid
              ) {
                console.log('Financial planner is required')
                errorCount += 1
                user.error = {
                  ...user.error,
                  advisorUid: 'Financial planner is required',
                }
              }
              if (
                [AGENCY_ADMIN_USER_TYPE, ADVISOR_USER_TYPE].includes(
                  tableUserType
                ) &&
                !user.agencyUid
              ) {
                errorCount += 1
                user.error = {
                  ...user.error,
                  agencyUid: 'Firm is required',
                }
              }
            }
            if (user.Email) {
              runningListOfEmails[user.Email] = user
            }
            return user
          })
          setValidating(false)
          if (shouldSetLoading) {
            setLoading(false)
          }
          if (Object.keys(localEmailErrors)?.length) {
            setEmailErrors(localEmailErrors)
            data = data.filter((d) => {
              return !localEmailErrors[d.uid]
            })
          } else if (errorCount > 0) {
            message.warn('Please correct the errors below.')
          }
          setUsersList(data)
          resolve(data)
        })
        .catch((error) => {
          message.error(
            error ||
              'Unable to validate CSV. Please make sure your CSV format matches the sample CSV.'
          )
          setValidating(false)
          setLoading(false)
          reject(error)
        })
    })
  }

  function beginImport() {
    setLoading(true)
    preValidateUsersList(usersList, false)
      .then(() => {
        const finalUsersList = usersList.map((user) => {
          return {
            uid: user.uid,
            email: user.Email,
            firstName: user['First Name'] || '',
            lastName: user['Last Name'] || '',
            primarySchedulingUrl: user.primarySchedulingUrl || '',
            calendlyUsername: user.calendlyUsername || '',
            type: tableUserType,
            active: true,
            advisorUid:
              tableUserType === CLIENT_USER_TYPE ? user.advisorUid : undefined,
            agencyUid: user.agencyUid,
            teamUid: user.teamUid,
          }
        })

        const advisor = advisorsMap[masterAdvisorUid]
        const agency = agenciesMap[advisor?.agencyUid]

        if (!advisor) {
          console.warn('No advisor to upload!')
          return
        }

        if (!agency) {
          console.warn('No agency to upload!')
          return
        }

        FirebaseManager.bulkUploadUsers(
          finalUsersList,
          sendWelcomeEmails,
          currentUser?.displayName,
          currentUser?.email,
          currentUser?.photoURL,
          tableUserType,
          masterAdvisorUid,
          agency?.uid,
          agency?.marketingListId
        )
          .then((response) => {
            if (response.rejectedUsers?.length) {
              setResult(response)
              setNumberOfErrors(0)
              setLoading(false)
              setUsersList([])
              fetchAllAppData()
              setValidating(false)
            } else {
              message.success(
                `${finalUsersList.length} user${
                  finalUsersList.length === 1 ? '' : 's'
                } ${
                  finalUsersList.length === 1 ? 'has' : 'have'
                } been imported!`
              )
              setUsersList([])
              fetchAllAppData()
              navigate('/admin/users')
            }
          })
          .catch((error) => {
            console.warn(error)
            setLoading(false)
            setValidating(false)
            message.error("We were't unable to upload your users")
          })
      })
      .catch((error) => {
        message.error(
          'Unable to validate CSV. Please make sure your CSV format matches the sample CSV.'
        )
        console.warn(error)
        setLoading(false)
        setValidating(false)
      })
  }

  function onFinishInterceptor(values) {
    if (tableUserType === CLIENT_USER_TYPE) {
      Modal.confirm({
        title:
          'Please be advised that by adding these parties to your Member list, you are verifying that these Members are under an active and signed MMLIS Financial Planning Services Agreement.',
        onOk: () => onFinish(values),
        okText: 'Add Member(s)',
      })
    } else {
      onFinish(values)
    }
  }

  const onFinish = (/* values */) => {
    const hasErrors = postValidateUsersList(usersList)
    if (!hasErrors) {
      Modal.confirm({
        title: 'Warning',
        content: (
          <div>
            <p>
              {sendWelcomeEmails
                ? 'Are you sure you want to begin importing? This will register all users and send out welcome emails.'
                : 'Are you sure you want to begin importing? This will register all users (without sending a welcome email).'}
            </p>
          </div>
        ),
        onOk() {
          beginImport()
        },
        cancelText: 'Cancel',
        okText: 'Proceed',
        onCancel() {},
      })
    }
  }

  function returnSampleCSVForUserType() {
    switch (user?.user?.attributes?.type) {
      case ADMIN_USER_TYPE:
        return '/example_users.csv'
      case AGENCY_ADMIN_USER_TYPE:
        return '/example_advisors.csv'
      case ADVISOR_USER_TYPE:
        return '/example_members.csv'
      default:
        return '/example_members.csv'
    }
  }

  const onReset = () => {
    setFile(null)
    setUsersList(null)
    // setNumberOfErrors(0)
    setRemoteErrors(null)
    setLoading(false)
    setValidating(false)
  }

  const readyToUpload = usersList?.length > 0 && !numberOfErrors

  function returnErrorBannerCopy() {
    if (remoteErrors) {
      return (
        <div>
          We tried to import everyone, but some{' '}
          {returnUserTypeBasedOnCurrentUserRole(tableUserType).toLowerCase()}s
          were unsuccessful. Please see the below list of errors. We would
          recommend ensuring your CSV format matches the sample CSV, then trying
          again.
          {remoteErrors.map((error) => {
            return (
              <div key={`error_${error.index}`} style={{ marginTop: 5 }}>
                <strong>{error.user.email}</strong>: {error.error}
              </div>
            )
          })}
        </div>
      )
    } else {
      const errorCountGreaterThanOne = numberOfErrors > 1
      return `There ${
        errorCountGreaterThanOne ? 'are' : 'is'
      } ${numberOfErrors} error${
        errorCountGreaterThanOne ? 's' : ''
      } in your CSV. Highlighted below is user info that needs to be corrected before you upload.`
    }
  }

  function handleSetSendWelcomeEmails(value) {
    if (!value) {
      Modal.warn({
        title: 'Warning',
        content: (
          <div>
            <p>
              Sending a welcome email is the only way a user can get their
              password. This feature is only available for quietly importing v1
              FPE users into the v2 platform.
            </p>
          </div>
        ),
        onOk() {},
      })
    }
    setSendWelcomeEmails(value)
  }

  function handleChangeTableType(e) {
    onReset()
    handleChangeAllAdvisors(null)
    handleChangeAllFirms(null)
    setTableUserType(e)
  }

  function renderImportErrors() {
    return (
      <ul>
        {(result?.rejectedUsers || []).map((user, index) => {
          return (
            <li key={`import_error_${index}`}>
              <strong>
                {`${user?.user?.email}: ${
                  user.error || 'Could not be imported'
                }`.toLowerCase()}
              </strong>
            </li>
          )
        })}
      </ul>
    )
  }

  function renderEmailErrors() {
    const els = []
    var index = 0
    for (var error in emailErrors) {
      if (emailErrors[error].error === ACCOUNT_EXISTS) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error].Email} is already registered`}</strong>
          </li>
        )
      } else if (emailErrors[error].error === EMAIL_IS_REQUIRED) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error]['First Name'] || 'A user'}${
              emailErrors[error]['First Name'] &&
              emailErrors[error]['Last Name']
                ? ` ${emailErrors[error]['Last Name']}`
                : ''
            } does not have an email`}</strong>
          </li>
        )
      } else if (emailErrors[error].error === EMAIL_IS_DUPLICATED) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error]['Email']} exists twice in your CSV. Both records have been removed, so please try removing the duplicate and re-uploading.`}</strong>
          </li>
        )
      }
      index++
    }
    if (els?.length) {
      return <ul style={{ textAlign: 'left' }}>{els}</ul>
    } else {
      return null
    }
  }

  const [windowInnerHeight, setWindowInnerHeight] = React.useState(undefined)
  function handleResize() {
    setWindowInnerHeight(window.innerHeight - 200)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function renderApplyAllSelect() {
    switch (tableUserType) {
      case CLIENT_USER_TYPE:
        return (
          <ConfigProvider
            renderEmpty={() => (
              <FPEEmptyState description="Could not find any financial planners" />
            )}
          >
            <Select
              allowClear
              value={masterAdvisorUid}
              placeholder="Assign financial planner to all members"
              showSearch
              style={{ marginLeft: 10 }}
              onChange={handleChangeAllAdvisors}
              filterOption={(input, option) => {
                if (option.children) {
                  return option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    ? true
                    : false
                } else if (option.label) {
                  return option.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    ? true
                    : false
                }
              }}
            >
              {renderAdvisorOptions()}
            </Select>
          </ConfigProvider>
        )
      case AGENCY_ADMIN_USER_TYPE:
        return (
          <ConfigProvider
            renderEmpty={() => (
              <FPEEmptyState description="Could not find any firms" />
            )}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              value={masterFirmUid}
              placeholder="Assign firm to all firm/team admins"
              style={{ marginLeft: 10 }}
              onChange={handleChangeAllFirms}
            >
              {renderAgencyOptions()}
            </Select>
          </ConfigProvider>
        )
      case ADVISOR_USER_TYPE:
        return (
          <ConfigProvider
            renderEmpty={() => (
              <FPEEmptyState description="Could not find any firms" />
            )}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              value={masterFirmUid}
              placeholder="Assign firm to all financial planners"
              style={{ marginLeft: 10 }}
              onChange={handleChangeAllFirms}
            >
              {renderAgencyOptions()}
            </Select>
          </ConfigProvider>
        )
      default:
        return null
    }
  }

  function renderPreview() {
    return (
      <>
        {numberOfErrors > 0 || remoteErrors ? (
          <div style={{ marginBottom: 15 }}>
            <Alert type="error" message={returnErrorBannerCopy()} />
          </div>
        ) : null}
        <ConfigProvider
          renderEmpty={() => (
            <FPEEmptyState description="Upload a CSV to get started" />
          )}
        >
          <Table
            loading={validating}
            rowKey="uid"
            dataSource={usersList}
            columns={columns}
            pagination={false}
            scroll={{
              x: 600,
              y: windowInnerHeight,
            }}
            title={() => {
              return (
                <Space
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h1
                    style={{
                      margin: 0,
                      fontSize: 19,
                      fontWeight: 'bold',
                    }}
                  >
                    Bulk Import{' '}
                    {currentUser?.attributes?.type !== ADMIN_USER_TYPE ? (
                      `${returnUserTypeBasedOnCurrentUserRole(
                        user?.user?.attributes?.type
                      )}s`
                    ) : (
                      <Select
                        onChange={handleChangeTableType}
                        defaultValue={tableUserType}
                        bordered={false}
                        style={{
                          // width: 150,
                          border: 'none',
                          fontSize: 19,
                          fontWeight: 'bold',
                          color: 'rgba(0, 0, 0, 0.85)',
                          padding: 0,
                          paddingLeft: 0,
                          margin: 0,
                          marginLeft: -10,
                        }}
                      >
                        <Select.Option value={AGENCY_ADMIN_USER_TYPE}>
                          {returnUserTagName(
                            AGENCY_ADMIN_USER_TYPE,
                            false,
                            true
                          )}
                        </Select.Option>
                        <Select.Option value={ADVISOR_USER_TYPE}>
                          {returnUserTagName(ADVISOR_USER_TYPE, false, true)}
                        </Select.Option>
                        <Select.Option value={CLIENT_USER_TYPE}>
                          {returnUserTagName(CLIENT_USER_TYPE, false, true)}
                        </Select.Option>
                      </Select>
                    )}
                    {usersList?.length &&
                    currentUser?.attributes?.type === ADMIN_USER_TYPE
                      ? renderApplyAllSelect()
                      : null}
                  </h1>
                  <Space>
                    {readyToUpload ? (
                      currentUser?.attributes?.type === ADMIN_USER_TYPE ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Tooltip title="This controls whether welcome emails will be sent to this user. This is useful for onboarding users from the FPE v1 platform.">
                            <span style={{ marginRight: 5 }}>
                              <InfoCircleOutlined style={{ marginRight: 5 }} />
                              Send Welcome Email
                            </span>
                          </Tooltip>
                          <Switch
                            defaultChecked={sendWelcomeEmails}
                            onChange={handleSetSendWelcomeEmails}
                          />
                        </div>
                      ) : null
                    ) : (
                      <Link
                        to={returnSampleCSVForUserType()}
                        target="_blank"
                        download
                      >
                        Download sample CSV
                      </Link>
                    )}
                    <Button
                      onClick={onReset}
                      disabled={!usersList?.length || validating || loading}
                    >
                      Reset
                    </Button>
                    {readyToUpload ? (
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={onFinishInterceptor}
                      >
                        Begin Import
                      </Button>
                    ) : (
                      <Upload
                        onRemove={() => {
                          setFile(null)
                        }}
                        showUploadList={false}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        valuePropName="fileList"
                        beforeUpload={(f) => {
                          setFile(f)
                          Papa.parse(f, csvConfig)
                          return false
                        }}
                        fileList={file ? [file] : []}
                        disabled={loading}
                      >
                        <Button
                          type="primary"
                          loading={validating}
                          icon={<UploadOutlined />}
                        >
                          Select CSV File
                        </Button>
                      </Upload>
                    )}
                  </Space>
                </Space>
              )
            }}
          />
        </ConfigProvider>
        <Modal
          visible={!!result}
          centered
          closable={false}
          maskClosable={false}
          okText="Ok"
          onOk={() => navigate('/admin/users')}
        >
          <Result
            status="warning"
            title="Warning"
            subTitle={
              <div>
                <p>
                  {`${result?.users?.length} user${
                    result?.users?.length === 1 ? '' : 's'
                  } ${
                    result?.users?.length === 1 ? 'has' : 'have'
                  } been uploaded, but ${result?.rejectedUsers?.length} user${
                    result?.rejectedUsers?.length === 1 ? '' : 's'
                  } ${
                    result?.rejectedUsers?.length === 1 ? 'was' : 'were'
                  } not able to be uploaded.`}
                </p>
                {renderImportErrors()}
              </div>
            }
          />
        </Modal>
        <Modal
          visible={Object.keys(emailErrors)?.length}
          centered
          closable
          maskClosable
          cancelText="Close"
          onOk={() => setEmailErrors({})}
          onCancel={() => setEmailErrors({})}
        >
          <Result
            title="Warning"
            subTitle={
              <div>
                <p>
                  Some{' '}
                  {returnUserTypeBasedOnCurrentUserRole(
                    currentUser?.attributes?.type
                  ).toLowerCase()}
                  s cannot be imported <strong>and have been removed</strong>:
                </p>
                {renderEmailErrors()}
              </div>
            }
          />
        </Modal>
        <Modal
          visible={inlineTeamCreateAgencyUid?.agencyUid}
          centered
          onCancel={() => setInlineTeamCreateAgencyUid(null)}
          destroyOnClose
          footer={null}
          width={'75%'}
          height={'75%'}
          style={{ padding: 0, height: '100%' }}
          bodyStyle={{ padding: 0, height: '100%' }}
        >
          <TeamCreateForm
            closeInlineAgencyCreateModal={(newTeamUid, forUserId) => {
              assignTeamToUser(forUserId, newTeamUid)
              setInlineTeamCreateAgencyUid(null)
            }}
            inlineAgencyUid={inlineTeamCreateAgencyUid?.agencyUid}
            inlineUserUid={inlineTeamCreateAgencyUid?.userUid}
          />
        </Modal>
      </>
    )
  }

  return <Card bodyStyle={{ padding: 0 }}>{renderPreview()}</Card>
}

function mapStateToProps({
  session: { user = {} },
  agencies: { data: agenciesData = [] },
  teams: { data: teamsData = [] },
  users: { data: usersData = [] },
}) {
  const agenciesMap = {}
  agenciesData.forEach((agency) => {
    agenciesMap[agency.uid] = agency
  })
  const advisors = usersData.filter((user) => {
    return user.type === ADVISOR_USER_TYPE
  })
  const advisorsMap = {}
  const groupedAdvisors = {}
  advisors.forEach((advisor) => {
    advisorsMap[advisor.uid] = advisor
    const agencyName = agenciesMap[advisor.agencyUid]?.name || 'Unassigned'
    if (!groupedAdvisors[agencyName]) {
      groupedAdvisors[agencyName] = {
        advisors: [advisor],
      }
    } else {
      groupedAdvisors[agencyName] = {
        ...groupedAdvisors[agencyName],
        advisors: [...(groupedAdvisors[agencyName].advisors || []), advisor],
      }
    }
  })
  return {
    currentUser: user,
    agencies: agenciesData || [],
    advisors: advisors || [],
    teams: teamsData || [],
    groupedAdvisors: groupedAdvisors || {},
    advisorsMap: advisorsMap || {},
    agenciesMap: agenciesMap || {},
  }
}

const mapDispatchToProps = {
  fetchAllAppData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsersForm)
