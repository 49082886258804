//@refresh reset
import React from 'react'

import {
  ExclamationCircleOutlined,
  UploadOutlined,
  LeftOutlined,
  VideoCameraFilled,
  FilePdfOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Col,
  PageHeader,
  Row,
  Tabs,
  Tag,
  Spin,
  Upload,
  Card,
  Modal,
  Space,
  Progress,
  message,
} from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import moment from 'moment'
import { Document, Page } from 'react-pdf'
import { connect } from 'react-redux'
import Webcam from 'react-webcam'

import { addAdvisorVideo } from '../../../../features/quarters/redux/operations'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
} from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPEVideo from '../../../../shared/components/FPEVideo'
import UserLink from '../../../../shared/components/Links/UserLink'
import placeholders from '../../../../shared/helpers/handlebars'
import 'video.js/dist/video-js.css'

const { TabPane } = Tabs

const uploadFile = async ({ file, filename, onProgress = () => {} }) => {
  return new Promise(async (resolve, reject) => {
    if (!file) {
      console.warn('No media to upload -- skipping')
      resolve('')
    } else {
      FirebaseManager.uploadFile(file, filename, onProgress)
        .then((url) => {
          resolve(url)
        })
        .catch((error) => {
          console.warn(error)
          reject(error)
        })
    }
  })
}

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return seconds === 60
    ? minutes + 1 + ':00'
    : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

const MAX_WIDTH = 400

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

function QuarterDetail({
  quarterMetadata,
  currentUser,
  advisorVideo,
  quarterContent: conversation,
  addAdvisorVideo,
  advisorVideos,
}) {
  const startTimestamp = React.useRef(null)
  const webcamRef = React.useRef(null)
  const mediaRecorderRef = React.useRef(null)
  const thumbnailBlob = React.useRef(null)
  const [uploadingVideo, setUploadingVideo] = React.useState(false)
  const [timestamp, setTimestamp] = React.useState(null)
  const [capturing, setCapturing] = React.useState(false)
  const [previewVideo, setPreviewVideo] = React.useState(false)
  const [uploadProgress, setUploadProgress] = React.useState(0)
  const [recordedChunks, setRecordedChunks] = React.useState([])
  const [detail, setDetail] = React.useState(null)
  const [numPages, setNumPages] = React.useState(null)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [pageDimensions, setPageDimensions] = React.useState({
    width: 0,
    height: 0,
  })
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }
  function onPageLoad({ width, height }) {
    let adjustedWidth = width
    let adjustedHeight = height
    let ratio
    if (width > MAX_WIDTH) {
      ratio = width / height
      adjustedWidth = MAX_WIDTH
      adjustedHeight *= ratio
    }
    setPageDimensions({ width: adjustedWidth, height: adjustedHeight })
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }
  const size = useWindowSize()
  const VIDEO_WIDTH = (9 / 16) * size.width

  const [recordingModalVisible, setRecordingModalVisible] = React.useState(null)

  const [activeTabIndex, setActiveTabIndex] = React.useState(
    currentUser?.attributes?.type === ADVISOR_USER_TYPE ? '1' : '2'
  )

  const [recorderReady, setRecorderReady] = React.useState(false)
  const [video, setVideo] = React.useState(null)

  const playerRef = React.useRef(null)
  const learnMePlayerRef = React.useRef(null)
  const previewVideoPlayerRef = React.useRef(null)
  const advisorVideoPlayerRef = React.useRef(null)

  function handleDataAvailable({ data }) {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }
  }

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      })
      setVideo({
        file: blob,
        source: 'webcam',
      })
    }
  }, [recordedChunks])

  React.useEffect(() => {
    if (recordedChunks?.length > 0) {
      handleDownload()
    }
  }, [handleDownload, recordedChunks])

  React.useEffect(() => {
    if (video) {
      const url = URL.createObjectURL(video?.file)
      setPreviewVideo(url)
      setRecordingModalVisible(video?.source)
      setTimeout(() => {
        try {
          var canvasElement = document.getElementById('canvas')
          var videoElement = document.getElementById('video')
          canvasElement.width = videoElement.width
          canvasElement.height = videoElement.width * (9 / 16)
          canvasElement.style.display = 'none'
          canvasElement
            .getContext('2d')
            .drawImage(
              videoElement,
              0,
              0,
              videoElement.width,
              videoElement.width * (9 / 16)
            )
          canvasElement.toBlob(function (blob) {
            thumbnailBlob.current = blob
            console.log('Got a thumbnail', thumbnailBlob.current)
          }, 'image/png')
        } catch (error) {
          console.warn('Could not get thumbnail', error)
        }
      }, 1000)
    } else {
      setPreviewVideo(null)
    }
  }, [video])

  function handleStartCaptureClick() {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm',
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }

  function handleStopCaptureClick() {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    fill: true,
    sources: [
      {
        thumbnail: conversation?.mindsetMe?.videoThumbnail,
        src: conversation?.mindsetMe?.videoUrl?.src,
        type: conversation?.mindsetMe?.videoUrl?.contentType,
      },
    ],
  }

  function renderArticles() {
    return (
      <div style={{ padding: '0 20px' }}>
        <Alert
          style={{ marginBottom: 20 }}
          message={`These are the ${conversation?.articles?.articles?.length} articles that will be shared with your members on your behalf.`}
          type="info"
        />
        <ul>
          {(conversation?.articles?.articles || []).map((article, index) => {
            return (
              <li key={`article_${article.url}_${index}`}>
                <a target="_blank" rel="noreferrer" href={article.url}>
                  {article.title}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  async function handleRecord() {
    let mediaStream
    try {
      const constraints = { audio: true, video: true }
      mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
    } catch (ex) {
      if (ex instanceof DOMException) {
        if (ex.name === 'NotAllowedError') {
          // handle permission denied
          message.error('You must give camera and microphone access.')
        } else if (ex.name === 'NotFoundError') {
          // handle media not found
          message.error(
            'Could not find a camera and/or microphone. Please contact our team for support.'
          )
        } else {
          // handle unexpected DOMException
          message.error(
            'Unable to launch camera and/or microphone. Please contact our team for support.'
          )
        }
      } else {
        // handle unexpected error
        message.error(
          'Unable to launch camera and/or microphone. Please contact our team for support.'
        )
      }
    }

    if (mediaStream) {
      handleResetVideo()
      setRecordingModalVisible(true)
    }
  }

  function setPDFDetail() {
    setDetail(conversation?.welcomeScriptPdf)
  }

  function renderWelcomeVideo() {
    if (advisorVideo) {
      return (
        <div style={{ margin: '0 20px 20px 20px' }}>
          <Alert
            style={{ marginBottom: 20 }}
            type="info"
            message={'Your video will display on the homepage of your members.'}
          />
          <FPEVideo
            onReady={handleAdvisorVideoReady}
            // maxWidth={VIDEO_WIDTH}
            options={{
              autoplay: true,
              controls: true,
              fill: true,
              // aspectRatio: '16:9',
              sources: [
                {
                  src: advisorVideo?.url,
                  type: advisorVideo?.contentType,
                },
              ],
            }}
          />
        </div>
      )
    } else {
      return (
        <div style={{ padding: '0 20px 20px 20px' }}>
          {advisorVideo ? (
            <Alert
              style={{ marginBottom: 20 }}
              type="info"
              message={
                "This message will be shown on your member's home page at the start of each quarter."
              }
            />
          ) : (
            <h1>Create a Personalized Welcome Message</h1>
          )}
          <div>
            {uploadingVideo ? null : (
              <>
                <p>
                  One of the best ways to create a personal feel to your FPE
                  quarterly updates for your members is for them to hear from
                  you directly! There are two ways we can do this, you can
                  either create a welcome video or use the FPE welcome copy.
                  Below are some directions for each welcome type and it's up to
                  you to decide what you'd like to use on your page!
                </p>
                <p>
                  {/* For the Welcome Video, you can use the options below to either
                  record a video here or upload a pre-recorded video. Once you
                  have uploaded your video, it will be available to all of your
                  members. */}
                  For the Welcome Video, you can upload a pre-recorded video
                  below. Once you have uploaded your video, it will be available
                  to all of your members.
                </p>
              </>
            )}
            <Space direction="horizontal">
              {uploadingVideo ? (
                <div style={{ paddingTop: 5 }}>
                  <div>Uploading video...</div>
                  <i>
                    Please do not leave this screen while your video is
                    uploading.
                  </i>
                  <Progress
                    type="line"
                    percent={Math.ceil(uploadProgress * 100)}
                  />
                </div>
              ) : (
                <Button
                  style={{ marginBottom: 12 }}
                  type="primary"
                  onClick={handleRecord}
                  icon={
                    <span
                      style={{
                        backgroundColor: '#cf1322',
                        width: 10,
                        height: 10,
                        marginRight: 10,
                        borderRadius: 100,
                      }}
                    />
                  }
                >
                  Click to Record
                </Button>
              )}
              <Upload
                accept="video/mp4,video/x-m4v,video/*"
                onRemove={() => {
                  setVideo(null)
                }}
                listType="picture"
                className="upload-list-inline"
                valuePropName="fileList"
                beforeUpload={async (file) => {
                  setVideo({
                    file,
                    source: 'upload',
                  })
                  return false
                }}
                fileList={[]}
              >
                <span>
                  {uploadingVideo ? null : (
                    <span>
                      <Button
                        style={{ marginBottom: 12 }}
                        type="primary"
                        icon={<UploadOutlined />}
                      >
                        Click to Upload
                      </Button>
                    </span>
                  )}
                </span>
              </Upload>
              {conversation?.welcomeScriptPdf && !uploadingVideo ? (
                <Button style={{ marginBottom: 12 }} onClick={setPDFDetail}>
                  View Welcome Script
                </Button>
              ) : null}
            </Space>
            {uploadingVideo ? null : (
              <>
                <p style={{ marginTop: video ? 20 : 0 }}>
                  If you'd like to use the Welcome Script below, no further
                  action required; we will default to the text below.
                </p>
                <p
                  className="block-quote"
                  dangerouslySetInnerHTML={{
                    __html: placeholders(conversation?.welcomeScript, {
                      advisorName: currentUser?.attributes?.displayName,
                    }),
                  }}
                />
              </>
            )}
          </div>
        </div>
      )
    }
  }

  function renderModules() {
    return (
      <div style={{ padding: '0 20px' }}>
        <Alert
          style={{ marginBottom: 20 }}
          message={`These are the ${conversation?.modules?.length} conversations that you should have with your members this quarter.`}
          type="info"
        />
        <ul>
          {(conversation?.modules || []).map((module, index) => {
            return (
              <li
                key={`module_${module.title}_${index}`}
                style={{ marginBottom: 10 }}
              >
                <strong>{module.title}</strong>: {module.description}
                <ul>
                  {module.trainingVideo?.src ? (
                    <li>
                      <a
                        href={module.trainingVideo?.src}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <VideoCameraFilled />
                        <strong>{` ${module.title} Training Video`}</strong>
                      </a>
                    </li>
                  ) : null}
                  {(module.marketingMaterials || []).map((material, index) => {
                    return (
                      <li key={`${module.title}_${material.url}_${index}`}>
                        <a href={material.url} target="_blank" rel="noreferrer">
                          <FilePdfOutlined />
                          {` ${material.name}`}
                        </a>
                        {/* <SelectOutlined
                          style={{ color: 'rgba(0,0,0,0.5)', marginLeft: 5 }}
                        /> */}
                      </li>
                    )
                  })}
                </ul>
                {/* <a href={''}></a> */}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  function handleMindsetPlayerReady(player) {
    playerRef.current = player
  }

  function handleLearnMePlayerReady(player) {
    learnMePlayerRef.current = player
  }

  function handlePreviewVideoReady(player) {
    previewVideoPlayerRef.current = player
  }

  function handleAdvisorVideoReady(player) {
    advisorVideoPlayerRef.current = player
  }

  function renderMindsetMe() {
    return (
      <div style={{ padding: '0 20px 15px 20px' }}>
        <Alert
          style={{ marginBottom: 20 }}
          message={`This is a special quarterly topic dedicated to mindset training.`}
          type="info"
        />
        <h1>{conversation?.mindsetMe?.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: conversation?.mindsetMe?.description,
          }}
        />
        {conversation?.mindsetMe?.embed ? (
          <div
            style={{
              position: 'relative',
              paddingBottom: '56.25%' /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={conversation?.mindsetMe?.embed}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        ) : (
          <FPEVideo
            options={videoJsOptions}
            onReady={handleMindsetPlayerReady}
          />
        )}
      </div>
    )
  }
  function renderFinancialPlannerVideos() {
    return (
      <div style={{ padding: '0 20px 15px 20px' }}>
        {(advisorVideos || []).map((video) => {
          const { advisorUid, url } = video
          return <UserLink showAgency uid={advisorUid} to={url} />
        })}
      </div>
    )
  }

  function renderLearnMe() {
    const options = {
      autoplay: true,
      controls: true,
      fill: true,
      sources: [
        {
          src: conversation?.learnMe?.videoUrl?.src,
          type: conversation?.learnMe?.videoUrl?.contentType,
        },
      ],
    }
    return (
      <div style={{ padding: '0 20px 20px 20px' }}>
        <Alert
          style={{ marginBottom: 20 }}
          type="info"
          message={
            'The “Learn Me” section will reinforce lessons and key takeaways in an entertaining and memorable video.'
          }
        />
        <h1>{conversation?.learnMe?.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: conversation?.learnMe?.description,
          }}
        />
        <FPEVideo options={options} onReady={handleLearnMePlayerReady} />
      </div>
    )
  }

  function renderBook() {
    return (
      <div>
        <Alert
          style={{ margin: '0 20px 20px 20px' }}
          type="info"
          message={
            'There will be a new book selected quarterly which can optionally be sent to your members on your behalf.'
          }
        />
        {conversation?.bookOfTheQuarter ? (
          <Row style={{ padding: '0 20px 20px 20px' }} gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <div>
                <h1>{conversation?.bookOfTheQuarter?.title}</h1>{' '}
                <span>by {conversation?.bookOfTheQuarter?.author}</span>
              </div>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: conversation?.bookOfTheQuarter?.description,
                }}
              />
              {conversation?.bookOfTheQuarter?.sparknotes ? (
                <div style={{ marginTop: 20 }}>
                  <a
                    href={conversation?.bookOfTheQuarter?.sparknotes}
                    target="_blank"
                    rel="noreferrer"
                  >
                    SparkNotes Version
                  </a>
                </div>
              ) : null}
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'flex-end',
                height: '100%',
              }}
            >
              <div className="wrapper">
                <div className="book">
                  <div className="inner-book">
                    <div
                      className="img"
                      // style={{ paddingTop: 'calc(1.07 * 100%)' }}
                    >
                      <img
                        src={conversation?.bookOfTheQuarter?.image?.src}
                        alt={conversation?.bookOfTheQuarter?.title}
                      />
                    </div>
                    <div className="page"></div>
                    <div className="page page-2"></div>
                    <div className="page page-3"></div>
                    <div className="page page-4"></div>
                    <div className="page page-5"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    )
  }

  function renderCountdown() {
    let numberOfDays = 0
    let quarterStr = ''
    let shouldReturnNull = false
    switch (quarterMetadata?.quarter) {
      case 1:
        quarterStr = '01-01'
        break
      case 2:
        quarterStr = '04-01'
        break
      case 3:
        quarterStr = '07-01'
        break
      case 4:
        quarterStr = '10-01'
        break
      default:
        shouldReturnNull = true
    }
    var eventdate = moment(`${quarterMetadata?.year}-${quarterStr}`)
    var todaysdate = moment()
    numberOfDays = eventdate.diff(todaysdate, 'days')

    if (shouldReturnNull || numberOfDays < 1) {
      return null
    }

    return (
      <div style={{ padding: '0 20px 20px 20px' }}>
        <Countdown
          title="This quarter is going live in"
          suffix={numberOfDays === 1 ? 'day' : 'days'}
          format="D"
          value={numberOfDays}
        />
      </div>
    )
  }

  // function previewQuarter() {
  //   navigate(`/admin/content/${data.uid}/preview`)
  // }

  function onTabClick(index) {
    setActiveTabIndex(index)
  }

  function handleRecorderReady() {
    setRecorderReady(true)
  }

  const handleRecorderTimeUpdate = React.useCallback(
    (e) => {
      if (capturing) {
        if (startTimestamp.current === null) {
          startTimestamp.current = e.timeStamp
          setTimestamp('0:00')
        } else {
          const diff = e.timeStamp - startTimestamp.current
          const str = millisToMinutesAndSeconds(diff)
          if (timestamp !== str) {
            setTimestamp(millisToMinutesAndSeconds(diff))
          }
        }
      } else {
        setTimestamp(null)
      }
    },
    [capturing, timestamp]
  )

  const setWebcamRef = React.useCallback(
    (ref) => {
      webcamRef.current = ref
      if (ref?.video) {
        ref.video.addEventListener('canplay', handleRecorderReady)
        ref.video.addEventListener('timeupdate', handleRecorderTimeUpdate)
      }
    },
    [handleRecorderTimeUpdate]
  )

  function handleCloseRecorder() {
    setRecordingModalVisible(false)
    setRecordedChunks([])
    setCapturing(false)
    setCapturing(false)
    setPreviewVideo(null)
    setRecorderReady(false)
    setTimestamp(null)
    startTimestamp.current = null
  }

  function handleProgress(progress) {
    setUploadProgress(progress.loaded / progress.total)
  }

  async function handleUseVideo() {
    let blob
    if (video?.source === 'webcam') {
      blob = new Blob(recordedChunks, {
        type: 'video/webm',
      })
    } else {
      blob = video.file
    }
    let extension = blob.type.split('/')[1]?.toLowerCase()
    if (extension === 'quicktime' || extension === 'mov') {
      extension = 'mp4'
    }
    const filename = `advisor_videos_quarter_${quarterMetadata?.quarter}_${quarterMetadata?.year}_${currentUser?.uid}.${extension}`
    console.log(`Uploading ${filename} from ${video?.source}`)
    setRecordingModalVisible(false)
    setUploadingVideo(true)

    let thumbnailUrl = ''
    if (thumbnailBlob.current) {
      try {
        const thumbnailFilename = filename.replace(extension, 'png')
        thumbnailUrl = await uploadFile({
          file: thumbnailBlob.current,
          filename: thumbnailFilename,
        })
        console.log('Uploaded thumbnail', thumbnailUrl)
      } catch (error) {
        console.warn('Unable to upload thumbnail', error)
      }
    }

    uploadFile({
      file: blob,
      filename,
      onProgress: handleProgress,
    })
      .then((url) => {
        addAdvisorVideo(
          currentUser?.uid,
          currentUser?.displayName,
          quarterMetadata.uid,
          blob.type,
          url,
          thumbnailUrl
        ).then(() => {
          // window.location.reload()
          message.success('Your video was uploaded! Thanks.')
          setActiveTabIndex('1')
          setUploadingVideo(false)
        })
      })
      .catch((error) => {
        setUploadingVideo(false)
        setVideo(null)
        setUploadProgress(0)
        message.error(
          'Unable to upload video. Please contact the FPE team to upload the video manually.'
        )
        console.warn('Error uploading video', error)
      })
  }

  function handleResetWebcam() {
    setRecordedChunks([])
    setCapturing(false)
    setRecorderReady(false)
    setPreviewVideo(null)
    setTimestamp(null)
    setRecorderReady(false)
    startTimestamp.current = null
  }

  function handleResetVideo() {
    setRecordedChunks([])
    setCapturing(false)
    setRecordingModalVisible(false)
    setVideo(null)
    setRecorderReady(false)
    setPreviewVideo(null)
    setTimestamp(null)
    setRecorderReady(false)
    startTimestamp.current = null
  }

  if (!conversation || !quarterMetadata) {
    return null
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={quarterMetadata.name}
        style={{ paddingBottom: 1 }}
        // extra={[
        //   <Button type="primary" onClick={previewQuarter}>
        //     Preview
        //   </Button>,
        // ]}
        footer={
          <>
            {/* {renderCountdown()} */}
            <Tabs onTabClick={onTabClick} activeKey={activeTabIndex}>
              {currentUser?.attributes?.type === ADVISOR_USER_TYPE && (
                <TabPane
                  key="1"
                  tab={
                    <div>
                      <span>Welcome Message</span>
                      {advisorVideo ? null : (
                        <ExclamationCircleOutlined
                          style={{
                            marginLeft: 10,
                            marginRight: 0,
                            color: 'red',
                          }}
                        />
                      )}
                    </div>
                  }
                >
                  {renderWelcomeVideo()}
                </TabPane>
              )}
              <TabPane tab="Conversations" key="2">
                {renderModules()}
              </TabPane>
              <TabPane tab="Articles" key="3">
                {renderArticles()}
              </TabPane>
              <TabPane tab="Book of the Quarter" key="4">
                {renderBook()}
              </TabPane>
              {conversation?.learnMe ? (
                <TabPane tab="Learn Me" key="5">
                  {renderLearnMe()}
                </TabPane>
              ) : null}
              <TabPane tab="Mindset Training" key="6">
                {renderMindsetMe()}
              </TabPane>
              {currentUser?.attributes?.type === ADMIN_USER_TYPE ? (
                <TabPane tab="Financial Planner Videos" key="7">
                  {renderFinancialPlannerVideos()}
                </TabPane>
              ) : null}
            </Tabs>
          </>
        }
        subTitle={
          <>
            <Tag
              color={'purple'}
            >{`Quarter ${quarterMetadata?.quarter}, ${quarterMetadata?.year}`}</Tag>
            {currentUser?.attributes?.type === ADMIN_USER_TYPE ? (
              <Tag
                color={
                  quarterMetadata.published
                    ? quarterMetadata.activeForClients
                      ? 'green'
                      : quarterMetadata.activeForAdvisors
                      ? 'orange'
                      : 'grey'
                    : 'grey'
                }
              >
                {quarterMetadata.published
                  ? quarterMetadata.activeForClients
                    ? 'LIVE'
                    : quarterMetadata.activeForAdvisors
                    ? 'ADVISORS ONLY'
                    : 'INACTIVE'
                  : 'DRAFT'}
              </Tag>
            ) : null}
          </>
        }
      />
      <canvas id="canvas"></canvas>
      <Modal
        visible={recordingModalVisible}
        maskClosable
        centered
        destroyOnClose
        closeIcon={null}
        maskStyle={{
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        width={VIDEO_WIDTH}
        style={{
          padding: 0,
          borderRadius: 10,
          overflow: 'hidden',
          backgroundColor: 'transparent',
        }}
        bodyStyle={{
          padding: 0,
          backgroundColor: '#000',
        }}
        onCancel={handleCloseRecorder}
        footer={null}
      >
        {previewVideo ? (
          <div>
            <FPEVideo
              onReady={handlePreviewVideoReady}
              maxWidth={VIDEO_WIDTH}
              showMask={false}
              options={{
                preload: 'metadata',
                autoplay: true,
                controls: true,
                fill: true,
                // aspectRatio: '16:9',
                sources: [
                  {
                    src: previewVideo,
                    type: 'video/webm',
                  },
                ],
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 15,
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={handleUseVideo}
              >
                Use Video
              </Button>
              <Button
                onClick={
                  video?.source === 'webcam'
                    ? handleResetWebcam
                    : handleResetVideo
                }
              >
                {video?.source === 'webcam' ? 'Retake' : 'Discard'}
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transform: 'translateZ(0)',
            }}
          >
            <Webcam
              ref={setWebcamRef}
              audio
              muted
              videoConstraints={{
                aspectRatio: 16 / 9,
              }}
              style={{
                backgroundColor: '#000',
                zIndex: 10,
                width: '100%',
                height: '100%',
              }}
            />
            {recorderReady ? null : (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ color: '#fff', fontSize: 24 }}
                    spin
                  />
                }
                size="large"
                style={{ zIndex: 12, position: 'absolute' }}
              />
            )}
            {timestamp ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 11,
                  borderRadius: 5,
                  padding: '5px 0',
                  width: 60,
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'center',
                  top: 20,
                  left: 20,
                  backgroundColor: 'rgba(207,19,34,0.8)',
                }}
              >
                <div
                  style={{
                    fontSize: 16,
                    color: '#fff',
                    fontWeight: 'bold',
                  }}
                >
                  {timestamp}
                </div>
              </div>
            ) : null}
            {recorderReady ? (
              <div
                onClick={
                  capturing ? handleStopCaptureClick : handleStartCaptureClick
                }
                className={`recorder ${recorderReady ? 'recorder-active' : ''}`}
              >
                <div
                  className={`recorder-inner ${
                    capturing ? 'recorder-inner-active' : ''
                  }`}
                />
              </div>
            ) : null}
          </div>
        )}
      </Modal>
      <Modal
        title={detail?.name}
        visible={!!detail}
        cancelText="Close"
        okText={
          <a href={detail?.url} download target="_blank" rel="noreferrer">
            Download
          </a>
        }
        onCancel={() => setDetail(null)}
      >
        <Document
          file={detail?.url}
          onLoadSuccess={onDocumentLoadSuccess}
          style={{ alignSelf: 'center' }}
        >
          <Page
            onLoadSuccess={onPageLoad}
            pageNumber={pageNumber}
            width={pageDimensions.width}
            height={pageDimensions.height}
          />
        </Document>
        {numPages > 1 ? (
          <Row
            align="center"
            justify="space-between"
            style={{ paddingTop: 24 }}
          >
            <Button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
            />
            <div>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </div>
            <Button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
            />
          </Row>
        ) : null}
      </Modal>
    </Card>
  )
}

function mapStateToProps({ quarters = {}, session: { user = {} } }) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 1]
  const quarterContent =
    quarters.quarterContent?.find?.((quarter) => {
      return quarter.uid === uid
    }) || null
  const quarterMetadata =
    quarters.quarterMetadata?.find?.((quarter) => {
      return quarter.uid === uid
    }) || null
  const currentUserUid = user?.attributes?.uid
  return {
    quarterMetadata,
    quarterContent,
    currentUser: user,
    advisorVideos: quarterMetadata?.advisorVideos,
    advisorVideo: quarterMetadata?.advisorVideos?.find((video) => {
      return (
        video.advisorUid?.toLowerCase?.() === currentUserUid?.toLowerCase?.()
      )
    }),
  }
}

const mapDispatchToProps = {
  addAdvisorVideo,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterDetail)
