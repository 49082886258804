import axios from 'axios'

import { logout } from '../../features/session/redux/actions'
import config from '../../FirebaseManager/config'
import { store } from '../redux'

const axiosInstance = axios.create({
  baseURL: config.BASE_API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error?.response?.status === 403) {
      store.dispatch(logout())
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default axiosInstance
