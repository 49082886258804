import React from 'react'

import { CSVLink } from 'react-csv'

function FPECsvLink({ filename, data = [] }) {
  try {
    return (
      <CSVLink
        filename={filename}
        data={data.filter((x) => x)}
        className="btn btn-primary"
      >
        Export to CSV
      </CSVLink>
    )
  } catch (error) {
    console.warn(error)
    return null
  }
}

export default FPECsvLink
