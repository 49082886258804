import React from 'react'

import { Button, Card, Col, Divider, Row } from 'antd'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import FPEVideo from '../../../../shared/components/FPEVideo'
import AgencyLink from '../../../../shared/components/Links/AgencyLink'
import TeamLink from '../../../../shared/components/Links/TeamLink'
import placeholders from '../../../../shared/helpers/handlebars'
import { formatUrl } from '../../../../shared/helpers/linkingHelpers'

function Welcome({ currentUser, welcomeScript, advisor, advisorVideo }) {
  const advisorVideoRef = React.useRef(null)
  const navigate = useNavigate()
  // Data and template
  var data = {
    advisorName: advisor?.displayName || '',
  }

  const advisorVideoJsOptions = {
    autoplay: true,
    controls: true,
    fill: true,
    sources: [
      {
        thumbnail: {
          src: advisorVideo?.thumbnailUrl,
        },
        src: advisorVideo?.url,
        type: advisorVideo?.contentType,
      },
    ],
  }

  function handleAdvisorVideoReady(player) {
    advisorVideoRef.current = player
  }

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={16} xxl={19}>
          <h1 style={{ fontWeight: 'bold' }}>
            {currentUser?.attributes?.firstName
              ? `Welcome, ${currentUser?.attributes?.firstName}!`
              : 'Welcome!'}
          </h1>
          {advisorVideo ? (
            <FPEVideo
              options={advisorVideoJsOptions}
              onReady={handleAdvisorVideoReady}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: welcomeScript ? placeholders(welcomeScript, data) : '',
              }}
            />
          )}
        </Col>
        <Col xs={24} xl={8} xxl={5}>
          <Card title="Platform Links" style={{ height: '100%' }}>
            <div id="quick-links">
              {advisor?.accounts ? (
                (advisor?.accounts || []).map((account) => {
                  const url = formatUrl(account.url)
                  return (
                    <div key={url}>
                      <a href={url} target="_blank" rel="noreferrer">
                        {account.type} Account
                      </a>
                    </div>
                  )
                })
              ) : (
                <p>
                  Your financial planner hasn't bookmarked any accounts yet!
                </p>
              )}
            </div>
            <Divider />
            {advisor ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <img
                    src={advisor?.photoURL || global.DEFAULT_PHOTO_URL}
                    style={{ width: 100, marginBottom: 20 }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <h3>{advisor.displayName}</h3>
                    {advisor.teamUid ? (
                      <TeamLink disable uid={advisor.teamUid} />
                    ) : (
                      <AgencyLink disable uid={advisor.agencyUid} />
                    )}
                  </div>
                </div>
                <Button
                  onClick={() => navigate('/contact')}
                  style={{ width: '100%', maxWidth: 300 }}
                >
                  Contact Financial Planner
                </Button>
              </div>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

function mapStateToProps({
  quarters: { activeQuarterContent, activeQuarterMetadata },
  session: { user },
  users: { data: usersData },
}) {
  const advisorUid = user?.attributes?.advisorUid
  const advisor = usersData.find((advisor) => {
    return advisor.uid === advisorUid
  })
  let advisorVideo = null
  if (activeQuarterMetadata) {
    advisorVideo = (activeQuarterMetadata?.advisorVideos || []).find(
      (video) => {
        return video?.advisorUid === advisorUid
      }
    )
  }
  return {
    currentUser: user,
    name: activeQuarterContent?.name,
    welcomeScript: activeQuarterContent?.welcomeScript,
    modules: activeQuarterContent?.modules || null,
    advisor,
    advisorVideo: advisorVideo || null,
  }
}

export default connect(mapStateToProps)(Welcome)
