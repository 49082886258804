import React from 'react'

import {
  Button,
  Card,
  ConfigProvider,
  Descriptions,
  PageHeader,
  Space,
  Table,
  Tabs,
} from 'antd'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ADVISOR_USER_TYPE } from '../../../../features/session/redux/types'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import AgencyLink from '../../../../shared/components/Links/AgencyLink'

const { TabPane } = Tabs

function TeamDetail({ data, currentUser }) {
  const agency = data?.agency
  const navigate = useNavigate()

  function handleUnassignAdvisor() {
    console.log('Unassigning financial planner')
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      uid: 'displayName',
      sorter: (a, b) => {
        return a.name - b.name
      },
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text}</Link>
      },
    },
    // {
    //   title: 'Action',
    //   uid: 'action',
    //   render: (text, team) => {
    //     return (
    //       <Space size="middle">
    //         <a
    //           onClick={() => {
    //             return handleUnassignAdvisor(team)
    //           }}
    //         >
    //           Unassign
    //         </a>
    //       </Space>
    //     )
    //   },
    // },
  ]

  function addAdvisor() {
    navigate(`/admin/firms/${agency?.uid}/teams/${data?.uid}/add`)
  }

  if (!agency) {
    return null
  }

  const extraContent = (
    <Space>
      {data?.advisors?.length ? (
        <FPECsvLink
          filename={`${data.name}_financial_planners.csv`}
          data={data?.advisors || []}
        />
      ) : null}
      <Button type="primary" onClick={addAdvisor}>
        Add Financial Planner
      </Button>
    </Space>
  )

  function editTeam() {
    navigate(`/admin/firms/${data.agencyUid}/teams/${data.uid}/edit`)
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={data.name}
        extra={[
          <Button onClick={editTeam} type="primary">
            Edit Team
          </Button>,
        ]}
        footer={
          <Tabs defaultActiveKey="1" tabBarExtraContent={extraContent}>
            <TabPane tab="Financial Planners" key="1">
              <ConfigProvider
                renderEmpty={() => (
                  <FPEEmptyState description="No financial planners yet" />
                )}
              >
                <Table
                  rowKey="uid"
                  columns={columns}
                  dataSource={data?.advisors || []}
                  scroll={{
                    x: 600,
                  }}
                />
              </ConfigProvider>
            </TabPane>
          </Tabs>
        }
      >
        <img
          src={data.logoUrl}
          style={{ height: 50, objectFit: 'contain', marginBottom: 20 }}
        />
        <Descriptions
          size="small"
          column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}
        >
          {agency.uid ? (
            <Descriptions.Item label="Firm">
              <AgencyLink uid={agency.uid} />
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </PageHeader>
    </Card>
  )
}

function mapStateToProps({
  session: { user },
  users = {},
  teams = {},
  agencies = {},
}) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 1]
  let data =
    teams.data?.find?.((team) => {
      return team.uid === uid
    }) || null
  if (data) {
    data = { ...data }
    if (data.agencyUid) {
      data.agency = agencies.data?.find?.((agency) => {
        return agency.uid === data.agencyUid
      })
    }
    if (data.advisors?.length) {
      const advisorsMap = {}
      const advisors = users.data?.filter?.((user) => {
        return user.type === ADVISOR_USER_TYPE
      })
      if (advisors?.length) {
        advisors.forEach((advisor) => {
          advisorsMap[advisor.uid] = advisor
        })
        data.advisors = data.advisors
          .map((advisorUid) => {
            return advisorsMap[advisorUid]
          })
          .filter((x) => x)
      } else {
        data.advisors = []
      }
    }
  }
  return {
    currentUser: user,
    data,
  }
}

export default connect(mapStateToProps)(TeamDetail)
