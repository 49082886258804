export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
export const SET_CURRENT_USER_ATTRIBUTES = 'SET_CURRENT_USER_ATTRIBUTES'
export const SET_ACCOUNTS_SUCCESS = 'SET_ACCOUNTS_SUCCESS'
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME'
export const SET_CURRENT_USER_ONBOARDING = 'SET_CURRENT_USER_ONBOARDING'
export const FETCH_APP_DATA_BEGIN = 'FETCH_APP_DATA_BEGIN'
export const FETCH_APP_DATA_SUCCESS = 'FETCH_APP_DATA_SUCCESS'

export const ADMIN_USER_TYPE = 'admin'
export const AGENCY_ADMIN_USER_TYPE = 'agency_admin'
export const ADVISOR_USER_TYPE = 'advisor'
export const CLIENT_USER_TYPE = 'client'
