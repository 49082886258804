import React from 'react'

import { Link } from 'react-router-dom'

export default function AgencyAdminUserDetail({ user }) {
  const agency = user?.agency
  function renderAgency() {
    if (agency) {
      return (
        <div>
          Manages firm:{' '}
          <Link to={`/admin/firms/${agency.uid}`}>{agency.name}</Link>
        </div>
      )
    } else {
      return <p>Firm/Team Admin User Detail</p>
    }
  }
  return <div>{renderAgency()}</div>
}
