import React from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import useAuth from '../hooks/useAuth'

const REPLACE = false

function RequireAuth({ children, disallowedRoles, redirectTo }) {
  let session = useAuth()
  let location = useLocation()

  if (!session.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace={REPLACE} />
  }

  if (disallowedRoles?.includes(session?.user?.attributes?.type)) {
    const from = redirectTo || location.state?.from?.pathname || '/'
    return <Navigate to={from} replace={REPLACE} />
  }

  return children
}

export default RequireAuth
