import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin, message } from 'antd'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { setRememberMe, setUser } from '../../features/session/redux/actions'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../features/session/redux/types'
import FirebaseManager from '../../FirebaseManager'

export default function PasswordlessLogin() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const uid = searchParams.get('u')
  const email = searchParams.get('e')
  const listId = searchParams.get('l')
  const redirect = searchParams.get('redirect')

  const [loading, setLoading] = React.useState(!!email || !!uid || !!listId)

  React.useEffect(() => {
    if (loading) {
      FirebaseManager.signInWithMailchimpUid(email, uid, listId)
        .then((user) => {
          if (user.attributes?.type) {
            switch (user.attributes.type) {
              case ADMIN_USER_TYPE:
              case ADVISOR_USER_TYPE:
                message.warning(
                  'The link you used is intended only for members. Please log in.'
                )
                navigate('/login')
                break
              case CLIENT_USER_TYPE:
                dispatch(setRememberMe(true))
                dispatch(setUser(user))
                setTimeout(() => {
                  navigate(redirect || '/')
                }, 750)
                break
              default:
                console.warn('Error logging in - no user type found', user)
                message.warning('Unable to log in via this link.')
            }
          } else {
            console.warn('Error logging in - no type found', user)
            message.error('Error logging in. Please contact support.')
            setLoading(false)
          }
        })
        .catch((error) => {
          console.warn('Error logging in', error)
          message.error('Unable to sign you in via this link.')
          setLoading(false)
        })
    } else {
      navigate('/')
    }
  }, [loading, uid, dispatch, redirect, navigate, listId, email])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>Logging you back in to FPE...</div>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 24, marginLeft: 20 }} spin />
        }
      />
    </div>
  )
}
