import ContentfulManager from '../../../ContentfulManager'
import FirebaseManager from '../../../FirebaseManager'
import {
  editQuarterBegin,
  editQuarterSuccess,
  editQuarterError,
  addAdvisorVideoSuccess,
} from './actions'

export const setQuarters = () => {
  return new Promise((resolve, reject) => {
    FirebaseManager.getQuarters()
      .then(async (allQuarters = []) => {
        let promises = []
        allQuarters.forEach((quarter) => {
          promises.push(
            new Promise((resolve) => {
              ContentfulManager.getQuarter(quarter.uid)
                .then(resolve)
                .catch((error) => {
                  console.warn(error)
                  resolve(null)
                })
            })
          )
        })
        const allContent = ((await Promise.all(promises)) || []).filter(
          (x) => x
        )

        resolve({
          quarterContent: allContent,
          quarterMetadata: allQuarters,
        })
      })
      .catch(reject)
  })
}

export const addAdvisorVideo =
  (currentUserUid, currentUserName, quarterUid, fileType, url, thumbnailUrl) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      FirebaseManager.addAdvisorVideoToQuarter(
        currentUserUid,
        currentUserName,
        quarterUid,
        fileType,
        url,
        thumbnailUrl
      )
        .then(() => {
          dispatch(
            addAdvisorVideoSuccess(currentUserUid, quarterUid, fileType, url)
          )
          resolve(url)
        })
        .catch(reject)
    })
  }

export const editQuarter = (quarter, agencyUid, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(editQuarterBegin())
    FirebaseManager.patchQuarterRequest(quarter, agencyUid, payload)
      .then((response) => {
        dispatch(editQuarterSuccess(response))
        resolve(response)
      })
      .catch((error) => {
        dispatch(editQuarterError())
        reject(error)
      })
  })
}
