//@refresh reset
import React from 'react'

import {
  UserOutlined,
  HomeOutlined,
  CalendarOutlined,
  ProfileOutlined,
  CheckCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Carousel,
  Spin,
  message,
  Row,
  Steps,
  Col,
  Form,
  Modal,
} from 'antd'
import axios from 'axios'
import { connect } from 'react-redux'
import OAuth2Login from 'react-simple-oauth2-login'

import { editUser } from '../../../../features/users/redux/operations'
import config from '../../../../FirebaseManager/config'
import CalendlyEventList from '../../../../shared/components/CalendlyEventsList'
import usePrimaryColor from '../../../../shared/hooks/usePrimaryColor'
import UserCreateForm from '../UserCreateForm'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const { Step } = Steps

const NUMBER_OF_WELCOME_ONBOARDING = 3

const CALENDLY_REDIRECT_URI =
  config.ENVIRONMENT === 'dev'
    ? 'https://fpe-dev.herokuapp.com/admin'
    : 'https://www.financialplanningexperience.com/admin'
// https://fpe-prod.herokuapp.com/admin

const CALENDLY_CLIENT_ID =
  config.ENVIRONMENT === 'dev'
    ? 'TdMvQozkIqWz85qAKsQDLEvQpwlAiyWWQgarBGaFDWo'
    : 'vYImcSFZr7FCSxguSFfBzpAjQh5PmsUy3xfH0S8lokM'
// s1mjEt8Bzo3yEzv0R8S1Fq5UpTEhAgL0GWpNMdBupgE

const CALENDLY_CLIENT_SECRET =
  config.ENVIRONMENT === 'dev'
    ? 'W3dDm1OdYWDIB0JI14vA8h-Pa-v1NToY9Aqzz0lI5ts'
    : 'HC1XKJRidE4R-kkuOLFb5PctY4fpDWkemEeiWnzIQV0'
// UsFHVcewlX2vCxzhDi4ynEvp9O7Y58IGbe1-c0q7jjg

const SMALL_LOGO_SOURCE = '/fpe_small_logo.png'
const CALENDLY_LOGO_SOURCE = '/calendly.png'

const whatYouCanExpect = [
  {
    title: 'Winter: Financial Planning',
    description:
      'Discuss financial planning topics and goals (short, medium and long term): tax strategies, cash flow, net worth, core topics, and goals review.',
    image: '/expect_1.svg',
    imageAlign: 'left',
  },
  {
    title: 'Spring: Investment Planning',
    description:
      "Review portfolio performance, including account balances and holdings; revisit investment strategy, risk tolerance, and goal timelines. Let's put your money to work.",
    image: '/expect_2.svg',
    imageAlign: 'right',
  },
  {
    title: 'Summer: Protection Planning',
    description:
      'Review employee benefits, current cash flow and retirement savings, and long term needs such as estate planning, beneficiary designation, and long term care.',
    image: '/expect_3.svg',
    imageAlign: 'left',
  },
  {
    title: 'Fall: Year-End Tax Planning',
    description:
      'Year end tax planning, tax loss harvesting, RMD, and Tax distribution planning. Begin planning for the next year.',
    image: '/expect_4.svg',
    imageAlign: 'right',
  },
]

const educationalPieces = [
  {
    title: 'Scheduling',
    description:
      'Most importantly, we want to connect with YOU. There will be the ability to schedule a meeting to discuss any of these topics, or anything else on your mind.',
    image: '/content_6.svg',
  },
  {
    title: 'Introduction',
    description:
      'This will set the stage for any particularly relevant topics on financial planning - it could be changes in interest rates, market volatility, estate planning updates, etc. If you need to know it, you will find it here.',
    image: '/content_1.svg',
  },
  {
    title: 'Educational Video',
    description:
      "Reviewing key concepts related to the current quarter's conversation, the “Learn Me” section will reinforce lessons and key takeaways in an entertaining and memorable video.",
    image: '/content_2.svg',
  },
  {
    title: 'In the News',
    description:
      'Included will be relevant articles on timely changes in the financial planning landscape as well as the quarterly financial planning topic, titled "Catch Me Up".',
    image: '/content_3.svg',
  },
  {
    title: 'Quarterly Book',
    description:
      'Each quarter, there will be a new book selected that we believe will be of value. If you would like to receive a copy of the book, we would be happy to send it to you directly!',
    image: '/content_4.svg',
  },
  {
    title: 'Mindset Training',
    description:
      'Personal. Professional. Financial. All aspects of development are important, and interconnect in ways you may not realize. We will introduce you to key concepts in the realm of mindset training via the section called "Bigger Futures".',
    image: '/content_5.svg',
  },
]

function AdvisorOnboardingCarousel({
  onClose,
  currentUser,
  editUser,
  userHasMembers,
}) {
  const [form] = Form.useForm()

  const [calendlyEvents, setCalendlyEvents] = React.useState(null)
  const [fetchingCalendlyEvents, setFetchingCalendlyEvents] =
    React.useState(false)

  const [steps, setSteps] = React.useState([
    {
      title: 'Welcome',
      icon: <HomeOutlined />,
    },
    {
      title: 'Profile',
      icon: <ProfileOutlined />,
    },
    {
      title: 'Calendly',
      icon: <CalendarOutlined />,
    },
    {
      title: 'Member List',
      icon: <UserOutlined />,
    },
    {
      title: 'Done',
      icon: <CheckCircleOutlined />,
    },
  ])

  function renderWhatYouCanExpect() {
    return whatYouCanExpect.map((item) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: item.imageAlign === 'left' ? 'row-reverse' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '50px 50px',
          }}
        >
          <div>
            <h3 style={{ fontWeight: 'bold' }}>{item.title}</h3>
            <p>{item.description}</p>
          </div>
          <div
            style={{
              width: '75%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={item.image}
              style={{
                margin: 'auto',
                width: '75%',
                objectFit: 'contain',
              }}
            />
          </div>
        </div>
      )
    })
  }

  const primaryColor = usePrimaryColor()

  function renderEducationalPieces() {
    return educationalPieces.map((item) => {
      return (
        <Col xs={12}>
          <Card
            bodyStyle={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ height: 322 }}
          >
            <img src={item.image} style={{ height: '50%', margin: 'auto' }} />
            <h3 style={{ fontWeight: 'bold', marginTop: 30 }}>{item.title}</h3>
            <p>{item.description}</p>
          </Card>
        </Col>
      )
    })
  }

  const [current, setCurrent] = React.useState(0)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [calendlybuttonLoading, setCalendlyButtonLoading] =
    React.useState(false)

  const calendlyResponse = React.useRef({})
  const calendlyToken = React.useRef({})

  const carouselRef = React.useRef(null)
  const userCreateFormRef = React.useRef(null)

  function getCurrent() {
    if (current >= NUMBER_OF_WELCOME_ONBOARDING) {
      return current - NUMBER_OF_WELCOME_ONBOARDING + 1
    } else {
      return 0
    }
  }

  const adjustedCurrent = getCurrent()

  const next = React.useCallback(
    (skipSubmit = false) => {
      if (adjustedCurrent === 1) {
        if (!skipSubmit) {
          setButtonLoading(true)
          userCreateFormRef.current.submitForm().catch((error) => {
            console.warn('Error in validation', error)
            message.warn('Please correct the errors below.')
            setButtonLoading(false)
          })
        } else {
          setCurrent(current + 1)
          carouselRef.current.next()
        }
      } else if (adjustedCurrent === 2) {
        const selectedEvent = (calendlyEvents || []).find((event) => {
          return event.selected
        })
        if (selectedEvent) {
          setButtonLoading(true)
          editUser(currentUser.uid, {
            primarySchedulingUrl: selectedEvent.scheduling_url,
          })
            .then(() => {
              setCurrent(current + 1)
              carouselRef.current.next()
              console.log('Primary Calendly event set!')
              setButtonLoading(false)
              message.success('Primary Calendly event set!')
            })
            .catch((error) => {
              console.warn('Unable to set primary Calendly event', error)
              setButtonLoading(false)
              message.error(
                'Unable to set your primary Calendly event. Please contact the Financial Planning Experience team to get this set up later.'
              )
            })
        } else {
          if (calendlyEvents) {
            Modal.warning({
              title: 'Please select a primary Calendly event!',
            })
          } else {
            setCurrent(current + 1)
            carouselRef.current.next()
          }
        }
      } else {
        setCurrent(current + 1)
        carouselRef.current.next()
      }
    },
    [adjustedCurrent, current, calendlyEvents, currentUser.uid, editUser]
  )

  const prev = () => {
    if (current - 1 > -1) {
      setCurrent(current - 1)
      carouselRef.current.prev()
    }
  }

  const handleFinish = React.useCallback(() => {
    onClose()
  }, [onClose])

  const setCarouselRef = React.useCallback((ref) => {
    carouselRef.current = ref
  }, [])

  const setUserCreateFormRef = React.useCallback((ref) => {
    userCreateFormRef.current = ref
  }, [])

  const onFinishCallback = React.useCallback(() => {
    // setCurrent(current + 1)
    setButtonLoading(false)
    next(true)
  }, [next])

  function onCalendlySuccess(e) {
    try {
      setButtonLoading(true)

      const params = new URLSearchParams()
      params.append('client_secret', CALENDLY_CLIENT_SECRET)
      params.append('grant_type', 'authorization_code')
      params.append('client_id', CALENDLY_CLIENT_ID)
      params.append('code', e.code)
      params.append('redirect_uri', CALENDLY_REDIRECT_URI)
      let Authorization = null

      fetch('https://auth.calendly.com/oauth/token', {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => res.json())
        .then((tokenInfo) => {
          calendlyToken.current = tokenInfo
          Authorization = `Bearer ${calendlyToken.current.access_token}`
          axios
            .get('https://api.calendly.com/users/me', {
              headers: {
                Authorization,
              },
            })
            .then(async (response) => {
              calendlyResponse.current = response.data.resource
              console.log(
                'Received Calendly user info',
                calendlyResponse.current
              )
              editUser(currentUser.uid, {
                calendlyUsername: calendlyResponse.current.slug,
              })
                .then(() => {
                  console.log('Saved user Calendly info')
                  setCalendlyButtonLoading(false)
                  setButtonLoading(false)
                  message.success('We linked your Calendly account!')
                  getCalendlyEvents()
                })
                .catch((error) => {
                  console.warn('Unable to save user Calendly info', error)
                  setCalendlyButtonLoading(false)
                  setButtonLoading(false)
                  message.error(
                    'Unable to connect your Calendly profile. Please contact the Financial Planning Experience team to get this set up later.'
                  )
                })
            })
            .catch((error) => {
              console.warn('Unable to get your Calendly user', error)
              setButtonLoading(false)
              setCalendlyButtonLoading(false)
              message.error(
                'Unable to get your Calendly user. Please contact the Financial Planning Experience team to get this set up later.'
              )
            })
        })
        .catch((error) => {
          console.warn('Unable to authenticate with Calendly', error)
          setButtonLoading(false)
          setCalendlyButtonLoading(false)
          message.error(
            'Unable to authenticate with Calendly. Please contact the Financial Planning Experience team to get this set up later.'
          )
        })
    } catch (error) {
      message.error(
        'Unknown error connecting to Calendly. Please contact the Financial Planning Experience team to get this set up later.'
      )
      console.warn('Unknown error in Calendly Oauth2 flow', error)
      setButtonLoading(false)
      setCalendlyButtonLoading(false)
    }
  }

  function onCalendlyFailure(error) {
    console.warn(error)
    setCalendlyButtonLoading(false)
    setButtonLoading(false)
    message.error(
      'Unable to reach Calendly. Please contact the Financial Planning Experience team to get this set up later.'
    )
  }

  function renderCalendlyConnectButton({ onClick }) {
    return (
      <Button
        className="calendly-button"
        onClick={() => {
          setCalendlyButtonLoading(true)
          onClick()
        }}
        loading={calendlybuttonLoading}
      >
        Connect Calendly
      </Button>
    )
  }

  function handleCalendlyEventSelection(uri) {
    setCalendlyEvents((prevEvents) => {
      if (prevEvents) {
        prevEvents = prevEvents.map((e) => {
          e.selected = false
          return e
        })
        const event = prevEvents.find((e) => {
          return e.uri === uri
        })
        if (event) {
          if (event.active) {
            event.selected = !event.selected
          }
        }
        return [...prevEvents]
      } else {
        return prevEvents
      }
    })
  }

  async function getCalendlyEvents(e) {
    if (e) {
      e.preventDefault?.()
    }
    if (calendlyResponse.current?.slug) {
      try {
        setFetchingCalendlyEvents(true)
        const Authorization = `Bearer ${calendlyToken.current.access_token}`
        const events = await axios.get(
          `https://api.calendly.com/event_types?count=100&sort=name:asc&user=${calendlyResponse.current.uri}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization,
            },
          }
        )
        console.log('Calendly events', events.data?.collection || [])
        setCalendlyEvents(events.data?.collection || [])
        setFetchingCalendlyEvents(false)
      } catch (error) {
        setFetchingCalendlyEvents(true)
        console.error('Unable to get calendly events', error)
        message.error('Could not get Calendly events')
      }
    } else {
      console.warn('No calendly username')
      message.error('Could not get Calendly events')
    }
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Steps
        style={{
          position: 'absolute',
          padding: 24,
          backgroundColor: 'white',
          zIndex: 9999,
        }}
        current={getCurrent()}
      >
        {steps.map((item) => (
          <Step key={item.title} icon={item.icon} title={item.title} />
        ))}
      </Steps>
      <Carousel
        infinite={false}
        centerMode
        accessibility
        className="carousel-inner-div"
        dots={false}
        centerPadding={0}
        ref={setCarouselRef}
      >
        <div>
          <div
            className="slick-inner-div"
            style={{
              height: '100%',
              padding: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <h1
                style={{
                  textAlign: 'center',
                }}
              >
                Welcome to The Financial Planning Experience
              </h1>
              <p
                style={{
                  textAlign: 'center',
                  padding: '0 5rem',
                }}
              >
                You've taken the first step of many in embarking on a lifelong
                journey. You are now part of an exclusive club that has decided
                they are serious about achieving their financial potential.
              </p>
            </div>
            <img src="/balloons.svg" style={{ width: '50%' }} />
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div hide-scroll"
            style={{
              padding: '75px 100px',
              height: '100%',
              alignSelf: 'flex-end',
              overflow: 'scroll',
            }}
          >
            <h1>What You Can Expect</h1>
            <p>
              We deliver quarterly emails with educational, relevant,
              interactive, collaborative, actionable content that is always in
              the pursuit of your full potential. The delivery will follow a set
              format, arrive on a quarterly basis, and will provide education on
              the following:
            </p>
            {renderWhatYouCanExpect()}
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div hide-scroll"
            style={{
              padding: '75px 100px',
              height: '100%',
              alignSelf: 'flex-end',
              overflow: 'scroll',
            }}
          >
            <h1>Types of Content</h1>
            <p>
              In each of these educational pieces, there will be a few
              reoccurring sections and areas of focus:
            </p>
            <Row gutter={[16, 16]}>{renderEducationalPieces()}</Row>
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
            }}
          >
            <h1>First, does everything here look alright?</h1>
            <p>
              Feel free to make any changes, and your changes will save when you
              advance to the next slide.
            </p>
            <UserCreateForm
              ref={setUserCreateFormRef}
              onFinishCallback={onFinishCallback}
              finishingRegistration
              showCard={false}
              user={currentUser?.attributes}
              editing
            />
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
            }}
          >
            <div style={{ height: '100%' }}>
              <Row
                align="center"
                style={{
                  alignItems: 'center',
                }}
              >
                <img
                  src={SMALL_LOGO_SOURCE}
                  style={{ width: 100, height: 75, objectFit: 'contain' }}
                />
                <span
                  style={{
                    fontSize: 100,
                    color: 'rgba(0,0,0,0.25)',
                    lineHeight: 'normal',
                  }}
                >
                  /
                </span>
                <img
                  src={CALENDLY_LOGO_SOURCE}
                  style={{
                    width: 140,
                    height: 140,
                    objectFit: 'contain',
                    marginLeft: -30,
                  }}
                />
              </Row>
              <div style={{ textAlign: 'center' }}>
                {calendlyEvents ? (
                  <>
                    <h1>Great! Just one last thing...</h1>
                    <div>
                      <CalendlyEventList
                        events={calendlyEvents}
                        handleCalendlyEventSelection={
                          handleCalendlyEventSelection
                        }
                      />
                      <Button
                        loading={fetchingCalendlyEvents}
                        className="calendly-button"
                        onClick={getCalendlyEvents}
                      >
                        Refresh Events
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1>Next, connect your Calendly account.</h1>
                    <div
                      style={{
                        padding: '15px 0',
                        width: '50%',
                        margin: 'auto',
                      }}
                    >
                      <p>
                        FPE uses{' '}
                        <a
                          href="https://calendly.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Calendly
                        </a>{' '}
                        to help your members schedule meetings with you to
                        discuss each quarter's content. You can easily create an
                        account by clicking "Connect Calendly" below, or connect
                        an existing account.
                      </p>
                      <p>
                        The only user data we store from your Calendly account
                        is your username, and{' '}
                        <strong>the service is provided free of charge</strong>.
                        If you wish to adjust your availability, you can do this
                        by visiting your Calendly account.
                      </p>
                    </div>
                    <OAuth2Login
                      authorizationUrl="https://auth.calendly.com/oauth/authorize"
                      responseType="code"
                      render={renderCalendlyConnectButton}
                      clientId={CALENDLY_CLIENT_ID}
                      redirectUri={CALENDLY_REDIRECT_URI}
                      onSuccess={onCalendlySuccess}
                      onFailure={onCalendlyFailure}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
            }}
          >
            {userHasMembers ? (
              <>
                <h1>
                  Thank you for reviewing your Financial Planning Experience
                  profile! You are one step closer to interacting with your
                  members in a new and exciting way.
                </h1>
                <p>
                  As soon as possible, head over to the "Members" section on the
                  home page and review your list of onboarded members. You can
                  modify your member list as needed to ensure that your ideal
                  audience can view the quarterly content.
                </p>
              </>
            ) : (
              <>
                <h1>
                  Thank you for creating your Financial Planning Experience
                  profile! You are one step closer to interacting with your
                  members in a whole new way. The final step is to upload your
                  member list.
                </h1>
                <p>
                  All members who are under a signed agreement approved by MMLIS
                  are eligible to be onboarded onto the Financial Planning
                  Experience platform. To get started, download the CSV template{' '}
                  <a href="/example_members.csv" target="_blank" download>
                    here
                  </a>{' '}
                  and add your eligible member information.
                </p>
                <p>
                  Once your bulk upload list is completed, please head over to
                  the "Members" bulk import section and upload the list in CSV
                  format (First Name, Last Name, Email) where indicated. To
                  upload members individually, there is an option to "Add New
                  Users" directly through the platform; no CSV list required.
                </p>
              </>
            )}
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1>You're all set! 🎉</h1>
            <p>
              Thank you for setting up your Financial Planning Experience
              profile.
            </p>
            <img src="/success.svg" style={{ width: 200, marginTop: 20 }} />
          </div>
        </div>
      </Carousel>
      <div
        className={current > 0 ? 'arrow-icon' : 'display-none'}
        style={{
          overflow: 'hidden',
          borderRadius: 1000,
          pointerEvents: buttonLoading ? 'none' : 'auto',
          position: 'absolute',
          left: 24,
          bottom: 24,
        }}
      >
        <LeftCircleOutlined
          style={{
            fontSize: '40px',
            color: buttonLoading ? 'grey' : primaryColor,
            backgroundColor: 'white',
          }}
          onClick={() => prev()}
        />
      </div>
      <div
        style={{
          overflow: 'hidden',
          borderRadius: 1000,
          pointerEvents: buttonLoading ? 'none' : 'auto',
          position: 'absolute',
          right: 24,
          bottom: 24,
        }}
      >
        {buttonLoading ? (
          <Spin indicator={antIcon} />
        ) : adjustedCurrent === 4 ? (
          <Button type="primary" onClick={handleFinish}>
            Let's Get Started
          </Button>
        ) : (
          <RightCircleOutlined
            style={{
              backgroundColor: 'white',
              fontSize: '40px',
              color: primaryColor,
            }}
            onClick={() => next()}
          />
        )}
      </div>
    </div>
  )
}

function mapStateToProps({
  session: { user = {} },
  users: { data: usersData = [] },
}) {
  // const paths = window.location.pathname.split('/')
  // const uid = paths[paths.length - 1]
  const advisorMembers = usersData.filter((u) => u.advisorUid === user.uid)
  return {
    userHasMembers: advisorMembers?.length,
    currentUser: user,
  }
}

const mapDispatchToProps = {
  editUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvisorOnboardingCarousel)
