import axios from 'axios'

import axiosInstance from '../../../bootstrap/axios'
import config from '../../config'

function returnS3LinkForVideo(filename) {
  switch (config.ENVIRONMENT) {
    case 'prod':
      return `https://fpe-dev.s3.amazonaws.com/${filename}`
    default:
      return `https://fpe-dev.s3.amazonaws.com/${filename}`
  }
}

export function uploadProfilePhotoRequest(file, filename, onProgress) {
  return new Promise(async (resolve, reject) => {
    try {
      const scrubbedFilename = filename
        .replace(/ /g, '_')
        .replace(/\//g, '_')
        .replace('&', '_')
        .toLowerCase()
      console.log('Uploading', file)

      const response = await axiosInstance.get(
        `/preSignedUrl?name=${scrubbedFilename}&contentType=${file.type}`
      )
      axios({
        method: 'PUT',
        url: response.data.url,
        headers: { 'Content-Type': file.type },
        data: file,
        onUploadProgress: onProgress,
      })
        .then(() => {
          const finalUrl = returnS3LinkForVideo(scrubbedFilename)
          console.log('File available at', finalUrl)
          resolve(finalUrl)
        })
        .catch((error) => {
          console.warn('Could not upload', error)
          reject(error)
        })
    } catch (error) {
      console.warn('Could not upload, unknown error', error)
      reject(error)
    }
  })
}

export function getCurrentUserRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/current?uid=${uid}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getSupportedDomainsRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/supported-domains`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function unsubscribeEmailsRequest(email) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'PATCH',
      url: `${config.BASE_API_URL}/users/unsubscribe`,
      data: {
        email,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAllAccountsRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/accounts`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getHelpRequest(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/getHelp`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function deleteUserRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'DELETE',
      url: `${config.BASE_API_URL}/users/${uid}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getUsersRequest(type) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: type
        ? `${config.BASE_API_URL}/users?type=${type}`
        : `${config.BASE_API_URL}/users`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAllUserEmailsRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/emails`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getClientsForAdvisorRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users?advisor=${uid}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function changeUserPasswordRequest(email, currentPassword, newPassword) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/changePassword/`,
      data: {
        email,
        currentPassword,
        newPassword,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function forgotUserPasswordRequest(uid, password) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/forgotPassword/`,
      data: {
        uid,
        password,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function sendForgotPasswordEmailRequest(email) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/sendForgotPasswordEmail/`,
      data: {
        email,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function editUserRequest(uid, payload = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'PATCH',
      url: `${config.BASE_API_URL}/users/${uid}/edit/`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function importUsersRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/import/`,
      data: {},
    })
      .then(resolve)
      .catch(reject)
  })
}

export function signUpWithEmailAndPasswordRequest(payload) {
  return new Promise((resolve, reject) => {
    const {
      email,
      firstName = '',
      lastName = '',
      password,
      type = '',
      ...rest
    } = payload
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/signup/`,
      data: {
        email,
        firstName,
        lastName,
        password,
        type,
        ...rest,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function sendCalendlyOauthCodeRequest(code) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/calendly-oauth`,
      data: { code },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function createUserWithEmailAndPasswordRequest(payload) {
  return new Promise((resolve, reject) => {
    const {
      email,
      firstName = '',
      lastName = '',
      photoURL = '',
      type = '',
      ...rest
    } = payload
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/create/`,
      data: {
        email,
        firstName,
        lastName,
        photoURL,
        type,
        ...rest,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function bulkUploadUsersRequest(
  usersList,
  sendWelcomeEmails,
  invitingUserDisplayName,
  invitingUserEmail,
  invitingUserPhotoUrl,
  userType,
  advisorUid,
  agencyUid,
  marketingListId
) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/users/import`,
      data: {
        usersList,
        sendWelcomeEmails,
        invitingUserDisplayName,
        invitingUserEmail,
        invitingUserPhotoUrl,
        userType,
        advisorUid,
        agencyUid,
        marketingListId,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getUserRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/${uid}/`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getEmailAnalyticsForUserRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/${uid}/analytics`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getEmailAnalyticsForClientsRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/${uid}/advisorAnalytics`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getUserStatusRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users-status`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function fetchBatchResultRequest(id) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/batch/${id}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function fetchBatchResponseRequest(id) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/batchresponse/${id}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function bulkDeleteUsersRequest(data) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/remove-users`,
      data,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function signInWithMailchimpUidRequest(data) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/uid-sign-in`,
      data,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getUserAnalyticsRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${config.BASE_API_URL}/users/analytics`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function sendTestInviteEmailRequest(data) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${config.BASE_API_URL}/send-test-invite-email`,
      data,
    })
      .then(resolve)
      .catch(reject)
  })
}
