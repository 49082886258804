import React from 'react'

import { Form, Input, Button, Space, message, Card, Select } from 'antd'
import { connect } from 'react-redux'
import FirebaseManager from '../../../FirebaseManager'

const { Option } = Select

const layout = {
  // labelCol: { xs: 8, sm: 6, md: 8, lg: 8, xl: 8 },
  // wrapperCol: { xs: 24, sm: 14, md: 12, lg: 10, xl: 8 },
}

const tailLayout = {
  wrapperCol: { span: 16 },
}

const supportTypes = [
  'Feature Request',
  'Account Help',
  'Bug Report',
  'General Question',
  'Other',
]

function GetHelp({ currentUser }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)

  const onFinish = (values) => {
    setLoading(true)
    FirebaseManager.getHelp({
      displayName: currentUser.displayName,
      email: values.email,
      type: values.supportType,
      subject: values.subject,
      description: values.description,
    })
      .then(() => {
        onReset()
        message.success(
          'Thanks for reaching out! Our team will get back to you.'
        )
        window.history.back()
      })
      .catch((error) => {
        console.warn(error)
        message.error(
          'Unable to submit support request. Please try again later.'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onReset = () => {
    form.resetFields()
  }

  function renderSupportTypes() {
    return supportTypes.map((type) => {
      return (
        <Option key={type} value={type}>
          {type}
        </Option>
      )
    })
  }

  return (
    <Card title="Get Help">
      <Form {...layout} form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="email"
          label="Email"
          initialValue={currentUser.email}
          rules={[{ required: true, message: 'Please provide an email.' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="subject"
          label="Subject"
          rules={[{ required: true, message: 'Please enter a subject.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="supportType"
          label="Support Type"
          rules={[{ required: true, message: 'Please select a support type.' }]}
        >
          <Select placeholder="Select a support type" allowClear>
            {renderSupportTypes()}
          </Select>
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please let us know what you need help with.',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button loading={loading} htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

const mapDispatchToProps = {}

function mapStateToProps({ session: { user } }) {
  return {
    currentUser: user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetHelp)
