export const SET_USERS_SUCCESS = 'SET_USERS_SUCCESS'

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const EDIT_USER_BEGIN = 'EDIT_USER_BEGIN'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const HANDLE_DELETE_USERS_SUCCESS = 'HANDLE_DELETE_USERS_SUCCESS'
