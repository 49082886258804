import React from 'react'

import { Button, Card } from 'antd'

function ArticleCard({ data }) {
  return (
    <Card hoverable onClick={() => window.open(data.url)}>
      <div>
        <h1 style={{ fontWeight: 'bold' }}>
          {data.title}
          <span
            style={{
              fontSize: 16,
              fontWeight: 'normal',
              color: 'rgba(0,0,0,0.8)',
              marginLeft: 10,
            }}
          >
            Via {data.publisher}
          </span>
        </h1>
        <img src={data.image.src} className="card-media" />
        <Button type="primary" block onClick={() => window.open(data.url)}>
          {data.buttonText}
        </Button>
      </div>
    </Card>
  )
}

export default React.memo(ArticleCard)
