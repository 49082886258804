import { produce } from 'immer'

import { SET_CALENDLY_VISIBILITY } from './types'

const INITIAL_STATE = {
  user: null,
  notes: '',
  isOpen: false,
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_CALENDLY_VISIBILITY:
      draft.isOpen = action.payload.isOpen
      if (action.payload.notes) {
        draft.notes = action.payload.notes
      }
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
