let baseFirebaseUrl = ''
if (process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    baseFirebaseUrl = 'http://localhost:5001/fpe-dev-8954f/us-central1'
  } else {
    baseFirebaseUrl = 'http://localhost:5001/fpe-prod/us-central1'
  }
} else {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    baseFirebaseUrl = 'https://us-central1-fpe-dev-8954f.cloudfunctions.net'
  } else {
    baseFirebaseUrl = 'https://us-central1-fpe-prod.cloudfunctions.net'
  }
}

const baseApiUrl = `${baseFirebaseUrl}/api`

const config = {
  BASE_API_URL: baseApiUrl,
  BASE_FIREBASE_URL: baseFirebaseUrl,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
}

export default config
