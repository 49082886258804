import { useSelector } from 'react-redux'

function usePrimaryColor() {
  const { user, teams, agencies } = useSelector((state) => {
    return {
      user: state.session.user,
      teams: state.teams.data || [],
      agencies: state.agencies.data || [],
    }
  })
  let color = '#61764E'
  if (user?.attributes?.teamUid) {
    const team = teams.find((team) => {
      return team.uid === user.attributes.teamUid
    })
    if (team?.teamColor) {
      color = team.teamColor
    }
  } else if (user?.attributes?.agencyUid) {
    const agency = agencies.find((agency) => {
      return agency.uid === user.attributes.agencyUid
    })
    if (agency?.firmColor) {
      color = agency.firmColor
    }
  }
  return color
}

export default usePrimaryColor
