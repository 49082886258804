import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import CalendlyEvent from './components/CalendlyEvent'

function CalendlyEventList({
  events = [],
  loading,
  handleCalendlyEventSelection,
  compact = false,
}) {
  if (loading) {
    return (
      <Spin
        indicator={
          <LoadingOutlined style={{ color: '#fff', fontSize: 24 }} spin />
        }
        size="large"
      />
    )
  }
  return (
    <div style={{ marginBottom: 10 }}>
      {loading ? (
        <Spin
          tip="Fetching your calendly events..."
          indicator={
            <LoadingOutlined style={{ color: '#fff', fontSize: 24 }} spin />
          }
          size="large"
        />
      ) : (
        <>
          <p
            style={{
              width: '80%',
              margin: compact ? undefined : 'auto',
              paddingBottom: compact ? 0 : 20,
            }}
          >
            Select the event you want to use for scheduling conversations with
            your members. We strongly recommend first{' '}
            <a
              href="https://calendly.com/event_types/new/solo?return_to=%2Fevent_types%2Fuser%2Fme"
              target="_blank"
              rel="noreferrer"
            >
              adding a "One-on-One" event
            </a>{' '}
            called "Financial Planning Experience" with a duration of 45
            minutes, and then refreshing this list before selecting your primary
            event.
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 10,
              overflowX: 'scroll',
            }}
          >
            {(events || []).map((event) => {
              return (
                <CalendlyEvent
                  data={event}
                  onPress={handleCalendlyEventSelection}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default CalendlyEventList
