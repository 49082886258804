import React from 'react'

import {
  Table,
  Tag,
  Space,
  message,
  Popconfirm,
  Button,
  ConfigProvider,
  Card,
} from 'antd'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import axiosInstance from '../../../../bootstrap/axios'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../../../features/session/redux/types'
import { handleDeleteUsersSuccess } from '../../../../features/users/redux/actions'
import {
  editUser,
  deleteUser,
} from '../../../../features/users/redux/operations'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import AgencyLink from '../../../../shared/components/Links/AgencyLink'
import TeamLink from '../../../../shared/components/Links/TeamLink'
import { openLink } from '../../../../shared/helpers/linkingHelpers'
import {
  returnUserTagColor,
  returnUserTagName,
} from '../../../../shared/helpers/returnUserTagColor'
import { returnUserTypeBasedOnCurrentUserRole } from '../../../../shared/helpers/returnUserTypeBasedOnCurrentUserRole'
import { defaultSorter } from '../../../../shared/helpers/tableHelpers'

function UsersView({
  data,
  loading,
  editUser,
  currentUserType,
  currentUser,
  agencies = [],
  teams = [],
  handleDeleteUsersSuccess,
}) {
  const navigate = useNavigate()
  const [selectedKeys, setSelectedKeys] = React.useState([])
  const [deletingBulkUsers, setDeletingBulkUsers] = React.useState(false)

  const firstNameFilters = data
    .filter((x) => x.displayName)
    .map((user) => {
      return {
        text: <span>{user.firstName}</span>,
        value: user.firstName,
      }
    })

  const lastNameFilters = data
    .filter((x) => x.displayName)
    .map((user) => {
      return {
        text: <span>{user.lastName}</span>,
        value: user.lastName,
      }
    })

  let columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      uid: 'firstName',
      sorter: function (a, b) {
        return defaultSorter(a, b, 'firstName')
      },
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text || '—'}</Link>
      },
      // filters: firstNameFilters.sort((a, b) => defaultSorter(a, b, 'value')),
      // onFilter: (value, record) => {
      //   return record.firstName.includes(value)
      // },
      // filterSearch: (input, record) => {
      //   return !!firstNameFilters.find((u) => {
      //     return u.value.includes(input.toLowerCase())
      //   })
      // },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      uid: 'lastName',
      sorter: function (a, b) {
        return defaultSorter(a, b, 'lastName')
      },
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text || '—'}</Link>
      },
      // filters: lastNameFilters.sort((a, b) => defaultSorter(a, b, 'value')),
      // onFilter: (value, record) => {
      //   return record.lastName.includes(value)
      // },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      uid: 'email',
      sorter: function (a, b) {
        return defaultSorter(a, b, 'email')
      },
      render: (text /* user */) => {
        return (
          <Popconfirm
            title="Open email client?"
            onConfirm={() => {
              return openLink(`mailto:${text}`)
            }}
            okText="Open"
          >
            <a href="#">{text}</a>
          </Popconfirm>
        )
      },
    },
    currentUserType !== ADVISOR_USER_TYPE
      ? {
          title: 'Firm',
          dataIndex: 'agencyUid',
          uid: 'agency',
          filters: agencies
            .map((agency) => {
              return {
                text: agency.name,
                value: agency.uid,
              }
            })
            .sort((a, b) => defaultSorter(a, b, 'value')),
          onFilter: (value, record) => {
            return record.agencyUid?.indexOf(value) === 0
          },
          render: (agency, user) => {
            return agency ? (
              <AgencyLink uid={agency} />
            ) : user?.type === ADVISOR_USER_TYPE ? (
              'Independent'
            ) : null
          },
        }
      : null,
    currentUserType !== ADVISOR_USER_TYPE
      ? {
          title: 'Team',
          dataIndex: 'teamUid',
          uid: 'team',
          filters: teams
            .map((team) => {
              return {
                text: team.name,
                value: team.uid,
              }
            })
            .sort((a, b) => defaultSorter(a, b, 'value')),
          onFilter: (value, record) => {
            return record.teamUid?.indexOf(value) === 0
          },
          render: (teamUid /* user */) => {
            if (teamUid) {
              return <TeamLink uid={teamUid} />
            } else {
              return null
            }
          },
        }
      : null,
    currentUserType !== ADVISOR_USER_TYPE
      ? {
          title: 'Role',
          uid: 'type',
          dataIndex: 'type',
          filters: [
            currentUserType === ADMIN_USER_TYPE && {
              text: 'Admin',
              value: ADMIN_USER_TYPE,
            },
            [ADMIN_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
              currentUserType
            ) && {
              text: 'Firm/Team Admin',
              value: AGENCY_ADMIN_USER_TYPE,
            },
            [
              ADMIN_USER_TYPE,
              ADVISOR_USER_TYPE,
              AGENCY_ADMIN_USER_TYPE,
            ].includes(currentUserType) && {
              text: 'Financial Planner',
              value: ADVISOR_USER_TYPE,
            },
            {
              text: 'Member',
              value: CLIENT_USER_TYPE,
            },
          ]
            .filter((x) => x)
            .sort((a, b) => defaultSorter(a, b, 'value')),
          onFilter: (value, record) => {
            return record.type.indexOf(value) === 0
          },
          render: (tag) => {
            const color = returnUserTagColor(tag)
            return (
              <Tag color={color} key={tag}>
                {returnUserTagName(tag)}
              </Tag>
            )
          },
        }
      : null,
    {
      title: 'Onboarded',
      dataIndex: 'onboarded',
      uid: 'onboarded',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.onboarded === value
      },
      render: (onboarded, user) => {
        return onboarded ? (
          <Tag color="green">YES</Tag>
        ) : (
          <>
            {/* <Popover
              content={() => {
                return (
                  <>
                    <div style={{ paddingBottom: 10 }}>
                      This will send an invite email that contains a password
                      reminder and sign in link.
                    </div>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        handleResendInviteEmail(user)
                      }}
                    >
                      Send
                    </Button>
                  </>
                )
              }}
              okText="Send"
              cancelText="Cancel"
              placement="bottomRight"
              title={<strong>Resend invite email?</strong>}
              trigger="hover"
            > */}
            <Tag style={{ cursor: 'pointer' }} color="red">
              NO
            </Tag>
            {/* </Popover> */}
          </>
        )
      },
    },
    {
      title: 'Actions',
      uid: 'actions',
      render: (text, user) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a onClick={() => handleEditUser(user)}>Edit</a>
            {user.onboarded ? null : (
              <a
                onClick={() => {
                  handleResendInviteEmail(user)
                }}
              >
                Re-Send Invite
              </a>
            )}
            {user?.type === CLIENT_USER_TYPE ? (
              <Popconfirm
                title="Are you sure you want to permanently delete this member?"
                okText="Yes"
                okType="danger"
                cancelText="No"
                onConfirm={() => handleDeleteUser(user)}
              >
                <a loading={deletingBulkUsers} style={{ color: 'red' }}>
                  Delete
                </a>
              </Popconfirm>
            ) : null}
          </div>
        )
      },
    },
  ].filter((x) => {
    return x
  })

  columns = columns.map((column) => {
    return {
      ...column,
      ellipses: true,
    }
  })

  function deleteBulkUsers() {
    setDeletingBulkUsers(true)
    const numberOfUsers = selectedKeys?.length
    axiosInstance
      .delete('/members-bulk', {
        data: JSON.stringify(selectedKeys),
      })
      .then(() => {
        console.log(
          `Deleted ${numberOfUsers} member${numberOfUsers === 1 ? '' : 's'}!`
        )
        setDeletingBulkUsers(false)
        handleDeleteUsersSuccess(selectedKeys)
        setSelectedKeys([])
        message.success(
          `Deleted ${numberOfUsers} member${numberOfUsers === 1 ? '' : 's'}!`
        )
      })
      .catch((error) => {
        console.warn('Could not delete users', error)
        setDeletingBulkUsers(false)
        message.error(
          `Could not delete member${numberOfUsers === 1 ? '' : 's'}`
        )
      })
  }

  function handleEditUser(user) {
    navigate(`/admin/users/${user.uid}/edit`)
  }

  function handleResendInviteEmail(user) {
    const payload = {
      ...user,
      isCreatedByAdmin: currentUserType === ADMIN_USER_TYPE,
      invitingUserDisplayName: currentUser?.attributes?.displayName,
      invitingUserPhotoUrl: currentUser?.attributes?.photoURL,
      invitingUserEmail: currentUser?.attributes?.email,
    }
    axiosInstance
      .post('/send-invite-email', JSON.stringify(payload))
      .then(() => {
        console.log(`Resent invitation to ${payload.email}!`)
        message.success(`Resent invitation to ${payload.email}!`)
      })
      .catch((error) => {
        console.warn('Could not resend invitation', error)
        message.error(`Could not resend invitation to ${payload.email}`)
      })
  }

  function handleDeleteUser(user) {
    const numberOfUsers = 1
    setDeletingBulkUsers(true)
    axiosInstance
      .delete('/members-bulk', {
        data: JSON.stringify([user]),
      })
      .then(() => {
        console.log(
          `Deleted ${numberOfUsers} member${numberOfUsers === 1 ? '' : 's'}!`
        )
        setDeletingBulkUsers(false)
        handleDeleteUsersSuccess([user])
        setSelectedKeys([])
        message.success(
          'This member has been deleted from your list, and will no longer receive email communication.'
        )
      })
      .catch((error) => {
        console.warn('Could not delete user', error)
        setDeletingBulkUsers(false)
        message.error(
          `Could not delete member${numberOfUsers === 1 ? '' : 's'}`
        )
      })
  }

  // function handleDeactivateUser(user, active = false) {
  //   editUser(user.uid, { active })
  //     .then(() => {
  //       message.success(active ? 'User Activated' : 'User Deactivated')
  //     })
  //     .catch((error) => {
  //       console.warn(error)
  //       message.error('Unable to change user status. Please try again later.')
  //     })
  // }

  function navigateToUserCreate() {
    navigate('/admin/users/create')
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRows)
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.type !== CLIENT_USER_TYPE,
        name: record.type,
      }
    },
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <ConfigProvider
        renderEmpty={() => (
          <FPEEmptyState
            description={`No ${returnUserTypeBasedOnCurrentUserRole(
              currentUserType
            ).toLowerCase()}s yet`}
          />
        )}
      >
        <Table
          title={() => {
            return (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 style={{ margin: 0, fontSize: 19, fontWeight: 'bold' }}>
                  All {returnUserTypeBasedOnCurrentUserRole(currentUserType)}s
                </h1>
                <Space>
                  {(data || []).length ? (
                    <FPECsvLink
                      filename={`master_user_list.csv`}
                      data={data || []}
                    />
                  ) : null}
                  {[ADMIN_USER_TYPE, ADVISOR_USER_TYPE].includes(
                    currentUserType
                  ) ? (
                    <Button type="primary" onClick={navigateToUserCreate}>
                      Create New{' '}
                      {returnUserTypeBasedOnCurrentUserRole(currentUserType)}
                    </Button>
                  ) : null}
                  {selectedKeys?.length ? (
                    <Popconfirm
                      title={`Are you sure you want to delete ${
                        selectedKeys?.length
                      } member${selectedKeys?.length === 1 ? '' : 's'}?`}
                      onConfirm={deleteBulkUsers}
                      okText="Yes"
                      okType="danger"
                      cancelText="Cancel"
                    >
                      <Button type="danger">
                        Delete {selectedKeys?.length} Member
                        {selectedKeys?.length === 1 ? '' : 's'}
                      </Button>
                    </Popconfirm>
                  ) : null}
                </Space>
              </Space>
            )
          }}
          rowSelection={
            currentUserType === ADMIN_USER_TYPE
              ? {
                  type: 'checkbox',
                  ...rowSelection,
                }
              : undefined
          }
          rowKey="uid"
          loading={loading || deletingBulkUsers}
          columns={columns}
          dataSource={data || []}
          scroll={{
            x: 600,
          }}
        />
      </ConfigProvider>
    </Card>
  )
}

function mapStateToProps({
  users = {},
  session: { loading, user },
  agencies: { data: agenciesData = [] },
  teams: { data: teamsData = [] },
}) {
  const currentUserType = user?.attributes?.type
  const currentUser = user
  return {
    loading,
    teams: teamsData,
    agencies: agenciesData,
    currentUser,
    currentUserType,
    data: (users.data || []).filter((user) => {
      if (user.uid === currentUser?.uid) {
        return false
      }
      if (currentUserType === ADMIN_USER_TYPE) {
        return user
      }
      if (
        currentUserType === ADVISOR_USER_TYPE ||
        currentUserType === AGENCY_ADMIN_USER_TYPE
      ) {
        return (
          user?.type !== ADMIN_USER_TYPE &&
          user?.type !== AGENCY_ADMIN_USER_TYPE &&
          user?.advisorUid === currentUser?.uid
        )
      } else {
        return user
      }
    }),
  }
}

const mapDispatchToProps = {
  editUser,
  deleteUser,
  handleDeleteUsersSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersView)
