import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Table,
  Space,
  Tag,
  message,
  Progress,
  Popconfirm,
  ConfigProvider,
  Button,
  Card,
} from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { editQuarterSuccess } from '../../../../features/quarters/redux/actions'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
} from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import { openLink } from '../../../../shared/helpers/linkingHelpers'

function QuartersView({
  data,
  numberOfAdvisors,
  currentUserUid,
  currentUserType,
  editQuarterSuccess,
}) {
  const [buttonsLoading, setButtonsLoading] = React.useState(false)
  const columns = [
    {
      title: 'Topic',
      dataIndex: 'name',
      uid: 'name',
      sorter: (a, b) => {
        return a.name - b.name
      },
      render: (text, quarter) => {
        const advisorVideo = quarter?.advisorVideos?.find((video) => {
          return (
            video.advisorUid?.toLowerCase?.() ===
            currentUserUid?.toLowerCase?.()
          )
        })
        return (
          <div>
            <Link to={`/admin/content/${quarter.uid}`}>{text}</Link>
            {currentUserType === ADVISOR_USER_TYPE && !advisorVideo ? (
              <Tag
                style={{ marginLeft: 10 }}
                color="red"
                icon={<ExclamationCircleOutlined />}
              >
                ACTION REQUIRED
              </Tag>
            ) : null}
          </div>
        )
      },
    },
    currentUserType === ADMIN_USER_TYPE
      ? {
          title: 'Status',
          dataIndex: 'published',
          uid: 'published',
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            return a.published - b.published
          },
          render: (status, record) => {
            return (
              <Tag
                color={
                  status
                    ? record.activeForClients
                      ? 'green'
                      : record.activeForAdvisors
                      ? 'orange'
                      : 'grey'
                    : 'grey'
                }
              >
                {status
                  ? record.activeForClients
                    ? 'LIVE'
                    : record.activeForAdvisors
                    ? 'ADVISORS ONLY'
                    : 'INACTIVE'
                  : 'DRAFT'}
              </Tag>
            )
          },
        }
      : null,
    {
      title: 'Quarter',
      dataIndex: 'quarter',
      uid: 'quarter',
      sorter: (a, b) => {
        return a.quarter - b.quarter
      },
      render: (quarter) => {
        switch (quarter) {
          case 1:
            return 'One'
          case 2:
            return 'Two'
          case 3:
            return 'Three'
          case 4:
            return 'Four'
          default:
            return quarter
        }
      },
    },
    {
      title: 'Year',
      dataIndex: 'year',
      uid: 'year',
      sorter: (a, b) => {
        return a.year - b.year
      },
    },
    currentUserType === ADMIN_USER_TYPE
      ? {
          title: 'Financial Planner Videos',
          dataIndex: 'advisorVideos',
          uid: 'advisorVideos',
          render: (advisorVideos, record) => {
            if (!record.published) {
              return ''
            } else if (record.activeForAdvisors && record.activeForClients) {
              return (
                <div style={{ fontStyle: 'italic' }}>
                  {advisorVideos?.length
                    ? `${Math.ceil(
                        (advisorVideos?.length / numberOfAdvisors) * 100
                      )}% (${
                        advisorVideos?.length
                      } total) of financial planners uploaded videos`
                    : 'No videos were uploaded'}
                </div>
              )
            } else if (!record.activeForAdvisors) {
              if (record.activeForClients) {
                return (
                  <div style={{ fontStyle: 'italic' }}>
                    Financial Planners have not been notified yet
                  </div>
                )
              } else {
                return null
              }
            }

            return (
              <Progress
                size="small"
                type="line"
                percent={Math.ceil(
                  (advisorVideos?.length / numberOfAdvisors) * 100
                )}
              />
            )
          },
        }
      : null,
    currentUserType === ADMIN_USER_TYPE
      ? {
          title: 'Action',
          uid: 'action',
          render: (text, quarter) => {
            return (
              <Space size="middle" direction="vertical">
                {!quarter.published ? (
                  <a
                    target="_blank"
                    href={`https://app.contentful.com/spaces/qdjvwbdpzhpv/entries/${quarter.uid}`}
                    rel="noreferrer"
                  >
                    Edit Draft in Contentful
                  </a>
                ) : quarter.activeForAdvisors && quarter.activeForClients ? (
                  <Button block type="primary" onClick={handleViewInMailchimp}>
                    View in Mailchimp
                  </Button>
                ) : quarter.activeForAdvisors ? (
                  <div>
                    <Space direction="vertical">
                      <Button
                        block
                        type="text"
                        onClick={() => handleEditMailchimpTemplate(quarter)}
                      >
                        Edit Mailchimp Template
                      </Button>
                      <Popconfirm
                        title={
                          <div style={{ maxWidth: 200 }}>
                            <strong>Create campaigns for all advisors?</strong>
                            <div>
                              This will not send any campaigns - it will only
                              make them available in Mailchimp for testing and
                              editing.
                            </div>
                            <br />
                            <div>
                              <strong>Important:</strong> This will also remove
                              all previous quarter's video thumbnails and add Q
                              {quarter.quarter}'s thumbnails.
                            </div>
                            <br />
                            <div>
                              This action can be performed as many times as
                              needed. For example, if new advisors were added
                              since the last time campaigns were staged, you
                              will need to re-generate the campaigns.
                            </div>
                          </div>
                        }
                        onConfirm={() => {
                          createMailChimpCampaigns(quarter)
                        }}
                        style={{ width: 200 }}
                        okText="Proceed"
                      >
                        <Button
                          type={quarter.campaignsStaged ? 'default' : 'primary'}
                          block
                          loading={buttonsLoading}
                        >
                          {quarter.campaignsStaged
                            ? 'Re-stage All Campaigns'
                            : 'Stage All Campaigns'}
                        </Button>
                      </Popconfirm>
                      {quarter.campaignsStaged ? (
                        <Popconfirm
                          title={`Send all Q${quarter.quarter} ${quarter.year} campaigns?`}
                          onConfirm={() => {
                            sendMailchimpCampaigns(quarter)
                          }}
                          okText="Send"
                        >
                          <Button block type="primary" loading={buttonsLoading}>
                            Send Campaigns
                          </Button>
                        </Popconfirm>
                      ) : null}
                    </Space>
                  </div>
                ) : null}
              </Space>
            )
          },
        }
      : null,
  ].filter((x) => x)

  function createMailChimpCampaigns(quarter) {
    setButtonsLoading(true)
    FirebaseManager.createMailChimpCampaigns(quarter)
      .then(() => {
        editQuarterSuccess({
          ...quarter,
          campaignsStaged: true,
        })
        message.success('All mailchimp campaigns have been staged!')
      })
      .catch((error) => {
        message.error(
          'We were unable to stage mailchimp campaign. Please try again later.'
        )
        console.warn(error)
      })
      .finally(() => {
        setButtonsLoading(false)
      })
  }

  function sendMailchimpCampaigns(quarter) {
    setButtonsLoading(true)
    FirebaseManager.sendMailchimpCampaigns(quarter)
      .then(() => {
        editQuarterSuccess({
          ...quarter,
          activeForClients: true,
        })
        message.success(
          'Mailchimp campaigns are sending. Click "View in Mailchimp" to check campaign statuses.'
        )
      })
      .catch((error) => {
        message.error(
          'We were unable to send mailchimp campaign. Please try again later.'
        )
        console.warn(error)
      })
      .finally(() => {
        setButtonsLoading(false)
      })
  }

  function handleEditMailchimpTemplate(quarter) {
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      openLink(
        `https://us10.admin.mailchimp.com/templates/design?tid=${quarter.mailchimpTemplateId}`
      )
    } else {
      openLink(
        `https://us14.admin.mailchimp.com/templates/design?tid=${quarter.mailchimpTemplateId}`
      )
    }
  }

  function handleViewInMailchimp() {
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      openLink(
        `https://us10.admin.mailchimp.com/campaigns/#f_list:all;t:campaigns-list`
      )
    } else {
      openLink(
        `https://us14.admin.mailchimp.com/campaigns/#f_list:all;t:campaigns-list`
      )
    }
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <ConfigProvider
        renderEmpty={() => <FPEEmptyState description="No content yet" />}
      >
        <Table
          title={() => {
            return (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 style={{ margin: 0, fontSize: 19, fontWeight: 'bold' }}>
                  All Conversations
                </h1>
                {(data || []).length ? (
                  <Space>
                    <FPECsvLink
                      filename={`master_conversations_list.csv`}
                      data={data || []}
                    />
                  </Space>
                ) : null}
              </Space>
            )
          }}
          rowKey="uid"
          loading={!data}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 600,
          }}
        />
      </ConfigProvider>
    </Card>
  )
}

function mapStateToProps({ quarters = {}, session: { user }, users = {} }) {
  const advisors = (users.data || []).filter(
    (user) => user.type === ADVISOR_USER_TYPE
  )
  return {
    numberOfAdvisors: advisors.length,
    data: quarters.quarterMetadata || [],
    currentUserUid: user?.attributes?.uid,
    currentUserType: user?.attributes?.type,
    currentUserAgencyUid: user?.attributes?.agency?.uid,
  }
}

const mapDispatchToProps = {
  editQuarterSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuartersView)
