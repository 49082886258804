import React from 'react'

import { connect } from 'react-redux'

import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import TeamCreateForm from '../TeamCreateForm'

function TeamEditForm({ team }) {
  return <TeamCreateForm editing team={team} />
}

function mapStateToProps({
  session: { user = {} },
  teams: { data: teamsData },
}) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 2]
  const team = teamsData.find((team) => {
    if (user?.attributes?.type === ADMIN_USER_TYPE) {
      return team.uid === uid
    } else {
      return team.uid === user?.attributes?.teamUid
    }
  })
  return {
    team,
  }
}

export default connect(mapStateToProps)(TeamEditForm)
