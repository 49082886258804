import React from 'react'

import { connect } from 'react-redux'

import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import AgencyCreateForm from '../AgencyCreateForm'

function AgencyEditForm({ agency }) {
  return <AgencyCreateForm editing agency={agency} />
}

function mapStateToProps({
  session: { user = {} },
  agencies: { data: agenciesData },
}) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 2]
  const agency = agenciesData.find((agency) => {
    if (user?.attributes?.type === ADMIN_USER_TYPE) {
      return agency.uid === uid
    } else {
      return agency.uid === user?.attributes?.agencyUid
    }
  })
  return {
    agency,
  }
}

export default connect(mapStateToProps)(AgencyEditForm)
