import { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'

function AdminAnalytics({ currentUser }) {
  const [analytics, setAnalytics] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
      navigate('/admin')
    } else {
      FirebaseManager.getUserAnalytics()
        .then((response) => {
          setAnalytics(response)
        })
        .catch(console.warn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
    return null
  }
  function renderAnalytics(analytics) {
    if (analytics) {
      return (
        <div>
          <ul>
            <li>
              Number of Advisors added in {new Date().getFullYear()}:{' '}
              {analytics.numAdvisorsAddedThisYear?.length || 0}
            </li>
            <li>
              Number of Clients added in {new Date().getFullYear()}:{' '}
              {analytics.numClientsAddedThisYear?.length || 0}
            </li>
          </ul>
        </div>
      )
    } else {
      return <div>No data</div>
    }
  }
  return (
    <div className="admin-analytics">
      <h1>Admin Analytics</h1>
      {renderAnalytics(analytics)}
    </div>
  )
}

function mapStateToProps({ session: { user = {} } }) {
  return {
    currentUser: user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAnalytics)
