import { current, produce } from 'immer'

import {
  SET_AGENCIES_SUCCESS,
  CREATE_AGENCY_BEGIN,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_ERROR,
  ADD_TEAM_TO_AGENCY,
  ADD_ADVISOR_TO_AGENCY,
  EDIT_AGENCY_BEGIN,
  EDIT_AGENCY_SUCCESS,
  EDIT_AGENCY_ERROR,
} from './types'

const INITIAL_STATE = {
  data: [],
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_AGENCIES_SUCCESS:
      draft.data = action.payload
      return draft
    case CREATE_AGENCY_BEGIN:
      return draft
    case CREATE_AGENCY_SUCCESS:
      draft.data.push(action.payload)
      return draft
    case CREATE_AGENCY_ERROR:
      return draft
    case EDIT_AGENCY_BEGIN:
      return draft
    case EDIT_AGENCY_SUCCESS:
      const agencyIndex = draft.data.findIndex((a) => {
        return a.uid === action.payload?.uid
      })
      if (agencyIndex !== -1) {
        draft.data[agencyIndex] = action.payload
      } else {
        console.warn('Could not find an agency to edit in EDIT_AGENCY_SUCCESS')
      }
      return draft
    case EDIT_AGENCY_ERROR:
      return draft
    case ADD_TEAM_TO_AGENCY:
      if (action.payload) {
        const agency = draft.data.find(
          (x) => x.uid === action.payload?.agencyUid
        )
        if (!agency.teams) {
          agency.teams = []
        }
        agency.teams.push(action.payload?.uid)
      }
      return draft
    case ADD_ADVISOR_TO_AGENCY:
      // if (action.payload) {
      //   const agency = draft.data.find(
      //     (x) => x.uid === action.payload.agencyUid
      //   )
      //   agency.teams.push(action.payload?.uid)
      // }
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
