import React from 'react'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import UserCreateForm from '../UserCreateForm'

function EditUser({ user }) {
  const navigate = useNavigate()
  function navigateBack() {
    navigate('/admin/users')
  }
  return <UserCreateForm editing user={user} onFinishCallback={navigateBack} />
}

function mapStateToProps({ users: { data: usersData = [] } }) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 2]
  const user =
    usersData?.find?.((user) => {
      return user.uid === uid
    }) || null
  return {
    user,
  }
}

export default connect(mapStateToProps)(EditUser)
