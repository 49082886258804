/* eslint-disable arrow-body-style */
import React from 'react'

import { Card, Col, Row, Form, Input, Button, message } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { logout, setUser } from '../../features/session/redux/actions'
import FirebaseManager from '../../FirebaseManager'
import { returnMessageForFirebaseError } from '../../FirebaseManager/helpers'

const LOGO_SOURCE = '/logo.png'

const layout = {}

const tailLayout = {
  wrapperCol: { span: 16 },
}

export default function PasswordReset() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('th')
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    ReactGA.event({ category: 'Password Reset View', action: 'password reset' })
    FirebaseManager.logEvent('Password Reset View')
    dispatch(logout())
    dispatch(setUser(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values) => {
    setLoading(true)
    FirebaseManager.forgotUserPassword(uid, values.password)
      .then(() => {
        message.success('Your password has been reset!')
        navigate('/login', { replace: true })
      })
      .catch((error) => {
        console.warn(error)
        message.error(returnMessageForFirebaseError(error))
        setLoading(false)
      })
  }

  return (
    <div>
      <Header
        className="landing-page-header"
        style={{
          padding: '25px 50px',
          height: 85,
          position: 'fixed',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 900,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img
              src={LOGO_SOURCE}
              style={{ width: 250, objectFit: 'contain' }}
            />
          </a>
        </div>
      </Header>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '100vh' }}
      >
        <Col lg={12} xs={24}>
          <Card bordered title="Password Reset">
            <Form {...layout} form={form} onFinish={onFinish}>
              <Form.Item
                name="password"
                label="New Password"
                labelCol={{ span: 24 }}
                labelWrap
                rules={[
                  {
                    required: true,
                    secure: true,
                    message: 'Please enter your new password.',
                  },
                  {
                    min: 6,
                    message: 'Password must be at least 6 characters.',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm New Password"
                labelCol={{ span: 24 }}
                labelWrap
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your new password.',
                  },
                  {
                    min: 6,
                    message: 'Password must be at least 6 characters.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The passwords that you entered do not match.'
                        )
                      )
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button loading={loading} type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
