/* eslint-disable import/prefer-default-export */
import FirebaseManager from '../../../FirebaseManager'
import { addAdvisorToAgency } from '../../agencies/redux/actions'
import { setCurrentUserAttributes } from '../../session/redux/actions'
import { ADVISOR_USER_TYPE } from '../../session/redux/types'
import {
  editUserBegin,
  editUserSuccess,
  editUserError,
  createUserBegin,
  createUserSuccess,
  createUserError,
  deleteUserBegin,
  deleteUserSuccess,
  deleteUserError,
} from './actions'

export const createUser =
  ({ email, firstName, lastName, type, ...rest }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(createUserBegin())
      FirebaseManager.createUser({
        email,
        firstName,
        lastName,
        type,
        ...rest,
      })
        .then((user) => {
          if (user.type === ADVISOR_USER_TYPE) {
            dispatch(addAdvisorToAgency(user))
          }
          dispatch(createUserSuccess(user))
          resolve(user)
        })
        .catch((error) => {
          dispatch(createUserError())
          reject(error)
        })
    })
  }

export const editUser =
  (uid, payload = {}) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        session: { user },
      } = getState()
      dispatch(editUserBegin())
      FirebaseManager.editUser(uid, payload)
        .then((updatedUser) => {
          dispatch(editUserSuccess(updatedUser))
          if (uid === user.uid) {
            dispatch(setCurrentUserAttributes(updatedUser))
          }
          resolve(updatedUser)
        })
        .catch((error) => {
          dispatch(editUserError())
          reject(error)
        })
    })
  }

export const deleteUser = (uid) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(deleteUserBegin())
    FirebaseManager.deleteUser(uid)
      .then(() => {
        dispatch(deleteUserSuccess(uid))
        resolve()
      })
      .catch((error) => {
        dispatch(deleteUserError())
        reject(error)
      })
  })
}
