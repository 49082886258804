import React from 'react'

import {
  Form,
  Button,
  Space,
  message,
  Select,
  Card,
  ConfigProvider,
} from 'antd'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ADVISOR_USER_TYPE } from '../../../../features/session/redux/types'
import { addAdvisorToTeam } from '../../../../features/teams/redux/operations'
import { fetchAllAppData } from '../../../../features/session/redux/operations'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}
const { Option } = Select

function TeamAddForm({ advisors = [], addAdvisorToTeam, fetchAllAppData }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id: agencyUid, teamId } = useParams() || {}
  const [loading, setLoading] = React.useState(false)

  const onFinish = (values) => {
    setLoading(true)
    const advisorUid = values.Advisor
    addAdvisorToTeam(teamId, agencyUid, advisorUid)
      .then(() => {
        setLoading(false)
        message.success('Team Updated!')
        fetchAllAppData()
        navigate(`/admin/firms/${agencyUid}/teams/${teamId}`)
      })
      .catch((error) => {
        setLoading(false)
        console.warn(error)
        message.error(
          'Unable to add financial planner to team. Please try again later.'
        )
      })
  }

  const onReset = () => {
    form.resetFields()
  }

  function renderAdvisors() {
    return advisors.map((advisor) => {
      return (
        <Option
          key={advisor.uid}
          value={advisor.uid}
          disabled={advisor.teamUid}
        >
          {advisor.displayName}{' '}
          {advisor.teamUid ? `(already assigned to a team)` : ''}
        </Option>
      )
    })
  }

  return (
    <Card title={`Add Financial Planner to Team`}>
      <Form form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          name="Advisor"
          label="Financial Planner"
          labelWrap
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: 'Please select a financial planner.' },
          ]}
          tooltip="This financial planner will be added to the team"
        >
          <Select placeholder="Select a financial planner" allowClear>
            {renderAdvisors()}
          </Select>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button loading={loading} htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

const mapDispatchToProps = {
  addAdvisorToTeam,
  fetchAllAppData,
}

function mapStateToProps({ users = {} }) {
  const paths = window.location.pathname.split('/').filter((x) => x)
  const agencyUid = paths[2]
  const advisors =
    users.data?.filter?.((user) => {
      return (
        user.type === ADVISOR_USER_TYPE &&
        user?.agencyUid &&
        user?.agencyUid === agencyUid
      )
    }) || []
  return {
    advisors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamAddForm)
