import {
  SET_USERS_SUCCESS,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  EDIT_USER_BEGIN,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  HANDLE_DELETE_USERS_SUCCESS,
} from './types'

export function handleDeleteUsersSuccess(selectedKeys) {
  return {
    type: HANDLE_DELETE_USERS_SUCCESS,
    payload: selectedKeys,
  }
}

export function setUsersSuccess(users = []) {
  return {
    type: SET_USERS_SUCCESS,
    payload: users,
  }
}

export function createUserBegin() {
  return {
    type: CREATE_USER_BEGIN,
  }
}

export function createUserSuccess(user = null) {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  }
}

export function createUserError() {
  return {
    type: CREATE_USER_ERROR,
  }
}

export function editUserBegin(user) {
  return {
    type: EDIT_USER_BEGIN,
    payload: user,
  }
}
export function editUserSuccess(user) {
  return {
    type: EDIT_USER_SUCCESS,
    payload: user,
  }
}
export function editUserError() {
  return {
    type: EDIT_USER_ERROR,
  }
}

export function deleteUserBegin() {
  return {
    type: DELETE_USER_BEGIN,
  }
}
export function deleteUserSuccess(user) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user,
  }
}

export function deleteUserError() {
  return {
    type: DELETE_USER_ERROR,
  }
}
