import React from 'react'

import { Card, Col, Row } from 'antd'
import './styles.css'
import { connect } from 'react-redux'

import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import ArticleCard from './components/ArticleCard'

function CatchMeUp({ articles, title, description }) {
  if (!articles?.length && !title && !description) {
    return (
      <Card title="Catch Me Up">
        <FPEEmptyState description={'No content yet'} />
      </Card>
    )
  }
  return (
    <Card title="Catch Me Up">
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: description }} />
      <Row gutter={[16, 16]} align="center" style={{ marginTop: 30 }}>
        {articles?.map((article) => {
          return (
            <Col xs={24} xl={9} key={`article_${article.title}`}>
              <ArticleCard key={article.title} data={article} />
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

function mapStateToProps({ quarters }) {
  const { activeQuarterContent } = quarters
  return {
    title: activeQuarterContent?.articles?.title,
    description: activeQuarterContent?.articles?.description,
    articles: activeQuarterContent?.articles?.articles || null,
  }
}

export default connect(mapStateToProps)(React.memo(CatchMeUp))
