import React from 'react'

import { Card, Col, Progress, Statistic, Tag } from 'antd'
import { Link } from 'react-router-dom'

import FirebaseManager from '../../../FirebaseManager'

function IndividualEmailAnalytics({ data: initialData, index }) {
  const timeout = React.useRef(null)
  const [localData, setLocalData] = React.useState(initialData)

  const fetchBatchResults = React.useCallback(
    (id) => {
      FirebaseManager.fetchBatchResult(id)
        .then(async (result) => {
          setLocalData((prevData) => {
            return {
              ...prevData,
              batch: result,
            }
          })
          if (result.status === 'finished') {
            FirebaseManager.fetchBatchResponse(id)
              .then((responseBody) => {
                const data = (responseBody || [])
                  .filter((x) => {
                    return x?.status_code === 200
                  })
                  .map(({ response }) => {
                    try {
                      const r = JSON.parse(response)
                      if (r) {
                        return {
                          email: r.email_address,
                          opens: r.activity.filter((x) => x.action === 'open'),
                          clicks: r.activity.filter(
                            (x) => x.action === 'click'
                          ),
                        }
                      } else {
                        return null
                      }
                    } catch (error) {
                      console.warn('Unable to parse response', error)
                      return null
                    }
                  })
                  .filter((x) => x)
                setLocalData((prevData) => {
                  return {
                    ...prevData,
                    data,
                  }
                })
              })
              .catch((error) => {
                console.warn('Unable to fetch data', error)
                console.warn(localData)
                setLocalData((prevData) => {
                  return {
                    ...prevData,
                    error: true,
                  }
                })
              })
          } else {
            setLocalData((prevData) => {
              return {
                ...prevData,
                batch: {
                  ...prevData.batch,
                  ...result,
                },
              }
            })
            timeout.current = setTimeout(() => fetchBatchResults(id), 3000)
          }
        })
        .catch((error) => {
          console.warn('Unable to recursively fetch analytics', error)
          setLocalData((prevData) => {
            return {
              ...prevData,
              error: true,
            }
          })
        })
    },
    [localData]
  )

  React.useEffect(() => {
    if (!localData?.data && !!localData?.batch?.id) {
      const id = localData?.batch?.id
      timeout.current = setTimeout(() => fetchBatchResults(id), index * 1000)
    }
    return function cleanup() {
      if (timeout.current) {
        console.log('Cancelling request')
      }
      clearTimeout(timeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { type, metadata, batch, error, data } = localData || {}

  function returnColorType() {
    switch (type?.toLowerCase?.()) {
      case 'account':
        return 'purple'
      case 'campaigns':
        return 'blue'
      default:
        return undefined
    }
  }

  function returnProgress() {
    switch (batch?.status) {
      case 'pending':
        return 0
      case 'preprocessing':
        return 25
      case 'started':
        return (
          50 +
          25 * (batch.finished_operations / batch.total_operations)
        ).toFixed(0)
      case 'finalizing':
        return 75
      case 'finished':
        return 100
      default:
        return null
    }
  }

  const progress = returnProgress(batch?.status)

  let numberOfOpens = 0
  let numberOfClicks = 0
  let openRate = 0
  let clickRate = 0
  let totalEmails = 0

  if (data) {
    totalEmails = data?.length || 0
    data.forEach((datum) => {
      if (datum?.clicks?.length) {
        numberOfClicks++
      }
      if (datum?.opens?.length) {
        numberOfOpens++
      }
    })
    openRate = ((numberOfOpens / totalEmails) * 100).toFixed(0)
    clickRate = ((numberOfClicks / totalEmails) * 100).toFixed(0)
  }

  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4} key={localData?.name}>
      <Card
        title={
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tag color={returnColorType()}>{type}</Tag>
              {progress === null ? null : (
                <Progress
                  percent={progress}
                  status={error ? 'exception' : undefined}
                  size="small"
                />
              )}
            </div>
            {metadata?.name ? (
              <div style={{ paddingTop: 5 }}>{metadata.name}</div>
            ) : null}
          </div>
        }
        loading={!error && !data && !!batch ? true : false}
      >
        {localData?.error ? (
          <p>
            Unable to generate this report. Please try again, or contact our{' '}
            <Link to="/admin/help">support team</Link> if this problem
            continues.
          </p>
        ) : data?.length === 0 ? (
          <p>No open or click data found for this campaign.</p>
        ) : (
          <>
            {totalEmails ? (
              <div
                style={{ fontWeight: 'bold' }}
                className="ant-statistic-title"
              >
                Total receipients: {totalEmails}
              </div>
            ) : null}
            <Statistic
              title={`Open Rate (${numberOfOpens} opens)`}
              value={openRate}
              precision={0}
              valueStyle={{
                color: openRate
                  ? openRate > 20
                    ? '#3f8600'
                    : '#F86B2C'
                  : '#F86B2C',
              }}
              prefix={null}
              suffix="%"
            />
            <Statistic
              title={`Click Rate (${numberOfClicks} clicks)`}
              value={clickRate}
              precision={0}
              valueStyle={{
                color: clickRate
                  ? clickRate > 20
                    ? '#3f8600'
                    : '#F86B2C'
                  : '#F86B2C',
              }}
              prefix={null}
              suffix="%"
            />
          </>
        )}
      </Card>
    </Col>
  )
}

export default IndividualEmailAnalytics
