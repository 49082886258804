import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import {
  Upload,
  Form,
  Input,
  Button,
  Space,
  message,
  Card,
  Select,
  Row,
  Col,
  Modal,
} from 'antd'
import { Colorpicker } from 'antd-colorpicker'
import ImgCrop from 'antd-img-crop'
import ColorThief from 'colorthief/dist/color-thief.mjs'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tinycolor from 'tinycolor2'

import {
  createAgency,
  editAgency,
} from '../../../../features/agencies/redux/operations'
import FirebaseManager from '../../../../FirebaseManager'
import states from './states'

const layout = {}

const tailLayout = {
  wrapperCol: { span: 16 },
}

const uploadImage = async ({ file, filename }) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      console.warn('No images to compress -- skipping')
      resolve('')
    } else {
      FirebaseManager.uploadFile(file, filename)
        .then((logoUrl) => {
          resolve(logoUrl)
        })
        .catch((error) => {
          console.warn(error)
          reject(error)
        })

      return {
        abort() {
          console.log('upload progress is aborted.')
        },
      }
    }
  })
}

function AgencyCreateForm({
  createAgency,
  editAgency,
  agency,
  editing = false,
  currentUserType,
}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)
  const [logo, setLogo] = React.useState(editing ? agency?.logoUrl : null)
  const [palette, setPalette] = React.useState(setInitialPalette)

  const navigate = useNavigate()

  function setInitialPalette() {
    if (agency?.logoUrl) {
      setPaletteFromLogo()
    }
  }

  function setPaletteFromLogo(timeout = 500) {
    setTimeout(() => {
      const colorThief = new ColorThief()
      const img = document.getElementsByClassName(
        'ant-upload-list-item-image'
      )?.[0]
      if (img.src?.includes?.('appspot')) {
        img.crossOrigin = 'Anonymous'
      } // Make sure image is finished loading

      function setColors() {
        const palettes = colorThief.getPalette(img, 5)
        const dominantArr = colorThief.getColor(img)
        const dominant = tinycolor(
          `rgb(${dominantArr[0]},${dominantArr[1]},${dominantArr[2]})`
        ).toHex8String()
        if (palettes?.length) {
          const hexColors = palettes.map((palette) => {
            return tinycolor(
              `rgb(${palette[0]},${palette[1]},${palette[2]})`
            ).toHex8String()
          })
          setPalette(hexColors)
          form.setFieldsValue({ firmColor: { hex: dominant } })
        }
      }
      if (img.complete) {
        setColors()
      } else {
        img.addEventListener('load', function () {
          setColors()
        })
      }
    }, timeout)
  }

  const onFinish = async (values) => {
    setLoading(true)
    const name = values.name
    values.firmColor = values.firmColor?.hex || values.firmColor
    const filename = `agency_photo_${name}.jpeg`
    let logoUrl
    let shouldContinue = true
    if (logo && logo?.lastModified) {
      try {
        logoUrl = await uploadImage({ file: logo, filename })
      } catch (error) {
        shouldContinue = false
        console.warn(error)
        message.error(
          'Unable to create firm with the supplied photo. Please try a different photo.'
        )
        setLoading(false)
      }
    }
    if (shouldContinue) {
      if (editing) {
        editAgency(agency.uid, {
          ...values,
          logoUrl,
        })
          .then((agency) => {
            message.success('Firm Updated!')
            navigate(`/admin/firms/${agency.uid}`)
          })
          .catch((error) => {
            console.warn(error)
            message.error('Unable to edit firm. Please try again later.')
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        createAgency({
          ...values,
          logoUrl,
        })
          .then((agency) => {
            onReset()
            message.success('Firm Created!')
            navigate(`/admin/firms/${agency.uid}`)
          })
          .catch((error) => {
            console.warn(error)
            if (error.response?.status === 426) {
              message.error(
                'We have reached our maximum amount of firms. Please contact the Financial Planning Experience team to proceed.'
              )
            } else {
              message.error('Unable to create firm. Please try again later.')
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const onReset = () => {
    form.resetFields()
    setLogo(null)
  }

  function renderStates() {
    return states.map((state) => {
      return (
        <Select.Option key={`state_dropdown_${state}`} value={state}>
          {state}
        </Select.Option>
      )
    })
  }

  function handleCreateMailchimpMarketingList(value) {
    if (!value) {
      Modal.warn({
        title: 'Warning',
        content: (
          <div>
            <p>
              Creating a Mailchimp marketing audience is the only way we can
              send emails to members on behalf of a financial planner. The only
              reason you would uncheck this is if an error had previously
              occured when creating an agency, and this option is only available
              to FPE admins.
            </p>
          </div>
        ),
        onOk() {},
      })
    }
  }

  return (
    <Card title={editing ? 'Edit Firm' : 'Create New Firm'}>
      <Form {...layout} form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="name"
          label="Name"
          initialValue={editing ? agency?.name : undefined}
          rules={[{ required: true, message: 'Please provide a firm name.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="address1"
          label="Address 1"
          initialValue={editing ? agency?.address1 : undefined}
          rules={[
            { required: true, message: 'Please provide a mailing address.' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="address2"
          label="Address 2"
          initialValue={editing ? agency?.address2 : undefined}
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              labelWrap
              labelCol={{ span: 24 }}
              name="city"
              label="City"
              initialValue={editing ? agency?.city : undefined}
              rules={[{ required: true, message: 'Please provide a city.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              labelWrap
              labelCol={{ span: 24 }}
              name="state"
              label="State"
              initialValue={editing ? agency?.state : undefined}
              rules={[{ required: true, message: 'Please provide a state.' }]}
            >
              <Select placeholder="Select a state" allowClear>
                {renderStates()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              labelWrap
              labelCol={{ span: 24 }}
              name="zip"
              label="Zip Code"
              initialValue={editing ? agency?.zip : undefined}
              rules={[
                { required: true, message: 'Please provide a zip code.' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="country"
          label="Country"
          initialValue="United States"
          rules={[{ required: true, message: 'Please provide a country.' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="officePhoneNumber"
          label="Office Phone"
          initialValue={editing ? agency?.officePhoneNumber : undefined}
          rules={[
            {
              required: true,
              message: 'Please provide an office phone number.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="website"
          label="Website URL"
          initialValue={editing ? agency?.website : undefined}
          rules={[{ required: true, message: 'Please provide a website url.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="logoUrl"
          label="Logo"
          rules={[
            {
              required: true,
              validator(/* _, value */) {
                if (logo) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Please upload a firm logo'))
              },
            },
          ]}
        >
          <ImgCrop modalTitle="Crop Logo" aspect={16 / 9}>
            <Upload
              accept="image/*"
              onRemove={() => {
                setLogo(null)
              }}
              listType="picture-card"
              valuePropName="fileList"
              beforeUpload={(file) => {
                file.thumbUrl = URL.createObjectURL(file)
                setLogo(file)
                setPaletteFromLogo()
                form.validateFields(['photoURL'])
                return false
              }}
              disabled={loading}
              fileList={
                logo
                  ? [
                      {
                        uid: '-1',
                        name: logo.name,
                        status: 'done',
                        url: logo,
                        thumbUrl: logo.thumbUrl,
                      },
                    ]
                  : []
              }
            >
              {logo ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="firmColor"
          label="Firm Brand Color"
          tooltip="Tip: Set your firm logo first, and we'll provide a list of colors to choose from."
          initialValue={editing ? agency?.firmColor : undefined}
          rules={[{ required: true, message: 'Please provide a brand color.' }]}
        >
          <Colorpicker
            popup
            disableAlpha
            presetColors={palette || []}
            styles={{
              picker: {
                width: 200,
                shadow: 'none',
              },
            }}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button loading={loading} htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

const mapDispatchToProps = {
  createAgency,
  editAgency,
}

function mapStateToProps({ session: { user } }) {
  return {
    currentUserType: user?.attributes?.type,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyCreateForm)
