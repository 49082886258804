import React from 'react'

import { ConfigProvider, Popconfirm, Table } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import FPEEmptyState from '../../../../../../shared/components/FPEEmptyState'
import { openLink } from '../../../../../../shared/helpers/linkingHelpers'
import { defaultSorter } from '../../../../../../shared/helpers/tableHelpers'

function AdvisorUserDetail({ user, clients }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      uid: 'name',
      sorter: function (a, b) {
        return defaultSorter(a, b, 'displayName')
      },
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text || '—'}</Link>
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      uid: 'email',
      sorter: function (a, b) {
        return defaultSorter(a, b, 'email')
      },
      render: (text) => {
        return (
          <Popconfirm
            title="Open email client?"
            onConfirm={() => {
              return openLink(`mailto:${text}`)
            }}
            okText="Open"
          >
            <a href="#">{text}</a>
          </Popconfirm>
        )
      },
    },
    // {
    //   title: 'Action',
    //   uid: 'action',
    //   render: (text, user) => {
    //     return (
    //       <Space size="middle">
    //         <a
    //           onClick={() => {
    //             // return handleDeactivateUser(user, !user.active)
    //           }}
    //         >
    //           {user.active ? 'Unassign' : 'Assign'}
    //         </a>
    //       </Space>
    //     )
    //   },
    // },
  ]

  function renderClientList() {
    return (
      <ConfigProvider
        renderEmpty={() => <FPEEmptyState description="No members yet" />}
      >
        <Table
          rowKey="uid"
          loading={!clients}
          columns={columns}
          dataSource={clients || []}
          scroll={{
            x: 600,
          }}
        />
      </ConfigProvider>
    )
  }
  return <div>{renderClientList()}</div>
}

function mapStateToProps({ users = {} }) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 1]
  const user =
    users.data?.find?.((user) => {
      return user.uid === uid
    }) || null
  const clients =
    users.data?.filter?.((user) => {
      return user.advisorUid === uid
    }) || []
  return {
    user,
    clients,
  }
}

export default connect(mapStateToProps)(AdvisorUserDetail)
