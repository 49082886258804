//@refresh reset
import React from 'react'

import { PlayCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

const VIDEO_SIZE = 750

function VideoJS(props) {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  let { options = {}, onReady } = props

  options.sources = (options.sources || []).map((source) => {
    if (source.type?.includes('quicktime') || source?.type?.includes('mov')) {
      source.type = 'video/mp4'
    }
    return { ...source }
  })

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current

      if (!videoElement) return

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
      }))

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [onReady, options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  )
}

export const FPEVideo = (props) => {
  let { options = {}, maxWidth = VIDEO_SIZE, showMask = true } = props
  const [modalIsVisible, setModalIsVisible] = React.useState(false)

  function handleModalClick(e) {
    e.preventDefault?.()
    setModalIsVisible(true)
  }

  function handleModalClose() {
    setModalIsVisible(false)
  }

  return (
    <>
      <div
        onClick={handleModalClick}
        style={{
          // height: maxWidth * (16 / 9),
          // width: maxWidth,
          // maxWidth: maxWidth,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <PlayCircleOutlined
          style={{
            filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
            zIndex: 30,
            position: 'absolute',
            color: '#fff',
            fontSize: '40px',
          }}
        />
        <video
          id="video"
          width={maxWidth}
          controls={false}
          poster={options.sources?.[0]?.thumbnail?.src}
          style={{
            // backgroundColor: '#000',
            // height: maxWidth * (16 / 9),
            width: maxWidth,
            borderRadius: 10,
            pointerEvents: 'none',
            overflow: 'hidden',
          }}
        >
          {options.sources.map((source, index) => {
            if (
              source.type?.includes('quicktime') ||
              source?.type?.includes('mov')
            ) {
              source.type = 'video/mp4'
            }
            return (
              <source
                key={`${source.src}_${index}`}
                src={source.src}
                type={source.type}
              />
            )
          })}
        </video>
      </div>
      <Modal
        onCancel={handleModalClose}
        centered
        maskClosable
        footer={null}
        destroyOnClose
        closeIcon={null}
        maskStyle={{
          backgroundColor: showMask ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0)',
        }}
        width={'90%'}
        height={'90%'}
        style={{
          padding: 0,
          height: '100%',
          borderRadius: 10,
          overflow: 'hidden',
        }}
        bodyStyle={{ padding: 0, height: '100%' }}
        visible={modalIsVisible}
        closable
      >
        <VideoJS {...props} />
      </Modal>
    </>
  )
}

export default FPEVideo
