import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../features/session/redux/types'

export function returnUserTagColor(type) {
  switch (type) {
    case ADMIN_USER_TYPE:
      return 'red'
    case AGENCY_ADMIN_USER_TYPE:
      return 'orange'
    case ADVISOR_USER_TYPE:
      return 'blue'
    case CLIENT_USER_TYPE:
      return 'green'
    default:
      console.warn('Unknown user type in tags', type)
      return 'black'
  }
}

export function returnUserTagName(type, upper = true, plural = false) {
  switch (type) {
    case ADMIN_USER_TYPE:
      return (upper ? 'ADMIN' : 'Admin') + (plural ? 's' : '')
    case AGENCY_ADMIN_USER_TYPE:
      return (
        (upper ? 'FIRM/TEAM ADMIN' : 'Firm/Team Admin') + (plural ? 's' : '')
      )
    case ADVISOR_USER_TYPE:
      return (
        (upper ? 'FINANCIAL PLANNER' : 'Financial Planner') +
        (plural ? 's' : '')
      )
    case CLIENT_USER_TYPE:
      return (upper ? 'MEMBER' : 'Member') + (plural ? 's' : '')
    default:
      console.warn('Unknown user type in tags', type)
      return ''
  }
}
