//@refresh reset
import React from 'react'

import {
  HomeOutlined,
  ProfileOutlined,
  CheckCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Card, Carousel, Spin, message, Row, Steps, Col } from 'antd'
import { connect } from 'react-redux'

import usePrimaryColor from '../../../../shared/hooks/usePrimaryColor'
import UserCreateForm from '../../../AdminDashboard/components/UserCreateForm'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const { Step } = Steps

const NUMBER_OF_WELCOME_ONBOARDING = 3

const whatYouCanExpect = [
  {
    title: 'Winter:  Financial Planning',
    description:
      'Discuss financial planning topics and goals (short, medium and long term): tax strategies, cash flow, net worth, core topics, and goals review.',
    image: '/expect_1.svg',
    imageAlign: 'left',
  },
  {
    title: 'Spring: Investment Planning',
    description:
      "Review portfolio performance, including account balances and holdings; revisit investment strategy, risk tolerance, and goal timelines. Let's put your money to work.",
    image: '/expect_2.svg',
    imageAlign: 'right',
  },
  {
    title: 'Summer: Protection Planning',
    description:
      'Review employee benefits, current cash flow and retirement savings, and long term needs such as estate planning, beneficiary designation, and long term care.',
    image: '/expect_3.svg',
    imageAlign: 'left',
  },
  {
    title: 'Fall: Year-End Tax Planning',
    description:
      'Year end tax planning, tax loss harvesting, RMD, and Tax distribution planning. Begin planning for the next year.',
    image: '/expect_4.svg',
    imageAlign: 'right',
  },
]

const educationalPieces = [
  {
    title: 'Scheduling',
    description:
      'Most importantly, we want to connect with YOU. There will be the ability to schedule a meeting to discuss any of these topics, or anything else on your mind.',
    image: '/content_6.svg',
  },
  {
    title: 'Introduction',
    description:
      'This will set the stage for any particularly relevant topics on financial planning - it could be changes in interest rates, market volatility, estate planning updates, etc. If you need to know it, you will find it here.',
    image: '/content_1.svg',
  },
  {
    title: 'Educational Video',
    description:
      "Reviewing key concepts related to the current quarter's conversations, the “Learn Me” section will reinforce lessons and key takeaways in an entertaining and memorable video.",
    image: '/content_2.svg',
  },
  {
    title: 'In the News',
    description:
      'Included will be relevant articles on timely changes in the financial planning landscape as well as the quarterly financial planning topic, titled "Catch Me Up".',
    image: '/content_3.svg',
  },
  {
    title: 'Quarterly Book',
    description:
      'Each quarter, there will be a new book selected that we believe will be of value. If you would like to receive a copy of the book, we would be happy to send it to you directly!',
    image: '/content_4.svg',
  },
  {
    title: 'Mindset Training',
    description:
      'Personal. Professional. Financial. All aspects of development are important, and interconnect in ways you may not realize. We will introduce you to key concepts in the realm of mindset training via the section called "Bigger Futures".',
    image: '/content_5.svg',
  },
]

function OnboardingCarousel({ onClose, currentUser }) {
  const [steps, setSteps] = React.useState([
    {
      title: 'Welcome',
      icon: <HomeOutlined />,
    },
    {
      title: 'Profile',
      icon: <ProfileOutlined />,
    },
    {
      title: 'Done',
      icon: <CheckCircleOutlined />,
    },
  ])

  function renderWhatYouCanExpect() {
    return whatYouCanExpect.map((item) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: item.imageAlign === 'left' ? 'row-reverse' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '50px 50px',
          }}
        >
          <div>
            <h3 style={{ fontWeight: 'bold' }}>{item.title}</h3>
            <p>{item.description}</p>
          </div>
          <div
            style={{
              width: '75%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={item.image}
              style={{
                margin: 'auto',
                width: '75%',
                objectFit: 'contain',
              }}
            />
          </div>
        </div>
      )
    })
  }

  const primaryColor = usePrimaryColor()

  function renderEducationalPieces() {
    return educationalPieces.map((item) => {
      return (
        <Col xxs={24} lg={12}>
          <Card
            bodyStyle={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ height: 400 }}
          >
            <img src={item.image} style={{ height: '50%', margin: 'auto' }} />
            <h3 style={{ fontWeight: 'bold', marginTop: 30 }}>{item.title}</h3>
            <p>{item.description}</p>
          </Card>
        </Col>
      )
    })
  }

  const [current, setCurrent] = React.useState(0)
  const [buttonLoading, setButtonLoading] = React.useState(false)

  const carouselRef = React.useRef(null)
  const userCreateFormRef = React.useRef(null)

  function getCurrent() {
    if (current >= NUMBER_OF_WELCOME_ONBOARDING) {
      return current - NUMBER_OF_WELCOME_ONBOARDING + 1
    } else {
      return 0
    }
  }

  const adjustedCurrent = getCurrent()

  const next = React.useCallback(
    (skipSubmit = false) => {
      if (adjustedCurrent === 1) {
        if (!skipSubmit) {
          setButtonLoading(true)
          userCreateFormRef.current.submitForm().catch((error) => {
            console.warn('Error in validation', error)
            message.warn('Please correct the errors below.')
            setButtonLoading(false)
          })
        } else {
          setCurrent(current + 1)
          carouselRef.current.next()
        }
      } else {
        setCurrent(current + 1)
        carouselRef.current.next()
      }
    },
    [adjustedCurrent, current]
  )

  const prev = () => {
    if (current - 1 > -1) {
      setCurrent(current - 1)
      carouselRef.current.prev()
    }
  }

  const handleFinish = React.useCallback(() => {
    onClose()
  }, [onClose])

  const setCarouselRef = React.useCallback((ref) => {
    carouselRef.current = ref
  }, [])

  const setUserCreateFormRef = React.useCallback((ref) => {
    userCreateFormRef.current = ref
  }, [])

  const onFinishCallback = React.useCallback(() => {
    // setCurrent(current + 1)
    setButtonLoading(false)
    next(true)
  }, [next])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Steps
        style={{
          position: 'absolute',
          padding: 24,
          backgroundColor: 'white',
          zIndex: 9999,
        }}
        current={getCurrent()}
      >
        {steps.map((item) => (
          <Step key={item.title} icon={item.icon} title={item.title} />
        ))}
      </Steps>
      <Carousel
        infinite={false}
        centerMode
        accessibility
        className="carousel-inner-div"
        dots={false}
        centerPadding={0}
        ref={setCarouselRef}
      >
        <div>
          <Row
            className="slick-inner-div"
            style={{
              height: '100%',
              padding: '75px 24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Col xs={24} md={12}>
              <h1
                style={{
                  textAlign: 'center',
                }}
              >
                Welcome to The Financial Planning Experience
              </h1>
              <p
                style={{
                  textAlign: 'center',
                  padding: '0 5rem',
                }}
              >
                You've taken the first step of many in embarking on a lifelong
                journey. You are now part of an exclusive club that has decided
                they are serious about achieving their financial potential.
              </p>
            </Col>
            <Col xs={24} md={12}>
              <img src="/balloons.svg" style={{ width: '100%' }} />
            </Col>
          </Row>
        </div>
        <div>
          <div
            className="slick-inner-div hide-scroll"
            style={{
              padding: '75px 100px',
              height: '100%',
              alignSelf: 'flex-end',
              overflow: 'scroll',
            }}
          >
            <h1>What You Can Expect</h1>
            <p>
              We deliver quarterly emails with educational, relevant,
              interactive, collaborative, actionable content that is always in
              the pursuit of your full potential. The delivery will follow a set
              format, arrive on a quarterly basis, and will provide education on
              the following:
            </p>
            {renderWhatYouCanExpect()}
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div hide-scroll"
            style={{
              padding: '75px 100px',
              height: '100%',
              alignSelf: 'flex-end',
              overflow: 'scroll',
            }}
          >
            <h1>Types of Content</h1>
            <p>
              In each of these educational pieces, there will be a few
              reoccurring sections and areas of focus:
            </p>
            <Row gutter={[16, 16]}>{renderEducationalPieces()}</Row>
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
            }}
          >
            <h1>First, does everything here look alright?</h1>
            <p>
              Feel free to make any changes, and your changes will save when you
              advance to the next slide.
            </p>
            <UserCreateForm
              ref={setUserCreateFormRef}
              onFinishCallback={onFinishCallback}
              finishingRegistration
              showCard={false}
              user={currentUser?.attributes}
              editing
            />
          </div>
        </div>
        <div>
          <div
            className="slick-inner-div"
            style={{
              padding: '75px 100px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1>You're all set! 🎉</h1>
            <p>
              Thank you for setting up your Financial Planning Experience
              profile.
            </p>
            <img src="/success.svg" style={{ width: 200, marginTop: 20 }} />
          </div>
        </div>
      </Carousel>
      <div
        className={current > 0 ? 'arrow-icon' : 'display-none'}
        style={{
          overflow: 'hidden',
          borderRadius: 1000,
          pointerEvents: buttonLoading ? 'none' : 'auto',
          position: 'absolute',
          left: 24,
          bottom: 24,
        }}
      >
        <LeftCircleOutlined
          style={{
            fontSize: '40px',
            color: buttonLoading ? 'grey' : primaryColor,
            backgroundColor: 'white',
          }}
          onClick={() => prev()}
        />
      </div>
      <div
        style={{
          borderRadius: 1000,
          overflow: 'hidden',
          pointerEvents: buttonLoading ? 'none' : 'auto',
          position: 'absolute',
          right: 24,
          bottom: 24,
        }}
      >
        {buttonLoading ? (
          <Spin indicator={antIcon} />
        ) : adjustedCurrent === 2 ? (
          <Button type="primary" onClick={handleFinish}>
            Let's Get Started
          </Button>
        ) : (
          <RightCircleOutlined
            style={{
              backgroundColor: 'white',
              fontSize: '40px',
              color: primaryColor,
            }}
            onClick={() => next()}
          />
        )}
      </div>
    </div>
  )
}

function mapStateToProps({ session: { user = {} } }) {
  // const paths = window.location.pathname.split('/')
  // const uid = paths[paths.length - 1]
  return {
    currentUser: user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingCarousel)
