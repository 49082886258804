import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
} from '../../features/session/redux/types'

export function returnUserTypeBasedOnCurrentUserRole(type) {
  switch (type) {
    case ADMIN_USER_TYPE:
      return 'User'
    case ADVISOR_USER_TYPE:
      return 'Member'
    default:
      return 'User'
  }
}
