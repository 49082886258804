import React from 'react'

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  ConfigProvider,
  message,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import axiosInstance from '../../../../bootstrap/axios'
import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'

const antIcon = <LoadingOutlined style={{ fontSize: 24, top: 2 }} spin />

function SyncMailchimp({ currentUser }) {
  const shouldCancel = React.useRef(false)
  const idArrayRef = React.useRef([])
  const navigate = useNavigate()
  const [syncLoading, setSyncLoading] = React.useState(false)
  const [data, setData] = React.useState([])

  if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
    navigate('/admin')
  }

  function recursivelyFetchUpdates(d) {
    if (!shouldCancel.current) {
      const ids = d.map((datum) => {
        return datum.id
      })
      if (ids?.length) {
        const commaSeparatedIds = ids.join(',')
        axiosInstance
          .get(`/batches?ids=${commaSeparatedIds}`)
          .then((response) => {
            setData(response?.data || [])
            const hasUnfinishedProcesses = !!(response?.data || []).find(
              (process) => {
                return process.status !== 'finished'
              }
            )
            if (hasUnfinishedProcesses) {
              setTimeout(() => {
                recursivelyFetchUpdates(response?.data || [])
              }, 500)
            } else {
              message.success('Mailchimp sync finished!')
              setSyncLoading(false)
            }
          })
          .catch((error) => {
            console.warn('Could not fetch batch updates', error)
            setSyncLoading(false)
            message.error('Could not fetch batch updates')
          })
      } else {
        shouldCancel.current = false
        message.warn('No records to update.')
        setSyncLoading(false)
      }
    } else {
      message.warn('Cancelling sync...')
      cancelSync()
    }
  }

  function cancelSync() {
    const commaSeparatedIds = idArrayRef.current.join(',')
    setData((prevData) => {
      return prevData.map((datum) => {
        return {
          ...datum,
          status: 'cancelling',
        }
      })
    })
    axiosInstance
      .delete(`/batches?ids=${commaSeparatedIds}`)
      .then(() => {
        setSyncLoading(false)
        setData((prevData) => {
          return prevData.map((datum) => {
            return {
              ...datum,
              status: 'cancelled',
            }
          })
        })
        message.success('Successfully cancelled Mailchimp sync')
      })
      .catch((error) => {
        console.warn(error)
        setSyncLoading(false)
        setData((prevData) => {
          return prevData.map((datum) => {
            return {
              ...datum,
              status: 'error',
            }
          })
        })
        message.warn('Unable to cancel mailchimp sync')
      })
  }

  function stopSync() {
    shouldCancel.current = true
  }

  function syncAllMailchimpUsers() {
    shouldCancel.current = false
    setSyncLoading(true)
    setData([])
    idArrayRef.current = []
    axiosInstance
      .post('/sync-mailchimp', null)
      .then((response) => {
        setData(response?.data || [])
        idArrayRef.current = (response?.data || []).map((datum) => {
          return datum.id
        })
        recursivelyFetchUpdates(response?.data || [])
        message.success('Mailchimp sync successfully started!')
      })
      .catch((error) => {
        console.warn('Could not sync members', error)
        setSyncLoading(false)
        message.error('Could not sync members')
      })
  }

  const columns = [
    {
      title: 'Batch ID',
      dataIndex: 'id',
      uid: 'id',
      render: (id, batch) => {
        if (batch.status === 'finished') {
          return (
            <a href={batch.response_body_url} target="_blank" rel="noreferrer">
              {id}
              <DownloadOutlined style={{ paddingLeft: 5 }} />
            </a>
          )
        } else {
          return id
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      uid: 'status',
      render: (text) => (
        <Tag color={text === 'error' ? 'red' : 'purple'}>
          {text?.toUpperCase?.()}
        </Tag>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'submitted_at',
      uid: 'submitted_at',
      render: (text) => moment(text).format('LT'),
    },
    {
      title: 'Total Operations',
      dataIndex: 'total_operations',
      uid: 'total_operations',
    },
    {
      title: 'Finished Operations',
      dataIndex: 'finished_operations',
      uid: 'finished_operations',
    },
    {
      title: 'Errored Operations',
      dataIndex: 'errored_operations',
      uid: 'errored_operations',
      render: (text, batch) =>
        text === 0 ? (
          syncLoading ? (
            text
          ) : batch.status === 'error' ? (
            text
          ) : batch.status === 'cancelled' || batch.status === 'cancelling' ? (
            text
          ) : (
            <Tag color="green">NO ERRORS</Tag>
          )
        ) : (
          <Tag color="red">
            {text} ERROR{text === 1 ? '' : 'S'}
          </Tag>
        ),
    },
    {
      title: 'End Time',
      dataIndex: 'completed_at',
      uid: 'completed_at',
      render: (text) => (text ? moment(text).format('LT') : '-'),
    },
  ]

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <ConfigProvider
        renderEmpty={() => <FPEEmptyState description="No batches yet" />}
      >
        <Table
          size="small"
          title={() => {
            return (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 style={{ margin: 0, fontSize: 19, fontWeight: 'bold' }}>
                  Sync Mailchimp
                </h1>
                <Space>
                  {syncLoading ? <Spin indicator={antIcon} /> : null}
                  <Button
                    type="primary"
                    danger={syncLoading}
                    onClick={syncLoading ? stopSync : syncAllMailchimpUsers}
                  >
                    {syncLoading ? 'Stop' : 'Begin'}
                  </Button>
                </Space>
              </Space>
            )
          }}
          sticky
          rowKey="id"
          loading={syncAllMailchimpUsers}
          columns={columns}
          dataSource={data || []}
          scroll={{
            x: 600,
            y: '75vh',
          }}
        />
      </ConfigProvider>
    </Card>
  )
}

function mapStateToProps({ session: { user = {} } }) {
  return {
    currentUser: user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SyncMailchimp)
