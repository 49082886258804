import { produce } from 'immer'

import {
  SET_USER,
  SET_CURRENT_USER_ATTRIBUTES,
  SET_ACCOUNTS_SUCCESS,
  SET_CURRENT_USER_ONBOARDING,
  SET_REMEMBER_ME,
  FETCH_APP_DATA_BEGIN,
  FETCH_APP_DATA_SUCCESS,
} from './types'

const INITIAL_STATE = {
  user: null,
  loading: false,
  rememberMe: false,
  accounts: [],
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_REMEMBER_ME:
      draft.rememberMe = !!action.payload
      return draft
    case SET_USER:
      draft.user = action.payload
      return draft
    case SET_CURRENT_USER_ONBOARDING:
      draft.user.attributes.onboarded = action.payload
      return draft
    case SET_CURRENT_USER_ATTRIBUTES:
      if (action.payload.displayName) {
        draft.user.displayName = action.payload.displayName
      }
      if (action.payload.active !== undefined) {
        draft.user.disabled = !action.payload.active
      }
      if (action.payload.email) {
        draft.user.email = action.payload.email
      }
      if (action.payload.photoURL) {
        draft.user.photoURL = action.payload.photoURL
      }
      draft.user.attributes = action.payload
      return draft
    case SET_ACCOUNTS_SUCCESS:
      draft.accounts = action.payload
      return draft
    case FETCH_APP_DATA_BEGIN:
      draft.loading = true
      return draft
    case FETCH_APP_DATA_SUCCESS:
      draft.loading = false
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
