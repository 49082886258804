import React from 'react'

import { Table, Space, message, Button, ConfigProvider, Card } from 'antd'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import AgencyLink from '../../../../shared/components/Links/AgencyLink'
import { defaultSorter } from '../../../../shared/helpers/tableHelpers'

function AgenciesView({ data, currentUserType }) {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      uid: 'name',
      sorter: (a, b) => {
        return defaultSorter(a, b, 'name')
      },
      render: (name, agency) => {
        return <AgencyLink uid={agency.uid} />
      },
    },
    {
      title: 'Admin',
      dataIndex: 'agencyAdmins',
      uid: 'agencyAdmins',
      render: (admins = []) => {
        if (!admins.length) {
          return <div>None set</div>
        }
        return admins.map((admin, index) => {
          return (
            <Link key={`${admin.uid}`} to={`/admin/users/${admin.uid}`}>
              {admin.displayName}
              {index === admins.length - 1 ? '' : ', '}
            </Link>
          )
        })
      },
    },
    // {
    //   title: 'Action',
    //   uid: 'action',
    //   render: (text, user) => {
    //     return (
    //       <Space size="middle">
    //         <a
    //           onClick={() => {
    //             return handleDeleteAgency(user)
    //           }}
    //         >
    //           Delete
    //         </a>
    //       </Space>
    //     )
    //   },
    // },
  ]

  function handleDeleteAgency(agency) {
    // setAgencies((previousAgencies) => {
    //   const indexToDelete = previousAgencies.findIndex((a) => {
    //     return a.uid === agency.uid
    //   })
    //   if (indexToDelete === -1) {
    //     console.warn('Could not find firm to delete')
    //   } else {
    //     previousAgencies.splice(indexToDelete, 1)
    //   }
    //   return [...previousAgencies]
    // })
    FirebaseManager.deleteAgency(agency.uid)
      .then(() => {
        message.success('Firm Deleted')
      })
      .catch((error) => {
        console.warn(error)
        message.error('Unable to delete firm. Please try again later.')
      })
  }

  function navigateToAgencyCreate() {
    navigate('/admin/firms/create')
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <ConfigProvider
        renderEmpty={() => <FPEEmptyState description="No firms yet" />}
      >
        <Table
          title={() => {
            return (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 style={{ margin: 0, fontSize: 19, fontWeight: 'bold' }}>
                  All Firms
                </h1>
                <Space>
                  {(data || []).length ? (
                    <FPECsvLink
                      filename={`master_firms_list.csv`}
                      data={data || []}
                    />
                  ) : null}
                  {currentUserType === ADMIN_USER_TYPE ? (
                    <Button type="primary" onClick={navigateToAgencyCreate}>
                      Create New Firm
                    </Button>
                  ) : null}
                </Space>
              </Space>
            )
          }}
          rowKey="uid"
          loading={!data}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 600,
          }}
        />
      </ConfigProvider>
    </Card>
  )
}

function mapStateToProps({ agencies = {}, session: { user } }) {
  const currentUserType = user?.attributes?.type
  return {
    currentUserType,
    data: agencies.data || [],
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesView)
