export function openLink(link) {
  window.open(link) || window.location.replace(link)
}

export function formatUrl(url) {
  if (!url) {
    return url
  }
  if (url?.includes?.('http')) {
    return url
  } else {
    return `https://${url}`
  }
}
