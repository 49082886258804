import { produce } from 'immer'

import {
  GET_ALL_TEAMS_BEGIN,
  GET_ALL_TEAMS_ERROR,
  GET_ALL_TEAMS_SUCCESS,
  SET_TEAMS_SUCCESS,
  ADD_TEAM_BEGIN,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  EDIT_TEAM_BEGIN,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_ERROR,
} from './types'

const INITIAL_STATE = {
  data: [],
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_TEAMS_SUCCESS:
      draft.data = action.payload
      return draft
    case GET_ALL_TEAMS_BEGIN:
      return draft
    case GET_ALL_TEAMS_SUCCESS:
      draft.data = action.payload
      return draft
    case GET_ALL_TEAMS_ERROR:
      return draft
    case ADD_TEAM_BEGIN:
      return draft
    case ADD_TEAM_SUCCESS:
      if (action.payload) {
        draft.data.push(action.payload)
      }
      return draft
    case ADD_TEAM_ERROR:
      return draft
    case EDIT_TEAM_BEGIN:
      return draft
    case EDIT_TEAM_SUCCESS:
      const teamIndex = draft.data.findIndex((a) => {
        return a.uid === action.payload?.uid
      })
      if (teamIndex !== -1) {
        draft.data[teamIndex] = action.payload
      } else {
        console.warn('Could not find an team to edit in EDIT_TEAM_SUCCESS')
      }
      return draft
    case EDIT_TEAM_ERROR:
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
