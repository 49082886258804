import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Form, Input, Button, Space, message, Card, Upload } from 'antd'
import { Colorpicker } from 'antd-colorpicker'
import ColorThief from 'colorthief/dist/color-thief.mjs'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import tinycolor from 'tinycolor2'

import { addTeam, editTeam } from '../../../../features/teams/redux/operations'
import FirebaseManager from '../../../../FirebaseManager'

const uploadImage = async ({ file, filename, onProgress = () => {} }) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      console.warn('No images to compress -- skipping')
      resolve('')
    } else {
      FirebaseManager.uploadFile(file, filename, onProgress)
        .then((logoUrl) => {
          resolve(logoUrl)
        })
        .catch((error) => {
          console.warn(error)
          reject(error)
        })

      return {
        abort() {
          console.log('upload progress is aborted.')
        },
      }
    }
  })
}

function TeamCreateForm({
  editing = false,
  team,
  editTeam,
  addTeam,
  inlineAgencyUid = null,
  inlineUserUid = null,
  closeInlineAgencyCreateModal,
}) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id, teamId } = useParams() || {}
  const [loading, setLoading] = React.useState(false)
  const [logo, setLogo] = React.useState(editing ? team?.logoUrl : null)
  const [palette, setPalette] = React.useState(setInitialPalette)

  function setInitialPalette() {
    if (team?.logoUrl) {
      setPaletteFromLogo()
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const filename = `team_photo_${values['Team Name']}.jpeg`
    let logoUrl = team?.logoUrl
    let shouldContinue = true
    values.teamColor = values.teamColor?.hex || values.teamColor
    if (logo && logo?.lastModified) {
      try {
        logoUrl = await uploadImage({ file: logo, filename })
      } catch (error) {
        shouldContinue = false
        console.warn(error)
        message.error(
          'Unable to edit firm with the supplied photo. Please try a different photo.'
        )
        setLoading(false)
      }
    }
    if (shouldContinue) {
      if (editing) {
        editTeam({
          name: values['Team Name'],
          uid: teamId,
          logoUrl,
          teamColor: values.teamColor,
          agencyUid: id,
        })
          .then((team) => {
            onReset()
            message.success('Team Edited!')
            navigate(`/admin/firms/${id}/teams/${team.uid}`)
          })
          .catch((error) => {
            setLoading(false)
            console.warn(error)
            message.error('Unable to edit team. Please try again later.')
          })
      } else {
        addTeam({
          name: values['Team Name'],
          logoUrl,
          teamColor: values.teamColor,
          agencyUid: inlineAgencyUid || id,
        })
          .then((team) => {
            onReset()
            message.success('Team Created!')
            if (inlineAgencyUid) {
              closeInlineAgencyCreateModal(team.uid, inlineUserUid)
            } else {
              navigate(`/admin/firms/${id}/teams/${team.uid}`)
            }
          })
          .catch((error) => {
            setLoading(false)
            console.warn(error)
            message.error('Unable to create team. Please try again later.')
          })
      }
    }
  }

  const onReset = () => {
    form.resetFields()
  }

  function setPaletteFromLogo(timeout = 500) {
    setTimeout(() => {
      const colorThief = new ColorThief()
      const img = document.getElementsByClassName(
        'ant-upload-list-item-image'
      )?.[0]
      if (img.src?.includes?.('appspot')) {
        img.crossOrigin = 'Anonymous'
      } // Make sure image is finished loading

      function setColors() {
        const palettes = colorThief.getPalette(img, 5)
        const dominantArr = colorThief.getColor(img)
        const dominant = tinycolor(
          `rgb(${dominantArr[0]},${dominantArr[1]},${dominantArr[2]})`
        ).toHex8String()
        if (palettes?.length) {
          const hexColors = palettes.map((palette) => {
            return tinycolor(
              `rgb(${palette[0]},${palette[1]},${palette[2]})`
            ).toHex8String()
          })
          setPalette(hexColors)
          if (!form.getFieldValue('teamColor')) {
            form.setFieldsValue({ teamColor: { hex: dominant } })
          }
        }
      }
      if (img.complete) {
        setColors()
      } else {
        img.addEventListener('load', function () {
          setColors()
        })
      }
    }, timeout)
  }

  return (
    <Card
      title={editing ? 'Edit Team' : 'Create Team'}
      style={{ height: inlineAgencyUid ? '100%' : undefined }}
    >
      <Form form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="Team Name"
          label="Team Name"
          initialValue={editing ? team?.name : undefined}
          rules={[{ required: true, message: 'Please add a team name.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="logoUrl"
          label="Team Logo"
          initialValue={editing ? team?.logoUrl : undefined}
          labelCol={{ span: 24 }}
          labelWrap
          rules={[
            (
              {
                /* getFieldValue */
              }
            ) => ({
              required: true,
              validator(/* _, value */) {
                if (logo) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Please add a team logo.'))
              },
            }),
          ]}
        >
          <Upload
            accept="image/*"
            onRemove={() => {
              setLogo(null)
            }}
            listType="picture-card"
            valuePropName="fileList"
            beforeUpload={(file) => {
              file.thumbUrl = URL.createObjectURL(file)
              setLogo(file)
              setPaletteFromLogo()
              form.validateFields(['logoUrl'])
              return false
            }}
            disabled={loading}
            fileList={
              logo
                ? [
                    {
                      uid: '-1',
                      name: logo.name,
                      status: 'done',
                      url: logo,
                      thumbUrl: logo.thumbUrl,
                    },
                  ]
                : []
            }
          >
            {logo ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          labelWrap
          labelCol={{ span: 24 }}
          name="teamColor"
          label="Team Brand Color"
          tooltip="Tip: Set your team logo first, and we'll provide a list of colors to choose from."
          initialValue={editing ? team?.teamColor : undefined}
          rules={[{ required: true, message: 'Please provide a brand color.' }]}
        >
          <Colorpicker
            popup
            disableAlpha
            presetColors={palette || []}
            styles={{
              picker: {
                width: 200,
                shadow: 'none',
              },
            }}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button loading={loading} htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}

const mapDispatchToProps = {
  addTeam,
  editTeam,
}

export default connect(null, mapDispatchToProps)(TeamCreateForm)
