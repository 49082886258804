import React from 'react'

import {
  PageHeader,
  Tabs,
  Tag,
  Table,
  ConfigProvider,
  Card,
  Button,
  Popconfirm,
  message,
} from 'antd'
import { groupBy } from 'lodash'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AdminUserDetail from './components/AdminUserDetail'
import AdvisorUserDetail from './components/AdvisorUserDetail'
import AgencyAdminUserDetail from './components/AgencyAdminUserDetail'
import ClientUserDetail from './components/ClientUserDetail'
import axiosInstance from '../../../../bootstrap/axios'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../../../features/session/redux/types'
import { handleDeleteUsersSuccess } from '../../../../features/users/redux/actions'
import FirebaseManager from '../../../../FirebaseManager'
import AdvisorContactCard from '../../../../shared/components/AdvisorContactCard'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import { formatUrl } from '../../../../shared/helpers/linkingHelpers'
import {
  returnUserTagColor,
  returnUserTagName,
} from '../../../../shared/helpers/returnUserTagColor'

const { TabPane } = Tabs

function UserDetail({ data, clients, handleDeleteUsersSuccess }) {
  const [emailAnalytics, setEmailAnalytics] = React.useState(null)
  const [deletingBulkUsers, setDeletingBulkUsers] = React.useState(false)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (data) {
      FirebaseManager.getEmailAnalyticsForUser(data.uid)
        .then((response) => {
          response = response.map((e) => {
            if (e.tags?.length) {
              e.tags = e.tags[0]
            } else {
              return null
            }
            return e
          })
          const emailsForUser = response.filter((email) => {
            return email.email === data.email
          })
          const groupedEmails = groupBy(emailsForUser, 'tags')
          delete groupedEmails['template-test']
          setEmailAnalytics(groupedEmails)
        })
        .catch((error) => {
          console.warn(error)
        })
    } else {
      console.warn('No data supplied to UserDetail')
    }
  }, [])

  if (!data) {
    return null
  }
  const color = returnUserTagColor(data.type)
  const name = returnUserTagName(data.type)
  function returnUserDetailView() {
    switch (data.type) {
      case ADMIN_USER_TYPE:
        return <AdminUserDetail user={data} />
      case AGENCY_ADMIN_USER_TYPE:
        return <AgencyAdminUserDetail user={data} />
      case ADVISOR_USER_TYPE:
        return <AdvisorUserDetail user={data} />
      case CLIENT_USER_TYPE:
        return <ClientUserDetail user={data} />
      default:
        return null
    }
  }

  function renderAccounts() {
    const columns = [
      {
        title: 'Type',
        dataIndex: 'url',
        uid: 'url',
        render: (text, account) => {
          return (
            <a
              key={`account_${text}`}
              target="_blank"
              href={formatUrl(text)}
              rel="noreferrer"
            >
              {account.type}
            </a>
          )
        },
      },
    ]
    return (
      <ConfigProvider
        renderEmpty={() => (
          <FPEEmptyState description="No platform links yet" />
        )}
      >
        <Table
          rowKey="uid"
          columns={columns}
          dataSource={data?.accounts || []}
          scroll={{
            x: 600,
          }}
        />
      </ConfigProvider>
    )
  }

  const extraContent = (clients || []).length ? (
    <FPECsvLink
      filename={`${data.displayName?.name?.replace?.(
        ' ',
        '_'
      )}_member_list.csv`}
      data={clients || []}
      className="btn btn-primary"
    />
  ) : null

  function handleEditUser() {
    navigate(`/admin/users/${data?.uid}/edit`)
  }

  function handleDeleteUser() {
    setDeletingBulkUsers(true)
    axiosInstance
      .delete('/members-bulk', {
        data: JSON.stringify([data]),
      })
      .then(() => {
        navigate('/admin/users')
        console.log('User deleted!')
        setDeletingBulkUsers(false)
        handleDeleteUsersSuccess([data])
        message.success(
          'This member has been deleted from your list, and will no longer receive email communication.'
        )
      })
      .catch((error) => {
        console.warn('Could not delete user', error)
        setDeletingBulkUsers(false)
        message.error('Could not delete user.')
      })
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={data.displayName || 'No Name'}
        subTitle={<Tag color={color}>{name}</Tag>}
        extra={
          data?.type === CLIENT_USER_TYPE
            ? [
                <Button onClick={handleEditUser}>Edit Member</Button>,
                <Popconfirm
                  title="Are you sure you want to permanently delete this member?"
                  onConfirm={handleDeleteUser}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button loading={deletingBulkUsers} type="danger">
                    Delete Member
                  </Button>
                </Popconfirm>,
              ]
            : [<Button onClick={handleEditUser}>Edit User</Button>]
        }
        footer={
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={extraContent}
            style={{ marginTop: 20 }}
          >
            {data?.type === ADVISOR_USER_TYPE ? (
              <TabPane tab="Members" key="1">
                {returnUserDetailView()}
              </TabPane>
            ) : null}
            {data?.type === ADVISOR_USER_TYPE ? (
              <TabPane tab="Platform Links" key="2">
                {renderAccounts()}
              </TabPane>
            ) : null}
          </Tabs>
        }
      >
        <AdvisorContactCard data={data} hasTitle={false} bordered={false} />
      </PageHeader>
    </Card>
  )
}

function mapStateToProps({ users = {}, agencies = {} }) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 1]
  const user = users.data?.find?.((user) => {
    return user.uid === uid
  })
  const agency =
    agencies.data?.find?.((agency) => {
      return agency.uid === user?.agencyUid
    }) || null
  const clients =
    users.data?.filter?.((user) => {
      return user.advisorUid === uid
    }) || []
  return {
    data: user,
    agency,
    clients,
  }
}

const mapDispatchToProps = {
  handleDeleteUsersSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
