import {
  SET_AGENCIES_SUCCESS,
  CREATE_AGENCY_BEGIN,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_ERROR,
  ADD_TEAM_TO_AGENCY,
  ADD_ADVISOR_TO_AGENCY,
  EDIT_AGENCY_BEGIN,
  EDIT_AGENCY_SUCCESS,
  EDIT_AGENCY_ERROR,
} from './types'

export function setAgenciesSuccess(agencies = []) {
  return {
    type: SET_AGENCIES_SUCCESS,
    payload: agencies,
  }
}

export function createAgencyBegin() {
  return {
    type: CREATE_AGENCY_BEGIN,
  }
}

export function createAgencySuccess(agency) {
  return {
    type: CREATE_AGENCY_SUCCESS,
    payload: agency,
  }
}

export function createAgencyError() {
  return {
    type: CREATE_AGENCY_ERROR,
  }
}

export function editAgencyBegin() {
  return {
    type: EDIT_AGENCY_BEGIN,
  }
}

export function editAgencySuccess(agency) {
  return {
    type: EDIT_AGENCY_SUCCESS,
    payload: agency,
  }
}

export function editAgencyError() {
  return {
    type: EDIT_AGENCY_ERROR,
  }
}

export function addTeamToAgency(team) {
  return {
    type: ADD_TEAM_TO_AGENCY,
    payload: team,
  }
}

export function addAdvisorToAgency(user) {
  return {
    type: ADD_ADVISOR_TO_AGENCY,
    payload: user,
  }
}
