import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import agenciesReducer from '../../features/agencies/redux/reducer'
import calendlyReducer from '../../features/calendly/redux/reducer'
import quartersReducer from '../../features/quarters/redux/reducer'
import sessionReducer from '../../features/session/redux/reducer'
import { LOGOUT } from '../../features/session/redux/types'
import teamsReducer from '../../features/teams/redux/reducer'
import usersReducer from '../../features/users/redux/reducer'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['calendly'],
}

const appReducer = combineReducers({
  session: sessionReducer,
  quarters: quartersReducer,
  users: usersReducer,
  agencies: agenciesReducer,
  teams: teamsReducer,
  calendly: calendlyReducer,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// https://github.com/reduxjs/redux-devtools/tree/main/extension#installation
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose

const enhancer = composeEnhancers(applyMiddleware(thunk))

const store = createStore(persistedReducer, enhancer)
const persistor = persistStore(store)

// persistor.purge()

export { store, persistor }
