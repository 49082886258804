export function defaultSorter(a, b, property) {
  if (property) {
    if (a[property] < b[property]) {
      return -1
    }
    if (a[property] > b[property]) {
      return 1
    }
  } else {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
  }
  return 0
}

export function createCSVData(data = []) {
  const y = data.map((item) => {
    for (var x in item) {
      if (typeof x !== 'string') {
        item[x] = JSON.string(item[x])
      }
    }
    return item
  })
  return y
}

export function capitalizeUnderscore(str = '') {
  const words = str.split('-')
  let finalStr = ''
  for (let i = 0; i < words.length; i++) {
    finalStr += `${words[i][0].toUpperCase() + words[i].substr(1)} `
  }
  return finalStr
}
