import React from 'react'

import { Card } from 'antd'
import './styles.css'
import { connect } from 'react-redux'

import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import FPEVideo from '../../../../shared/components/FPEVideo'

function MindsetMe({
  title,
  author,
  videoUrl,
  embed,
  videoThumbnail,
  description,
}) {
  const playerRef = React.useRef(null)

  if (!title && !videoUrl && !description && !embed) {
    return (
      <Card title="Mindset Training">
        <FPEEmptyState description={'No content yet'} />
      </Card>
    )
  }
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    // responsive: true,
    // fluid: true,
    fill: true,
    sources: [
      {
        thumbnail: videoThumbnail,
        src: videoUrl?.src,
        type: videoUrl?.contentType,
      },
    ],
  }

  function handleMindsetPlayerReady(player) {
    playerRef.current = player

    // You can handle player events here, for example:
    // player.on('waiting', () => {
    //   console.log('player is waiting')
    // })

    // player.on('dispose', () => {
    //   console.log('player will dispose')
    // })
  }

  return (
    <Card title="Mindset Training">
      <h2 style={{ fontStyle: author ? 'italic' : undefined }}>
        {title}
        {author ? (
          <span style={{ fontStyle: 'normal' }}>
            <span style={{ marginLeft: 15, marginRight: 10 }}>|</span>
            {author}
          </span>
        ) : null}
      </h2>
      <div>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {embed ? (
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0,
          }}
        >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={embed}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      ) : (
        <FPEVideo options={videoJsOptions} onReady={handleMindsetPlayerReady} />
      )}
    </Card>
  )
}

function mapStateToProps({ quarters }) {
  const { activeQuarterContent } = quarters
  return {
    title: activeQuarterContent?.mindsetMe?.title,
    author: activeQuarterContent?.mindsetMe?.author,
    description: activeQuarterContent?.mindsetMe?.description,
    videoUrl: activeQuarterContent?.mindsetMe?.videoUrl,
    embed: activeQuarterContent?.mindsetMe?.embed,
    videoThumbnail: activeQuarterContent?.mindsetMe?.videoThumbnail,
  }
}

export default connect(mapStateToProps)(MindsetMe)
