import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  connectAuthEmulator,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

import axiosInstance from '../bootstrap/axios'
import { returnMessageForFirebaseError } from './helpers'
import {
  createAgencyRequest,
  editAgencyRequest,
  getAgenciesRequest,
  getAgencyByUidRequest,
  addAdvisorToTeamRequest,
  editTeamRequest,
  addTeamToAgencyRequest,
  getAgencyTeamsRequest,
  getAllTeamsRequest,
  requestVerificationRequest,
  confirmVerificationRequest,
} from './network/agencies'
import {
  getQuartersRequest,
  getAllBooksRequest,
  requestBookRequest,
  addAdvisorVideoToQuarterRequest,
  quarterPatchRequest,
  createMailChimpCampaignsRequest,
  sendMailchimpCampaignsRequest,
} from './network/quarters'
import {
  getUserStatusRequest,
  fetchBatchResultRequest,
  fetchBatchResponseRequest,
  deleteUserRequest,
  getCurrentUserRequest,
  getUsersRequest,
  getAllUserEmailsRequest,
  editUserRequest,
  changeUserPasswordRequest,
  forgotUserPasswordRequest,
  sendForgotPasswordEmailRequest,
  signUpWithEmailAndPasswordRequest,
  createUserWithEmailAndPasswordRequest,
  bulkUploadUsersRequest,
  getUserRequest,
  getClientsForAdvisorRequest,
  getEmailAnalyticsForUserRequest,
  getEmailAnalyticsForClientsRequest,
  importUsersRequest,
  unsubscribeEmailsRequest,
  getHelpRequest,
  getAllAccountsRequest,
  getSupportedDomainsRequest,
  uploadProfilePhotoRequest,
  sendCalendlyOauthCodeRequest,
  bulkDeleteUsersRequest,
  signInWithMailchimpUidRequest,
  getUserAnalyticsRequest,
  sendTestInviteEmailRequest,
} from './network/users'

class FirebaseManager {
  constructor() {
    this.config =
      process.env.REACT_APP_ENVIRONMENT === 'dev'
        ? {
            apiKey: 'AIzaSyDTdRkUiI4YzytGyFAt7znokmYfCM7U_oE',
            authDomain: 'fpe-dev-8954f.firebaseapp.com',
            projectId: 'fpe-dev-8954f',
            storageBucket: 'fpe-dev-8954f.appspot.com',
            messagingSenderId: '462579335294',
            databaseURL: 'https://fpe-dev-8954f.firebaseio.com',
            appId: '1:462579335294:web:4806fa96b6ad34d5eb182f',
            measurementId: 'G-DYTQ9RY3D5',
          }
        : {
            apiKey: 'AIzaSyCYsCaktwBtxRNio66fX_H4EBPqDg30z_g',
            authDomain: 'fpe-prod.firebaseapp.com',
            projectId: 'fpe-prod',
            storageBucket: 'fpe-prod.appspot.com',
            messagingSenderId: '787197236048',
            appId: '1:787197236048:web:6d4d6b15959a67b4801255',
            measurementId: 'G-5BFRZ2YBP8',
          }
    this.app = initializeApp(this.config)
    this.db = getFirestore(this.app)
    this.auth = getAuth(this.app)
    this.storage = getStorage(this.app)
    this.analytics = getAnalytics(this.app)

    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_ENVIRONMENT === 'dev'
    ) {
      console.log('Connecting to Firebase Emulators')
      connectFirestoreEmulator(this.db, 'http://localhost:8080')
      connectAuthEmulator(this.auth, 'http://localhost:9099')
      connectStorageEmulator(this.storage, 'localhost', 9199, {
        mockUserToken: {
          user_id: 'yBreQ1H7nwhgxy749nCjaxgba4ZL',
        },
      })
    }

    this.user = null
  }

  setUser = (user) => {
    this.user = user
  }

  logEvent = (name = '', params = {}) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Logging event', name, params)
      logEvent(this.analytics, name, params)
    }
  }

  uploadFileDev = (file, type, filename, folder, onProgress) => {
    return new Promise((resolve, reject) => {
      const imagesRef = ref(
        this.storage,
        folder ? `${folder}/${filename}` : `${filename}`
      )
      const metadata = {
        contentType: type,
      }
      // 'file' comes from the Blob or File API
      const uploadTask = uploadBytesResumable(imagesRef, file, metadata)

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          onProgress &&
            onProgress(snapshot.bytesTransferred / snapshot.totalBytes)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              return console.warn(
                'Unknown state in snapshot.state',
                snapshot.state
              )
          }
        },
        (error) => {
          reject(error)
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)
            resolve(downloadURL)
          })
        }
      )
    })
  }

  uploadFile = async (file, filename, onProgress) => {
    const url = await uploadProfilePhotoRequest(file, filename, onProgress)
    return url
  }

  deleteAgency = (agencyUid) => {
    return agencyUid
  }

  deleteUser = (uid) => {
    return new Promise((resolve, reject) => {
      deleteUserRequest(uid)
        .then(() => {
          resolve(uid)
        })
        .catch(reject)
    })
  }

  signUpWithEmailAndPassword = ({
    email,
    firstName,
    lastName,
    password,
    type,
  }) => {
    return new Promise((resolve, reject) => {
      signUpWithEmailAndPasswordRequest({
        email,
        firstName,
        lastName,
        password,
        type,
      })
        .then((response) => {
          signInWithCustomToken(this.auth, response.data.accessToken)
            .then((userCredential) => {
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${userCredential.user.accessToken}`
              const user = userCredential.user
              getUserRequest(user.uid)
                .then((response) => {
                  const u = {
                    ...response.data,
                    accessToken: user.accessToken,
                  }
                  this.user = u
                  resolve(u)
                })
                .catch(reject)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  changeUserPassword = (email, currentPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      changeUserPasswordRequest(email, currentPassword, newPassword)
        .then((response) => {
          signInWithCustomToken(this.auth, response.data.accessToken)
            .then((userCredential) => {
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${userCredential.user.accessToken}`
              const user = userCredential.user
              getUserRequest(user.uid)
                .then((response) => {
                  const u = {
                    ...response.data,
                    accessToken: user.accessToken,
                  }
                  this.user = u
                  resolve(u)
                })
                .catch(reject)
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  forgotUserPassword = (uid, newPassword) => {
    return new Promise((resolve, reject) => {
      forgotUserPasswordRequest(uid, newPassword)
        .then((response) => {
          resolve(response?.data)
        })
        .catch(reject)
    })
  }

  sendForgotPasswordEmail = (email) => {
    return new Promise((resolve, reject) => {
      sendForgotPasswordEmailRequest(email)
        .then((response) => {
          resolve(response?.data)
        })
        .catch(reject)
    })
  }

  signInWithMailchimpUid = (email, uid, listId) => {
    return new Promise((resolve, reject) => {
      signInWithMailchimpUidRequest({ email, uid, listId })
        .then((response) => {
          this.user = response.data
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${this.user.accessToken}`
          resolve(this.user)
        })
        .catch((error) => {
          axiosInstance.defaults.headers.common.Authorization = null
          console.warn(error)
          reject(error)
        })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${userCredential.user.accessToken}`
          const user = userCredential.user
          getUserRequest(user.uid)
            .then((response) => {
              this.user = {
                ...response.data,
                accessToken: user.accessToken,
              }
              resolve(this.user)
            })
            .catch(reject)
        })
        .catch((error) => {
          axiosInstance.defaults.headers.common.Authorization = null
          console.warn(error)
          reject(error)
        })
    })
  }

  /**
   * FIREBASE API CALLS
   */
  getAllTeams = () => {
    return new Promise((resolve, reject) => {
      getAllTeamsRequest()
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAgencyTeams = (agencyUid) => {
    return new Promise((resolve, reject) => {
      getAgencyTeamsRequest(agencyUid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  addTeamToAgency = (payload) => {
    return new Promise((resolve, reject) => {
      addTeamToAgencyRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  editTeam = (payload) => {
    return new Promise((resolve, reject) => {
      editTeamRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  addAdvisorToTeam = (teamId, agencyUid, advisorUid) => {
    return new Promise((resolve, reject) => {
      addAdvisorToTeamRequest(teamId, agencyUid, advisorUid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAgencyByUid = (uid) => {
    return new Promise((resolve, reject) => {
      getAgencyByUidRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAgencies = () => {
    return new Promise((resolve, reject) => {
      getAgenciesRequest()
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  createAgency = (payload) => {
    return new Promise((resolve, reject) => {
      createAgencyRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  editAgency = (uid, payload) => {
    return new Promise((resolve, reject) => {
      editAgencyRequest(uid, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  importUsers = ({}) => {
    return new Promise((resolve, reject) => {
      importUsersRequest()
        .then(resolve)
        .catch((error) => {
          reject(returnMessageForFirebaseError(error?.response?.data))
        })
    })
  }

  createUser = (payload) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPasswordRequest(payload)
        .then((response) => {
          const data = response.data
          resolve(data)
        })
        .catch(reject)
    })
  }

  bulkUploadUsers = (
    usersList,
    sendWelcomeEmails,
    invitingUserDisplayName,
    invitingUserEmail,
    invitingUserPhotoUrl,
    userType,
    advisorUid,
    agencyUid,
    marketingListId
  ) => {
    return new Promise((resolve, reject) => {
      bulkUploadUsersRequest(
        usersList,
        sendWelcomeEmails,
        invitingUserDisplayName,
        invitingUserEmail,
        invitingUserPhotoUrl,
        userType,
        advisorUid,
        agencyUid,
        marketingListId
      )
        .then((response) => {
          const data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(returnMessageForFirebaseError(error?.response?.data))
        })
    })
  }

  getCurrentUser = async () => {
    return new Promise((resolve, reject) => {
      getCurrentUserRequest(this.user.uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getUsers = async (type) => {
    return new Promise((resolve, reject) => {
      getUsersRequest(type)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAllUserEmails = async () => {
    return new Promise((resolve, reject) => {
      getAllUserEmailsRequest()
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getUsersForType = async (type) => {
    return new Promise((resolve, reject) => {
      getUsersRequest(type)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getUserById = (uid) => {
    return new Promise((resolve, reject) => {
      getUserRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  editUser = (uid, payload) => {
    // console.log(`Applying changes to ${uid}`, payload)
    return new Promise((resolve, reject) => {
      editUserRequest(uid, payload)
        .then((response) => {
          const user = response.data
          resolve(user)
        })
        .catch(reject)
    })
  }

  getClientsForAdvisor = (uid) => {
    return new Promise((resolve, reject) => {
      getClientsForAdvisorRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getQuarters = (uid) => {
    return new Promise((resolve, reject) => {
      getQuartersRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  addAdvisorVideoToQuarter = (
    currentUserUid,
    userName,
    quarterUid,
    fileType,
    url,
    thumbnailUrl
  ) => {
    return new Promise((resolve, reject) => {
      addAdvisorVideoToQuarterRequest(
        currentUserUid,
        userName,
        quarterUid,
        fileType,
        url,
        thumbnailUrl
      )
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  createMailChimpCampaigns = (quarter) => {
    return new Promise((resolve, reject) => {
      createMailChimpCampaignsRequest(quarter.uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  sendMailchimpCampaigns = (quarter) => {
    return new Promise((resolve, reject) => {
      sendMailchimpCampaignsRequest(quarter.uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  patchQuarterRequest = (quarter, agencyUid, payload = {}) => {
    return new Promise((resolve, reject) => {
      quarterPatchRequest(quarter.uid, agencyUid, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getEmailAnalyticsForUser = (uid) => {
    return new Promise((resolve, reject) => {
      getEmailAnalyticsForUserRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getEmailAnalyticsForClients = (uid) => {
    return new Promise((resolve, reject) => {
      getEmailAnalyticsForClientsRequest(uid)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  requestBook = (payload) => {
    return new Promise((resolve, reject) => {
      requestBookRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAllBooks = () => {
    return new Promise((resolve, reject) => {
      getAllBooksRequest()
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  sendCalendlyOauthCode = (code) => {
    return new Promise((resolve, reject) => {
      sendCalendlyOauthCodeRequest(code)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getHelp = (payload) => {
    return new Promise((resolve, reject) => {
      getHelpRequest(payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getAllAccounts = () => {
    return new Promise((resolve, reject) => {
      getAllAccountsRequest()
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  unsubscribeEmail = (email) => {
    return new Promise((resolve, reject) => {
      unsubscribeEmailsRequest(email)
        .then((response) => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  getSupportedDomains = () => {
    return new Promise((resolve, reject) => {
      getSupportedDomainsRequest()
        .then((response) => {
          resolve(response.data || [])
        })
        .catch(reject)
    })
  }

  requestVerification = (values) => {
    return new Promise((resolve, reject) => {
      requestVerificationRequest(values)
        .then((response) => {
          resolve(response.data || [])
        })
        .catch(reject)
    })
  }

  confirmVerification = (values) => {
    return new Promise((resolve, reject) => {
      confirmVerificationRequest(values)
        .then((response) => {
          resolve(response.data || [])
        })
        .catch(reject)
    })
  }

  getUserStatus = () => {
    return new Promise((resolve, reject) => {
      getUserStatusRequest()
        .then((response) => {
          resolve(response.data || [])
        })
        .catch(reject)
    })
  }

  fetchBatchResult = (id) => {
    return new Promise((resolve, reject) => {
      fetchBatchResultRequest(id)
        .then((response) => {
          resolve(response.data || {})
        })
        .catch(reject)
    })
  }

  fetchBatchResponse = (id) => {
    return new Promise((resolve, reject) => {
      fetchBatchResponseRequest(id)
        .then((response) => {
          resolve(response.data || {})
        })
        .catch(reject)
    })
  }

  bulkDeleteUsers = (data) => {
    return new Promise((resolve, reject) => {
      bulkDeleteUsersRequest(data)
        .then((response) => {
          resolve(response.data || {})
        })
        .catch(reject)
    })
  }

  getUserAnalytics = (data) => {
    return new Promise((resolve, reject) => {
      getUserAnalyticsRequest(data)
        .then((response) => {
          resolve(response.data || {})
        })
        .catch(reject)
    })
  }

  sendTestInviteEmail = (data) => {
    return new Promise((resolve, reject) => {
      sendTestInviteEmailRequest(data)
        .then((response) => {
          resolve(response.data || {})
        })
        .catch(reject)
    })
  }
}

export default new FirebaseManager()
