import React from 'react'

import { Button, Card, Col, Form, Input, message, Modal, Row } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { connect } from 'react-redux'

import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'

function BookOfTheQuarter({
  title,
  author,
  image,
  description,
  url,
  audioBookUrl,
  buttonText,
  currentUser,
}) {
  const [modalVisibility, setModalVisibility] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [form] = Form.useForm()

  function handleRequestBook() {
    setModalVisibility(true)
  }

  function handleFinish(values) {
    setLoading(true)
    const address = `${values.addressLine1}${
      values.addressLine2 ? ` ${values.addressLine2}` : ''
    }, ${values.city}, ${values.state} ${values.zipCode}`
    FirebaseManager.requestBook({
      bookName: title,
      author,
      userFirstName: currentUser?.attributes?.firstName,
      userName: currentUser?.displayName,
      userEmail: currentUser?.email,
      address,
      bookUrl: values.isAudioBook ? audioBookUrl : url,
      advisorUid: currentUser?.attributes?.advisorUid,
      isAudioBook: !!values.isAudioBook,
    })
      .then(() => {
        setLoading(false)
        setModalVisibility(false)
        message.success('Your financial planner has received your request!')
        console.log('Requested!')
      })
      .catch((error) => {
        setLoading(false)
        message.error(
          'We were unable to send your request. Please try again later.'
        )
        console.warn(error)
      })
  }

  if (!title) {
    return (
      <Card>
        <FPEEmptyState description={'No content yet'} />
      </Card>
    )
  }

  return (
    <Card>
      <Row align="center" gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <h1
            style={{
              marginBottom: 0,
              fontFamily: 'Raleway-Medium',
              fontSize: 40,
            }}
          >
            Send Me
          </h1>
          <p
            style={{
              marginTop: 0,
              marginBottom: 20,
              fontFamily: 'CormorantGaramond-MediumItalic',
              fontSize: 35,
            }}
          >
            The Book of The Quarter
          </p>
          <h2 style={{ marginBottom: 0 }}>{title}</h2>
          <p>{author}</p>
          <div
            style={{
              maxHeight: 300,
              overflowY: 'auto',
              marginBottom: 15,
              paddingRight: 10,
            }}
          >
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <Button type="primary" block onClick={handleRequestBook}>
            {buttonText}
          </Button>
        </Col>
        <Col
          xs={24}
          xl={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className="wrapper">
            <div className="book">
              <div className="inner-book">
                <div className="img">
                  <img src={image?.src} />
                </div>
                <div className="page"></div>
                <div className="page page-2"></div>
                <div className="page page-3"></div>
                <div className="page page-4"></div>
                <div className="page page-5"></div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title="Request Book of The Quarter"
        visible={modalVisibility}
        centered
        cancelText="Cancel"
        okText={'Send Request'}
        okButtonProps={{ disabled: loading }}
        onOk={() => form.submit()}
        onCancel={() => setModalVisibility(false)}
        maskClosable={false}
      >
        <p>
          Requesting our Book of The Quarter will let your financial planner
          know that you're interested, and they will send a copy to this
          address.
        </p>
        <Form form={form} name="address" onFinish={handleFinish}>
          {audioBookUrl ? (
            <Form.Item
              name="isAudioBook"
              labelWrap
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              valuePropName="checked"
            >
              <Checkbox>Request Audio Book?</Checkbox>
            </Form.Item>
          ) : null}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues['isAudioBook'] !== currentValues['isAudioBook']
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue('isAudioBook')) {
                return (
                  <div style={{ fontStyle: 'italic' }}>
                    Your audio book will be sent to {currentUser?.email}
                  </div>
                )
              } else {
                return (
                  <>
                    <Form.Item
                      label="Address Line 1"
                      name="addressLine1"
                      labelWrap
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter an address.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Address Line 2"
                      name="addressLine2"
                      labelWrap
                      labelCol={{ span: 24 }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="City"
                      name="city"
                      labelWrap
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a city.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="State"
                      name="state"
                      labelWrap
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a state',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Zip Code"
                      name="zipCode"
                      labelWrap
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a zipCode.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                )
              }
            }}
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  )
}

function mapStateToProps({ quarters, session: { user } }) {
  const { activeQuarterContent } = quarters
  return {
    currentUser: user,
    title: activeQuarterContent?.bookOfTheQuarter?.title,
    author: activeQuarterContent?.bookOfTheQuarter?.author,
    image: activeQuarterContent?.bookOfTheQuarter?.image,
    url: activeQuarterContent?.bookOfTheQuarter?.url,
    audioBookUrl: activeQuarterContent?.bookOfTheQuarter?.audioBookUrl,
    buttonText: activeQuarterContent?.bookOfTheQuarter?.buttonText,
    description: activeQuarterContent?.bookOfTheQuarter?.description,
  }
}

export default connect(mapStateToProps)(BookOfTheQuarter)
