import React from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function TeamLink({ team, disable = false }) {
  if (team) {
    if (disable) {
      return team.name
    } else {
      return (
        <Link to={`/admin/firms/${team.agencyUid}/teams/${team.uid}`}>
          {team.name}
        </Link>
      )
    }
  } else {
    return null
  }
}

function mapStateToProps({ teams = {} }, { uid }) {
  if (!uid) {
    return {}
  } else {
    const team = teams.data?.find?.((team) => {
      return team.uid === uid
    })
    return {
      team,
    }
  }
}

export default connect(mapStateToProps)(React.memo(TeamLink))
