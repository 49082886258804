import { openLink } from '../../../../helpers/linkingHelpers'

function returnMeetingType(kind) {
  switch (kind) {
    case 'solo':
      return `, One-on-One`
    default:
      return ''
  }
}

function CalendlyEvent({ data, onPress }) {
  function handlePress() {
    onPress(data.uri)
  }
  return (
    <div
      className="calendly-event-container"
      onClick={handlePress}
      style={{
        userSelect: data.active ? 'auto' : 'none',
        pointerEvents: data.active ? 'auto' : 'none',
        opacity: data.active ? 1 : 0.5,
      }}
    >
      <div
        className="calendly-event-border-top"
        style={{
          backgroundColor: data.color,
        }}
      />
      <div style={{ padding: 16 }}>
        <div
          style={{
            height: 30,
            width: '100%',
            alignItems: 'flex-start',
            display: 'flex',
          }}
        >
          <input
            className="calendly-checkbox"
            checked={data.selected}
            type="checkbox"
          />
        </div>
        <div className="calendly-event-title">
          {data.name}
          {data.active ? '' : ' (Inactive)'}
        </div>
        <p className="calendly-event-subtitle">
          {data.duration} minutes{returnMeetingType(data.kind)}
        </p>
        <p
          style={{
            userSelect: data.active ? 'auto' : 'none',
            pointerEvents: data.active ? 'auto' : 'none',
            opacity: data.active ? 1 : 0.5,
          }}
          className="calendly-event-link"
          onClick={() => openLink(data.scheduling_url)}
        >
          View booking page
        </p>
      </div>
    </div>
  )
}

export default CalendlyEvent
