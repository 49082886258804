import React from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import AgencyLink from '../AgencyLink'

function UserLink({ user, showAgency = false, to, comma = false }) {
  if (user) {
    if (to) {
      const willShowAgency = showAgency && user.agencyUid
      return (
        <a
          href={to || `/admin/users/${user.uid}`}
          target="_blank"
          rel="noreferrer"
        >
          {willShowAgency ? <AgencyLink uid={user.agencyUid} /> : ''}
          {(willShowAgency ? ': ' : '') +
            user.displayName +
            (comma ? ', ' : '')}
          <DownloadOutlined style={{ paddingLeft: 5 }} />
        </a>
      )
    } else {
      return (
        <Link to={`/admin/users/${user.uid}`}>
          {user.displayName + (comma ? ', ' : '')}
        </Link>
      )
    }
  } else {
    return null
  }
}

function mapStateToProps({ users = {} }, { uid, email }) {
  if (!uid && !email) {
    return {}
  } else {
    let user = null
    if (uid) {
      user = users.data?.find?.((user) => {
        return user.uid === uid
      })
    } else if (email) {
      user = users.data?.find?.((user) => {
        return user.email === email
      })
    }
    return {
      user,
    }
  }
}

export default connect(mapStateToProps)(React.memo(UserLink))
