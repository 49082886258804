export function returnMessageForFirebaseStorageError(e) {
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  switch (e?.code) {
    case 'storage/unauthorized':
      return "User doesn't have permission to access the object"
    case 'storage/canceled':
      return 'User canceled the upload'
    case 'storage/unknown':
      return 'Unknown error occurred, inspect error.serverResponse'
    default:
      return 'Unknown error uploading file'
  }
}

export function returnMessageForFirebaseError(e) {
  switch (e?.code) {
    case 'auth/email-already-exists':
    case 'auth/email-already-in-use':
      return 'User already exists.'
    case 'auth/wrong-password':
      return 'Wrong password.'
    case 'auth/user-not-found':
      return 'User does not exist.'
    case 'auth/too-many-requests':
      return 'Too many sign in attempts. Please try again later.'
    default:
      console.warn(e)
      return 'Unknown error'
  }
}
