import React from 'react'

import { Button, Col, Grid, message, Row } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { logout } from '../../../features/session/redux/actions'
import FirebaseManager from '../../../FirebaseManager'
import { openLink } from '../../helpers/linkingHelpers'
import usePrimaryColor from '../../hooks/usePrimaryColor'

const { useBreakpoint } = Grid

const LOGO_SOURCE = '/logo.png'
const WHITE_LOGO_SOURCE = '/logo-white.png'
const LANDING_PAGE_SOURCE = '/landing-page.png'
const FOREST_BACKGROUND_IMAGE = '/forest.jpeg'

function returnHeaderFontForBP(bp) {
  switch (bp) {
    case 'xs':
      return 45
    case 'sm':
      return 50
    case 'md':
      return 55
    case 'lg':
      return 60
    default:
      return 65
  }
}

function returnSubtitleFontForBP(bp) {
  switch (bp) {
    case 'xs':
      return 18
    default:
      return 24
  }
}

function returnSectionTitleFontForBP(bp) {
  switch (bp) {
    case 'xs':
      return 25
    default:
      return 30
  }
}

const d = new Date()

const month = d.getMonth()

const modules = [
  {
    name: 'PLAN',
    content:
      'Discuss financial planning topics and goals (short, medium and long term): tax strategies, cash flow, net worth, core topics, and goals review.',
    season: 'Winter',
    marginAdjustment: -23,
    isHighlighted: [12, 1, 2].includes(month),
  },
  {
    name: 'GROW',
    content:
      "Review portfolio performance, including account balances and holdings; revisit investment strategy, risk tolerance, and goal timelines. Let's put your money to work.",
    season: 'Spring',
    right: true,
    marginAdjustment: 10,
    isHighlighted: [3, 4, 5].includes(month),
  },
  {
    name: 'PROTECT',
    content:
      'Review employee benefits, current cash flow and retirement savings, and long term needs such as estate planning, beneficiary designation, and long term care.',
    season: 'Summer',
    marginAdjustment: -5,
    isHighlighted: [6, 7, 8].includes(month),
  },
  {
    name: 'REFLECT',
    content:
      'Year end tax planning, tax loss harvesting, RMD, and Tax distribution planning. Begin planning for the next year.',
    season: 'Fall',
    right: true,
    marginAdjustment: -57,
    isHighlighted: [9, 10, 11].includes(month),
  },
]

const areas = [
  {
    name: 'Scheduling',
    content:
      'Most importantly, we want to connect with YOU. There will be the ability to schedule a meeting to discuss any of these topics, or anything else on your mind.',
    image: '/content_6.svg',
  },
  {
    name: 'Introduction',
    content:
      'This will set the stage for any particularly relevant topics on financial planning - it could be changes in interest rates, market volatility, estate planning updates, etc. If you need to know it, you will find it here.',
    image: '/content_1.svg',
  },
  {
    name: 'Educational Video',
    content:
      "Reviewing key concepts related to the current quarter's conversations, the “Learn Me” section will reinforce lessons and key takeaways in an entertaining and memorable video.",
    image: '/content_2.svg',
  },
  {
    name: 'In The News',
    content:
      'Included will be relevant articles on timely changes in the financial planning landscape as well as the quarterly financial planning topic, titled "Catch Me Up".',
    image: '/content_3.svg',
  },
  {
    name: 'Quarterly Book',
    content:
      'Each quarter, there will be a new book selected that we believe will be of value. If you would like to receive a copy of the book, we would be happy to send it to you directly!',
    image: '/content_4.svg',
  },
  {
    name: 'Mindset Training',
    content:
      'Personal. Professional. Financial. All aspects of development are important, and interconnect in ways you may not realize. We will introduce you to key concepts in the realm of mindset training via the section called "Bigger Futures".',
    image: '/content_5.svg',
  },
]

function LandingPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const dispatch = useDispatch()
  const bps = Object.entries(screens).filter((screen) => !!screen[1])
  const bp = bps[bps.length - 1]?.[0]

  React.useEffect(() => {
    if (location?.state?.unsubscribeEmail) {
      FirebaseManager.unsubscribeEmail(location?.state?.unsubscribeEmail)
        .then(() => {
          message.success(
            `${location?.state?.unsubscribeEmail} has been unsubscribed! Please contact your financial planner if you would like to resubscribe in the future.`
          )
        })
        .catch(console.warn)
    }
  }, [])

  React.useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  function renderAreas() {
    return areas.map((area) => {
      return (
        <Col xs={24} md={12} lg={12} xl={8} key={`areas_${area.name}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={area.image}
              style={{ width: 200, objtectFit: 'contain' }}
            />
            <h1
              style={{
                fontFamily: 'Raleway-Bold',
                color: 'white',
                textAlign: 'center',
              }}
            >
              {area.name}
            </h1>
            <p
              style={{
                fontFamily: 'CormorantGaramond-Regular',
                fontSize: 20,
                letterSpacing: 2,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {area.content}
            </p>
          </div>
        </Col>
      )
    })
  }

  function renderModules() {
    return modules.map((module) => {
      return (
        <Col
          key={`modules_${module.name}`}
          className="landing-page-module"
          xs={24}
          lg={11}
          style={{
            marginBottom: 50,
            transform: ['xs', 'sm', 'md'].includes(bp)
              ? undefined
              : module.right
              ? 'translate(0, 120px)'
              : undefined,
            backgroundColor: module.isHighlighted ? '#EAF0F2' : undefined,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 50,
              top: (module.right ? 90 : 130) + 50 + module.marginAdjustment,
              transform: 'rotate(270deg)',
              transformOrigin: 'left',
            }}
          >
            <div
              style={{
                fontSize: 50,
                fontFamily: 'CormorantGaramond-Regular',
                color: module.isHighlighted ? '#fff' : '#EAF0F2',
              }}
            >
              {module.season}
            </div>
          </div>
          <h1
            style={{
              fontFamily: 'Raleway-SemiBold',
              color: '#263739',
              fontSize: 30,
            }}
          >
            {module.name}
          </h1>
          <p
            style={{
              fontFamily: 'CormorantGaramond-Regular',
              color: '#263739',
              fontSize: 19,
            }}
          >
            {module.content}
          </p>
        </Col>
      )
    })
  }

  const [scrolled, setScrolled] = React.useState(false)
  function handleScroll() {
    if (window.scrollY > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  function login() {
    navigate('/login')
  }

  function signup() {
    navigate('/signup')
  }

  function contactUs() {
    openLink('mailto:info@financialplanningexperience.com')
  }

  const primaryColor = usePrimaryColor()

  return (
    <div className="landing-page">
      <Header
        className="landing-page-header"
        style={{
          padding: '25px 50px',
          height: 85,
          position: 'fixed',
          width: '100%',
          boxShadow: scrolled ? '0 3px 5px rgba(57, 63, 72, 0.3)' : undefined,
          backgroundColor: 'white',
          zIndex: 900,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: ['xs', 'sm', 'md'].includes(bp)
              ? 'center'
              : 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img
              src={LOGO_SOURCE}
              style={{ width: 250, objectFit: 'contain' }}
            />
          </a>
          {['xs', 'sm', 'md'].includes(bp) ? null : (
            <div>
              <Button
                shape="round"
                size={'large'}
                style={{ fontFamily: 'Raleway-Bold', color: '#263739' }}
                onClick={login}
              >
                Advisor Log In
              </Button>
              <Button
                style={{
                  marginLeft: 15,
                  background: primaryColor,
                  fontFamily: 'Raleway-Bold',
                }}
                type="primary"
                shape="round"
                size={'large'}
                onClick={signup}
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </Header>
      <img
        // id="landing-page-gradient"
        src={LANDING_PAGE_SOURCE}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100vh',
          objectFit: 'cover',
          minHeight: 600,
        }}
      />
      <Row
        style={{
          minHeight: 600,
          height: '100vh',
          padding: 55,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col
          xs={24}
          md={18}
          lg={16}
          xl={14}
          xxl={9}
          style={{ width: '100%', maxWidth: 600 }}
        >
          <h1
            style={{
              marginBottom: 5,
              fontSize: returnHeaderFontForBP(bp),
              fontFamily: 'Raleway-Bold',
              color: '#263739',
            }}
          >
            Modern Financial Planning Platform
          </h1>
          <p
            style={{
              fontSize: returnSubtitleFontForBP(bp),
              fontFamily: 'CormorantGaramond-Medium',
              color: '#263739',
            }}
          >
            Get carefully curated financial planning content and experiences
            delivered to you. Book conversations and meetings with your
            financial planner. Pursue your financial potential.
          </p>
          <Button
            shape="round"
            type="primary"
            style={{
              color: 'white',
              marginRight: 15,
              fontFamily: 'Raleway-Bold',
              backgroundColor: primaryColor,
            }}
            onClick={signup}
            size="large"
          >
            Sign Up
          </Button>
          {['xs', 'sm', 'md'].includes(bp) ? (
            <Button
              shape="round"
              size={'large'}
              style={{
                fontFamily: 'Raleway-Bold',
                marginRight: 15,
                color: '#263739',
              }}
              onClick={login}
            >
              Log In
            </Button>
          ) : null}
          <Button
            type="link"
            style={{
              padding: 0,
              fontFamily: 'Raleway-Bold',
              color: '#263739',
            }}
            onClick={contactUs}
            size="large"
          >
            Interested in registering your firm? Contact us ›
          </Button>
        </Col>
        {/* {scrolled ? null : (
          <div className="downArrow bounce">
            <img
              width="40"
              height="40"
              style={{ marginBottom: 75 }}
              alt=""
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4="
            />
          </div>
        )} */}
      </Row>
      <Row>
        <div
          style={{
            padding: '0 50px',
          }}
        >
          <div
            style={{
              fontSize: returnSectionTitleFontForBP(bp),
              fontFamily: 'CormorantGaramond-Regular',
              color: '#263739',
              textAlign: 'center',
              marginBottom: 80,
            }}
          >
            We deliver content and experiences throughout the year with
            educational, relevant, interactive, collaborative, and actionable
            content that is always in the pursuit of your full potential.
          </div>
          <Row justify="space-between">{renderModules()}</Row>
        </div>
      </Row>
      <div
        style={{
          marginTop: ['xs', 'sm', 'md'].includes(bp) ? undefined : 150,
          padding: 50,
          backgroundColor: '#1F3533',
        }}
      >
        <div
          style={{
            fontSize: returnSectionTitleFontForBP(bp),
            fontFamily: 'CormorantGaramond-Regular',
            color: '#fff',
            textAlign: 'center',
            marginTop: 40,
            marginBottom: 80,
          }}
        >
          In each of these educational pieces, there will be a few reoccurring
          sections and areas of focus:
        </div>
        <Row justify="space-evenly" gutter={[50, 50]}>
          {renderAreas()}
        </Row>
      </div>
      <Row
      // style={{
      //   height: 250,
      // }}
      >
        <Col
          xs={24}
          md={12}
          style={{
            backgroundColor: '#fff',
            padding: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h3
            style={{
              fontFamily: 'Raleway-Bold',
              fontSize: 30,
              color: '#263739',
              margin: 0,
              textAlign: 'center',
            }}
          >
            Interested in the Financial Planning Experience for your firm?
          </h3>
        </Col>
        <Col
          xs={24}
          md={12}
          style={{
            padding: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={FOREST_BACKGROUND_IMAGE}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'cover',
              opacity: 0.3,
            }}
          />
          <Button
            size="large"
            type="primary"
            shape="round"
            block
            onClick={contactUs}
            style={{
              maxWidth: 300,
              background: primaryColor,
              fontFamily: 'Raleway-Bold',
            }}
          >
            Contact Us
          </Button>
        </Col>
      </Row>
      <Row
        align="center"
        gutter={[0, 25]}
        style={{
          width: '100%',
          padding: 50,
          backgroundColor: '#263739',
        }}
      >
        <Col xs={24} md={8} lg={8}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={WHITE_LOGO_SOURCE}
              style={{ width: 200, objectFit: 'contain' }}
            />
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div
            style={{
              color: 'white',
              textAlign: 'center',
              fontFamily: 'CormorantGaramond-MediumItalic',
            }}
          >
            Securities, investment advisory services and financial planning are
            offered through qualified registered representatives of MML
            Investors Services, LLC, Member SIPC{' '}
            <a href="https://www.sipc.org" target="_blank" rel="noreferrer">
              (www.sipc.org)
            </a>
            . Commonwealth Financial Group is not a subsidiary or affiliate of
            MML Investors Services, LLC or its affiliated companies. Supervisory
            office: 280 Congress Street, Suite 1300, Boston, MA 02210 Tel:{' '}
            <a href="tel:6174394389">617-439-4389</a>.
            <br />
            CRN202507-2702274
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LandingPage
