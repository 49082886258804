import React from 'react'

import { connect } from 'react-redux'

import UserCreateForm from '../UserCreateForm'

function EditProfile({ currentUser }) {
  return <UserCreateForm editing user={currentUser} />
}

function mapStateToProps({ session: { user = {} } }) {
  return {
    currentUser: user?.attributes,
  }
}

export default connect(mapStateToProps)(EditProfile)
