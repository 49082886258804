import React from 'react'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Col, Modal, Row } from 'antd'
import { Document, Page } from 'react-pdf'
import { connect } from 'react-redux'

import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import PDFView from '../../../../shared/components/PDFView'

const MAX_WIDTH = 400

function MarketingMaterials({ marketingMaterials = [], showCard = true }) {
  const [detail, setDetail] = React.useState(null)
  const [numPages, setNumPages] = React.useState(null)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [pageDimensions, setPageDimensions] = React.useState({
    width: 0,
    height: 0,
  })

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function onPageLoad({ width, height }) {
    let adjustedWidth = width
    let adjustedHeight = height
    let ratio
    if (width > MAX_WIDTH) {
      ratio = width / height
      adjustedWidth = MAX_WIDTH
      adjustedHeight *= ratio
    }
    setPageDimensions({ width: adjustedWidth, height: adjustedHeight })
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function renderMarketingMaterials() {
    return (marketingMaterials || []).map((material, index) => {
      return (
        <Col
          key={`material_${material.name}_${index}`}
          xs={24}
          sm={14}
          md={14}
          lg={12}
          xl={8}
        >
          <PDFView setDetail={setDetail} data={material} />
        </Col>
      )
    })
  }

  if (!marketingMaterials?.length) {
    return (
      <Card title="Marketing Materials">
        <FPEEmptyState description="No materials yet" />
      </Card>
    )
  }

  return (
    <>
      {showCard ? (
        <Card title="Marketing Materials">
          <Alert
            style={{ marginBottom: 20 }}
            message="How will you tell the story of ongoing financial planning and the Financial Planning Experience to your clients and prospects? Position these experiences using the materials provided here."
          />
          <Row gutter={[16, 16]}>{renderMarketingMaterials()}</Row>
        </Card>
      ) : (
        <>
          <Alert
            style={{ marginBottom: 20 }}
            message="How will you tell the story of ongoing financial planning and the Financial Planning Experience to your clients and prospects? Position these experiences using the materials provided here."
          />
          <Row gutter={[16, 16]}>{renderMarketingMaterials()}</Row>
        </>
      )}
      <Modal
        title={detail?.name}
        visible={!!detail}
        cancelText="Close"
        okText={
          <a href={detail?.url} download target="_blank" rel="noreferrer">
            Download
          </a>
        }
        onCancel={() => setDetail(null)}
      >
        <Document
          file={detail?.url}
          onLoadSuccess={onDocumentLoadSuccess}
          style={{ alignSelf: 'center' }}
        >
          <Page
            onLoadSuccess={onPageLoad}
            pageNumber={pageNumber}
            width={pageDimensions.width}
            height={pageDimensions.height}
          />
        </Document>
        {numPages > 1 ? (
          <Row
            align="center"
            justify="space-between"
            style={{ paddingTop: 24 }}
          >
            <Button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
            />
            <div>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </div>
            <Button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
            />
          </Row>
        ) : null}
      </Modal>
    </>
  )
}

function mapStateToProps({ quarters: { marketingMaterials = [] } }, { data }) {
  return {
    marketingMaterials: data || marketingMaterials,
  }
}

export default connect(mapStateToProps)(MarketingMaterials)
