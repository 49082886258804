import FirebaseManager from '../../../FirebaseManager'
import {
  createAgencyBegin,
  createAgencySuccess,
  createAgencyError,
  editAgencyBegin,
  editAgencySuccess,
  editAgencyError,
} from './actions'

export const createAgency = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(createAgencyBegin())
    FirebaseManager.createAgency(payload)
      .then((response) => {
        dispatch(createAgencySuccess(response))
        resolve(response)
      })
      .catch((error) => {
        dispatch(createAgencyError())
        reject(error)
      })
  })
}

export const editAgency = (uid, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(editAgencyBegin())
    FirebaseManager.editAgency(uid, payload)
      .then((response) => {
        dispatch(editAgencySuccess(response))
        resolve(response)
      })
      .catch((error) => {
        dispatch(editAgencyError())
        reject(error)
      })
  })
}
