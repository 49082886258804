import React from 'react'

import { Button, Card, Col, message, Row, Space } from 'antd'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ADMIN_USER_TYPE } from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'

function AdminTools({ currentUser }) {
  const navigate = useNavigate()
  React.useEffect(() => {
    if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
      navigate('/admin')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function sendTestInviteEmail() {
    FirebaseManager.sendTestInviteEmail({
      email: 'drewjamesandre@gmail.com',
      uid: 'D0duMFpJbOSGelJKqnjS0SFWOO22',
    })
      .then(() => {
        message.success('Test email sent!')
      })
      .catch((error) => {
        console.warn(error)
        message.error('Unable to send test email.')
      })
  }

  function syncAllMailchimpUsers() {
    navigate('/admin/tools/mailchimp-sync')
  }

  function removeUsers() {
    navigate('/admin/tools/remove-users')
  }

  if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
    return null
  }

  return (
    <Card title="Admin Tools">
      <Row>
        <Col>
          <Space direction="vertical">
            {/* <Button onClick={removeUsers}>Bulk Remove Users</Button> */}
            <Button onClick={syncAllMailchimpUsers}>Sync Mailchimp</Button>
            <Button onClick={sendTestInviteEmail}>
              Send Test Invite Email
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

function mapStateToProps({ session: { user = {} } }) {
  return {
    currentUser: user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTools)
