import axiosInstance from '../../../bootstrap/axios'
import ContentfulManager from '../../../ContentfulManager'
import FirebaseManager from '../../../FirebaseManager'
import { setAgenciesSuccess } from '../../agencies/redux/actions'
import {
  setQuartersSuccess,
  setMarketingMaterialsSuccess,
} from '../../quarters/redux/actions'
import { setQuarters } from '../../quarters/redux/operations'
import {
  setAccountsSuccess,
  setUser,
  fetchAppDataBegin,
  fetchAppDataSuccess,
} from '../../session/redux/actions'
import { setTeamsSuccess } from '../../teams/redux/actions'
import { setUsersSuccess } from '../../users/redux/actions'

export const fetchAllAppData = () => async (dispatch, getState) => {
  const {
    session: { user: currentUser },
  } = getState()
  dispatch(fetchAppDataBegin())
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${currentUser?.accessToken}`
  const promises = [
    FirebaseManager.getCurrentUser(),
    FirebaseManager.getUsers(),
    FirebaseManager.getAgencies(),
    setQuarters(),
    ContentfulManager.getMarketingMaterials(),
    FirebaseManager.getAllTeams(),
    FirebaseManager.getAllAccounts(),
  ]
  const results = await Promise.all(promises)
  const [user, users, agencies, quarters, marketingMaterials, teams, accounts] =
    results
  const userWithAccessToken = {
    ...user,
    accessToken: currentUser?.accessToken,
  }
  dispatch(setUser(userWithAccessToken))
  dispatch(setUsersSuccess(users))
  dispatch(setAgenciesSuccess(agencies))
  dispatch(setQuartersSuccess(quarters))
  dispatch(setMarketingMaterialsSuccess(marketingMaterials))
  dispatch(setTeamsSuccess(teams))
  dispatch(setAccountsSuccess(accounts))
  dispatch(fetchAppDataSuccess())
  return results
}
