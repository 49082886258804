import FirebaseManager from '../../../FirebaseManager'
import { addTeamToAgency } from '../../agencies/redux/actions'
import {
  addTeamBegin,
  addTeamSuccess,
  addTeamError,
  addAdvisorToTeamBegin,
  addAdvisorToTeamSuccess,
  addAdvisorToTeamError,
  getAllTeamsBegin,
  getAllTeamsSuccess,
  getAllTeamsError,
  editTeamBegin,
  editTeamSuccess,
  editTeamError,
} from './actions'

export const getAllTeams = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(getAllTeamsBegin())
    FirebaseManager.getAllTeams()
      .then((teams) => {
        dispatch(getAllTeamsSuccess(teams))
        resolve(teams)
      })
      .catch((error) => {
        dispatch(getAllTeamsError())
        reject(error)
      })
  })
}

export const addTeam = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(addTeamBegin())
    FirebaseManager.addTeamToAgency(payload)
      .then((team) => {
        dispatch(addTeamToAgency(team))
        dispatch(addTeamSuccess(team))
        resolve(team)
      })
      .catch((error) => {
        dispatch(addTeamError())
        reject(error)
      })
  })
}

export const editTeam = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(editTeamBegin())
    FirebaseManager.editTeam(payload)
      .then((team) => {
        dispatch(editTeamSuccess(team))
        resolve(team)
      })
      .catch((error) => {
        dispatch(editTeamError())
        reject(error)
      })
  })
}

export const addAdvisorToTeam =
  (teamId, agencyUid, advisorUid) => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(addAdvisorToTeamBegin())
      FirebaseManager.addAdvisorToTeam(teamId, agencyUid, advisorUid)
        .then((team) => {
          dispatch(addAdvisorToTeamSuccess(team))
          resolve(team)
        })
        .catch((error) => {
          dispatch(addAdvisorToTeamError())
          reject(error)
        })
    })
  }
