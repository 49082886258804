import {
  SET_TEAMS_SUCCESS,
  ADD_TEAM_BEGIN,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  ADD_ADVISOR_TO_TEAM_BEGIN,
  ADD_ADVISOR_TO_TEAM_SUCCESS,
  ADD_ADVISOR_TO_TEAM_ERROR,
  GET_ALL_TEAMS_BEGIN,
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_ERROR,
  EDIT_TEAM_BEGIN,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_ERROR,
} from './types'

export function setTeamsSuccess(teams = []) {
  return {
    type: SET_TEAMS_SUCCESS,
    payload: teams,
  }
}

export function addTeamBegin() {
  return {
    type: ADD_TEAM_BEGIN,
  }
}

export function addTeamSuccess(team) {
  return {
    type: ADD_TEAM_SUCCESS,
    payload: team,
  }
}

export function addTeamError() {
  return {
    type: ADD_TEAM_ERROR,
  }
}

export function editTeamBegin() {
  return {
    type: EDIT_TEAM_BEGIN,
  }
}

export function editTeamSuccess(team) {
  return {
    type: EDIT_TEAM_SUCCESS,
    payload: team,
  }
}

export function editTeamError() {
  return {
    type: EDIT_TEAM_ERROR,
  }
}

export const getAllTeamsBegin = () => {
  return {
    type: GET_ALL_TEAMS_BEGIN,
  }
}

export const getAllTeamsSuccess = (teams = []) => {
  return {
    type: GET_ALL_TEAMS_SUCCESS,
    payload: teams,
  }
}

export const getAllTeamsError = () => {
  return {
    type: GET_ALL_TEAMS_ERROR,
  }
}

export const addAdvisorToTeamBegin = () => {
  return {
    type: ADD_ADVISOR_TO_TEAM_BEGIN,
  }
}

export const addAdvisorToTeamSuccess = (team = []) => {
  return {
    type: ADD_ADVISOR_TO_TEAM_SUCCESS,
    payload: team,
  }
}

export const addAdvisorToTeamError = () => {
  return {
    type: ADD_ADVISOR_TO_TEAM_ERROR,
  }
}
