import React from 'react'

import { Button, Card } from 'antd'
import { connect } from 'react-redux'

import { setCalendlyVisibility } from '../../../features/calendly/redux/actions'
import { openLink } from '../../helpers/linkingHelpers'

function ModuleCard({
  cardTitle,
  currentUser,
  setCalendlyVisibility,
  primarySchedulingUrl,
  advisor,
  data = {},
}) {
  const { title, image, description, buttonText } = data

  function openCalendly() {
    if (data) {
      if (primarySchedulingUrl) {
        setCalendlyVisibility({
          isOpen: true,
          firstName: currentUser?.attributes?.firstName,
          lastName: currentUser?.attributes?.lastName,
          email: currentUser?.email,
          notes: `I'd like to discuss "${title}"`,
        })
      } else {
        openLink(
          `mailto:${advisor?.email}?subject=FPE Meeting Request&body=Hi ${advisor?.firstName}. I'd like to discuss ${data.title}. What time would work best for you?`
        )
      }
    }
  }

  return (
    <Card
      title={cardTitle}
      hoverable
      bodyStyle={{ height: 'calc(100% - 60px)' }}
      style={{ height: '100%' }}
    >
      <div className="card-centered" style={{ height: '100%' }}>
        <img
          src={image.src}
          className="module-card-image"
          style={{ marginBottom: 15 }}
        />
        <h2 style={{ textAlign: 'center' }}>{title}</h2>
        <p style={{ textAlign: 'center' }}>{description}</p>
        <Button type="primary" block onClick={openCalendly}>
          {buttonText}
        </Button>
      </div>
    </Card>
  )
}

function mapStateToProps({ users, session: { user } }) {
  const advisorUid = user.attributes.advisorUid
  const advisor = (users.data || []).find((user) => {
    return user.uid === advisorUid
  })
  const primarySchedulingUrl = advisor?.primarySchedulingUrl
  return {
    advisor,
    primarySchedulingUrl,
  }
}

const mapDispatchToProps = {
  setCalendlyVisibility,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModuleCard))
