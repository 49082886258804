import React from 'react'

import { connect } from 'react-redux'

import AdvisorContactCard from '../../../../shared/components/AdvisorContactCard'

function Contact({ advisors = [], advisorUid }) {
  return (
    <div>
      {advisors.map((data, index) => {
        if (data) {
          return (
            <AdvisorContactCard
              data={data}
              isClient
              isLastItem={data.length === 1 || index === data.length - 1}
              isPrimaryAdvisor={advisorUid === data.uid}
            />
          )
        } else {
          console.warn('Null financial planner rendered!', data)
          return null
        }
      })}
    </div>
  )
}

function mapStateToProps({ session: { user }, users: { data: usersData } }) {
  const advisorUid = user?.attributes?.advisorUid
  const advisor = usersData.find((u) => {
    return u.uid === advisorUid
  })
  const teamUid = advisor?.teamUid
  const allAdvisors = teamUid
    ? usersData.filter((user) => {
        return user.teamUid && user.teamUid === teamUid
      })
    : [advisor]
  const sortedAdvisors = (allAdvisors || []).sort(function (x) {
    return x?.uid === advisorUid ? 0 : x ? 1 : -1
  })
  return {
    advisors: sortedAdvisors,
    advisorUid,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
