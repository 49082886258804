import React from 'react'

import { ConfigProvider } from 'antd'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import axiosInstance from './bootstrap/axios'
import { logout } from './features/session/redux/actions'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from './features/session/redux/types'
import FirebaseManager from './FirebaseManager'
import AdminDashboard from './routes/AdminDashboard'
import Dashboard from './routes/Dashboard'
import Login from './routes/Login'
import NotFound from './routes/NotFound'
import PasswordlessLogin from './routes/PasswordlessLogin'
import PasswordReset from './routes/PasswordReset'
import RequestPasswordReset from './routes/RequestPasswordReset'
import SignUp from './routes/SignUp'
import LandingPage from './shared/components/LandingPage'
import UnsubscribeHOC from './shared/components/UnsubscribeHOC'
import RequireAuth from './shared/HOC/RequireAuth'
import usePrimaryColor from './shared/hooks/usePrimaryColor'

global.DEFAULT_PHOTO_URL =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'

ConfigProvider.config({
  theme: {
    primaryColor: '#61764E',
  },
})

ReactGA.initialize('391070630', {
  testMode:
    process.env.NODE_ENV === 'development' ||
    window.location.host.includes('fpe-dev'),
})

function App({ currentUser, rememberMe, logout }) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${currentUser?.accessToken}`

  const primaryColor = usePrimaryColor()

  React.useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor,
      },
    })
  }, [primaryColor])

  if (!FirebaseManager.user) {
    FirebaseManager.setUser(currentUser)
  }

  React.useEffect(() => {
    if (!rememberMe) {
      console.log('Logging out')
      logout()
    }
  }, [rememberMe, logout])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth" element={<PasswordlessLogin />} />
        <Route path="/unsubscribe" element={<UnsubscribeHOC />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/request-password-reset"
          element={<RequestPasswordReset />}
        />
        <Route
          path="/*"
          element={
            currentUser ? (
              <RequireAuth
                redirectTo="/admin"
                disallowedRoles={[
                  ADVISOR_USER_TYPE,
                  AGENCY_ADMIN_USER_TYPE,
                  ADMIN_USER_TYPE,
                ]}
              >
                <Dashboard />
              </RequireAuth>
            ) : (
              <LandingPage />
            )
          }
        />
        <Route
          path="/admin/content/:uid/preview"
          element={<Dashboard preview />}
        />
        <Route
          path="/admin/*"
          element={
            <RequireAuth disallowedRoles={[CLIENT_USER_TYPE]}>
              <AdminDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

function mapStateToProps({ session: { rememberMe, user } }) {
  return {
    rememberMe,
    currentUser: user,
  }
}

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
