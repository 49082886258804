import React from 'react'

import {
  Button,
  Card,
  ConfigProvider,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
} from 'antd'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import AgencyLink from '../../../../shared/components/Links/AgencyLink'
import TeamLink from '../../../../shared/components/Links/TeamLink'
import UserLink from '../../../../shared/components/Links/UserLink'
import { openLink } from '../../../../shared/helpers/linkingHelpers'
import {
  returnUserTagColor,
  returnUserTagName,
} from '../../../../shared/helpers/returnUserTagColor'
import { defaultSorter } from '../../../../shared/helpers/tableHelpers'

const NULL_CHAR = '—'
const NA_CHAR = 'n/a'
const ADVISOR_USER_TYPES = [ADVISOR_USER_TYPE, AGENCY_ADMIN_USER_TYPE]
const USE_DUMMY_DATA = false

function defaultRenderer(text, record) {
  return ADVISOR_USER_TYPES.includes(record.type) ? text || NULL_CHAR : NA_CHAR
}

function defaultMatchValue(text, value) {
  return {
    props: {
      style: {
        backgroundColor: text === value ? undefined : 'rgba(207,19,34,0.6)',
      },
    },
    children: text,
  }
}

function defaultCompareToRender(
  text,
  record,
  compareTo,
  required = true,
  renderChildren
) {
  return {
    props: {
      style: {
        backgroundColor: compareTo
          ? text
            ? text === get(record, compareTo)
              ? undefined
              : 'rgba(207,19,34,0.6)'
            : required
            ? 'rgba(207,19,34,0.6)'
            : undefined
          : undefined,
      },
    },
    children: renderChildren || text || NULL_CHAR,
  }
}

function defaultImageCompareToRender(
  image,
  record,
  compareTo,
  required = true
) {
  return {
    props: {
      style: {
        backgroundColor: compareTo
          ? image
            ? image !== get(record, compareTo)
              ? 'rgba(207,19,34,0.6)'
              : undefined
            : get(record, compareTo)
            ? 'rgba(207,19,34,0.6)'
            : 'rgba(255,122,69,0.35)'
          : undefined,
      },
    },
    children: image ? (
      <img
        src={image}
        style={{ width: 75, height: 75, objectFit: 'contain' }}
      />
    ) : required ? (
      NULL_CHAR
    ) : (
      NA_CHAR
    ),
  }
}

const MERGE_FIELDS = [
  {
    tag: 'DNAME',
    name: 'Display Name',
    render: (text, record) => {
      return defaultCompareToRender(text, record, 'displayName')
    },
  },
  {
    tag: 'TMPHASH',
    name: 'Tmp Hash',
    render: (text, record) => {
      return defaultCompareToRender(text, record, 'tempPassword')
    },
  },
  // {
  //   tag: 'LOCALENV',
  //   name: 'Local Environment',
  //   render: (text) =>
  //     defaultMatchValue(
  //       text,
  //       process.env.NODE_ENV === 'development' ? 'true' : 'false'
  //     ),
  // },
  {
    tag: 'ENV',
    name: 'Environment',
    render: (text) =>
      defaultMatchValue(
        text,
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : 'prod'
      ),
  },
  {
    tag: 'TYPE',
    name: 'User Type',
    render: (text, record) => {
      return defaultCompareToRender(text, record, 'type')
    },
  },
  {
    tag: 'ONBOARDED',
    name: 'Onboarded',
    ender: (text, record) => {
      return defaultCompareToRender(text, record, 'onboarded')
    },
  },
  {
    tag: 'ADOPTOUT',
    name: 'Opt Out',
    render: defaultRenderer,
  },
  {
    tag: 'RETARGET',
    name: 'Retarget Users',
    render: defaultRenderer,
  },
  {
    tag: 'ADPHOTO',
    name: 'Advisor Photo',
    render: (image, record) => {
      return defaultImageCompareToRender(
        image,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? 'photoURL'
          : 'advisor.photoURL',
        !ADVISOR_USER_TYPES.includes(record.type)
      )
    },
  },
  {
    tag: 'ADNAME',
    name: 'Advisor Name',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? undefined
          : 'advisor.displayName',
        !ADVISOR_USER_TYPES.includes(record.type),
        record.advisorUid ? <UserLink uid={record.advisorUid} /> : text
      )
    },
  },
  {
    tag: 'ADEMAIL',
    name: 'Advisor Email',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type) ? 'email' : 'advisor.email',
        !ADVISOR_USER_TYPES.includes(record.type)
      )
    },
  },
  {
    tag: 'ADADRES',
    name: 'Advisor Address',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? 'address'
          : 'advisor.address',
        !ADVISOR_USER_TYPES.includes(record.type)
      )
    },
  },
  {
    tag: 'ADCELL',
    name: 'Advisor Cell #',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? 'cellPhoneNumber'
          : 'advisor.cellPhoneNumber',
        false
      )
    },
  },
  {
    tag: 'ADOFFICE',
    name: 'Advisor Office #',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? 'officePhoneNumber'
          : 'advisor.officePhoneNumber',
        !ADVISOR_USER_TYPES.includes(record.type)
      )
    },
  },
  {
    tag: 'ADSITE',
    name: 'Advisor Website',
    render: (text, record) => {
      return defaultCompareToRender(
        text,
        record,
        ADVISOR_USER_TYPES.includes(record.type)
          ? 'website'
          : 'advisor.website',
        false
      )
    },
  },
  {
    tag: 'TNAME',
    name: 'Team Name',
    render: (text, record) => {
      if (record.teamUid) {
        return defaultCompareToRender(text, record, 'team.name')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'TLOGO',
    name: 'Team Logo',
    render: (image, record) => {
      if (record.teamUid) {
        return defaultImageCompareToRender(image, record, 'team.logoUrl')
      } else {
        return defaultRenderer(image, record)
      }
    },
  },
  {
    tag: 'TCOLOR',
    name: 'Team Color',
    render: (text, record) => {
      if (record.teamUid) {
        return defaultCompareToRender(text, record, 'team.teamColor')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'AGNAME',
    name: 'Agency Name',
    render: (text, record) => {
      if (record.agencyUid) {
        return defaultCompareToRender(text, record, 'agency.name')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'AGADRES',
    name: 'Agency Address',
    render: (text, record) => {
      if (record.agencyUid) {
        return defaultCompareToRender(text, record, 'agency.address')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'AGCOLOR',
    name: 'Agency Color',
    render: (text, record) => {
      if (record.agencyUid) {
        return defaultCompareToRender(text, record, 'agency.firmColor')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'AGLOGO',
    name: 'Agency Logo',
    render: (image, record) => {
      if (record.agencyUid) {
        return defaultImageCompareToRender(image, record, 'agency.logoUrl')
      } else {
        return defaultRenderer(image, record)
      }
    },
  },
  {
    tag: 'AGPHONE',
    name: 'Agency Phone #',
    render: (text, record) => {
      if (record.agencyUid) {
        return defaultCompareToRender(text, record, 'agency.officePhoneNumber')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
  {
    tag: 'AGSITE',
    name: 'Agency Website',
    render: (text, record) => {
      if (record.agencyUid) {
        return defaultCompareToRender(text, record, 'agency.website')
      } else {
        return defaultRenderer(text, record)
      }
    },
  },
]

function UserStatus({ currentUserType, agencies = [], teams = [] }) {
  const navigate = useNavigate()
  const [data, setData] = React.useState(
    USE_DUMMY_DATA ? require('./sampleProdData.json') : []
  )
  const [loading, setLoading] = React.useState(false)

  if (currentUserType !== ADMIN_USER_TYPE) {
    navigate('/admin')
  }

  function handleRefresh() {
    if (currentUserType === ADMIN_USER_TYPE) {
      setLoading(true)
      FirebaseManager.getUserStatus()
        .then((response) => {
          setData(response)
          setLoading(false)
        })
        .catch((error) => {
          console.warn(error)
          message.error('Unable to get user status')
          setLoading(false)
        })
    }
  }

  React.useEffect(() => {
    if (!USE_DUMMY_DATA) {
      handleRefresh()
    }
  }, [])

  function returnTagColor(name = '') {
    if (name.toLowerCase().includes('agency')) {
      return 'red'
    } else if (name.toLowerCase().includes('team')) {
      return 'green'
    } else if (name.toLowerCase().includes('advisor')) {
      return 'blue'
    } else {
      return undefined
    }
  }

  const tempMap = {}
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: 150,
      uid: 'firstName',
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text || NULL_CHAR}</Link>
      },
      filterSearch: true,
      filters: data
        .map((user) => {
          const name = (user?.firstName || '').trim()
          if (name) {
            if (tempMap[name]) {
              return null
            } else {
              tempMap[name] = name
              return {
                text: name,
                value: name,
              }
            }
          } else {
            return null
          }
        })
        .filter((x) => x)
        .sort((a, b) => defaultSorter(a, b, 'value')),
      onFilter: (value, record) => record.firstName?.includes?.(value),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: 150,
      uid: 'lastName',
      render: (text, user) => {
        return <Link to={`/admin/users/${user.uid}`}>{text || NULL_CHAR}</Link>
      },
      filterSearch: true,
      filters: data
        .map((user) => {
          const name = (user?.lastName || '').trim()
          if (name) {
            if (tempMap[name]) {
              return null
            } else {
              tempMap[name] = name
              return {
                text: name,
                value: name,
              }
            }
          } else {
            return null
          }
        })
        .filter((x) => x)
        .sort((a, b) => defaultSorter(a, b, 'value')),
      onFilter: (value, record) => record.lastName?.includes?.(value),
    },
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      width: 150,
      uid: 'displayName',
      render: (text) => text || NULL_CHAR,
      filterSearch: true,
      filters: data
        .map((user) => {
          const name = (user?.displayName || '').trim()
          if (name) {
            if (tempMap[name]) {
              return null
            } else {
              tempMap[name] = name
              return {
                text: name,
                value: name,
              }
            }
          } else {
            return null
          }
        })
        .filter((x) => x)
        .sort((a, b) => defaultSorter(a, b, 'value')),
      onFilter: (value, record) => record.displayName?.includes?.(value),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      uid: 'email',
      render: (text /* user */) => {
        return (
          <Popconfirm
            title="Open email client?"
            onConfirm={() => {
              return openLink(`mailto:${text}`)
            }}
            okText="Open"
          >
            <a href="#">{text}</a>
          </Popconfirm>
        )
      },
      filterSearch: true,
      filters: data
        .map((user) => {
          const email = (user?.email || '').trim()
          if (email) {
            if (tempMap[email]) {
              return null
            } else {
              tempMap[email] = email
              return {
                text: email,
                value: email,
              }
            }
          } else {
            return null
          }
        })
        .filter((x) => x)
        .sort((a, b) => defaultSorter(a, b, 'value')),
      onFilter: (value, record) => record.email?.includes?.(value),
    },
    {
      title: 'Profile Photo',
      dataIndex: 'photoURL',
      width: 150,
      uid: 'photoURL',
      render: (image, record) => {
        if (ADVISOR_USER_TYPES.includes(record.type)) {
          return {
            props: {
              style: {
                backgroundColor: image ? undefined : 'rgba(207,19,34,0.6)',
              },
            },
            children: image ? (
              <img src={image} style={{ width: 75, height: 75 }} />
            ) : (
              NULL_CHAR
            ),
          }
        } else {
          return {
            children: image || NA_CHAR,
          }
        }
      },
    },
    {
      title: 'Firm',
      dataIndex: 'agencyUid',
      width: 150,
      uid: 'agencyUid',
      render: (agency, user) => {
        return agency ? <AgencyLink uid={agency} /> : NULL_CHAR
      },
    },
    {
      title: 'Team',
      dataIndex: 'teamUid',
      width: 150,
      uid: 'teamUid',
      render: (teamUid) => {
        if (teamUid) {
          return <TeamLink uid={teamUid} />
        } else {
          return NULL_CHAR
        }
      },
    },
    {
      title: 'Role',
      uid: 'type',
      width: 150,
      dataIndex: 'type',
      defaultFilteredValue: ADVISOR_USER_TYPES,
      filters: [
        {
          text: 'Admin',
          value: ADMIN_USER_TYPE,
        },
        {
          text: 'Firm/Team Admin',
          value: AGENCY_ADMIN_USER_TYPE,
        },
        {
          text: 'Financial Planner',
          value: ADVISOR_USER_TYPE,
        },
        {
          text: 'Member',
          value: CLIENT_USER_TYPE,
        },
      ]
        .filter((x) => x)
        .sort((a, b) => defaultSorter(a, b, 'value')),
      onFilter: (value, record) => {
        return record.type?.indexOf?.(value) === 0
      },
      render: (tag) => {
        const color = returnUserTagColor(tag)
        if (!tag) {
          return NULL_CHAR
        }
        return (
          <Tag color={color} key={tag}>
            {returnUserTagName(tag)}
          </Tag>
        )
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: 150,
      uid: 'address',
      render: defaultRenderer,
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: 150,
      uid: 'website',
      render: defaultRenderer,
    },
    {
      title: 'Cell Phone #',
      dataIndex: 'cellPhoneNumber',
      width: 150,
      uid: 'cellPhoneNumber',
      render: defaultRenderer,
    },
    {
      title: 'Office Phone #',
      dataIndex: 'officePhoneNumber',
      width: 150,
      uid: 'officePhoneNumber',
      render: defaultRenderer,
    },
    ...MERGE_FIELDS.map((field) => {
      return {
        title: () => {
          return (
            <div>
              <Tag color={returnTagColor(field.name)}>{field.name}</Tag>
              <div>{field.tag}</div>
            </div>
          )
        },
        dataIndex: ['mailchimpMergeFields', field.tag],
        width: 150,
        uid: field.tag,
        ...field,
      }
    }),
  ]

  // function recordIsValid(record = {}) {
  //   const {
  //     photoUrl,
  //     firstName,
  //     lastName,
  //     displayName,
  //     email,
  //     type,
  //     uid,
  //     mailchimpMergeFields,
  //     agencyUid,
  //   } = record
  //   if (type === CLIENT_USER_TYPE) {
  //     if (
  //       !photoUrl ||
  //       !displayName ||
  //       !email ||
  //       !firstName ||
  //       !lastName ||
  //       !type ||
  //       !uid ||
  //       !mailchimpMergeFields
  //     ) {
  //       return false
  //     }
  //   } else if (type === ADVISOR_USER_TYPE || type === AGENCY_ADMIN_USER_TYPE) {
  //     if (
  //       !photoUrl ||
  //       !displayName ||
  //       !email ||
  //       !firstName ||
  //       !lastName ||
  //       !type ||
  //       !uid ||
  //       !agencyUid ||
  //       !mailchimpMergeFields
  //     ) {
  //       return false
  //     }
  //   } else if (!type) {
  //     return false
  //   }
  //   return true
  // }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <ConfigProvider
        renderEmpty={() => <FPEEmptyState description={`No users yet`} />}
      >
        <Table
          size="small"
          // onRow={(record) => {
          //   return {
          //     style: {
          //       backgroundColor: recordIsValid(record)
          //         ? undefined
          //         : 'rgba(207,19,34,0.35)',
          //     },
          //   }
          // }}
          title={() => {
            return (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 style={{ margin: 0, fontSize: 19, fontWeight: 'bold' }}>
                  All Users
                </h1>
                <Space>
                  <Button onClick={handleRefresh} loading={loading}>
                    Refresh
                  </Button>
                </Space>
              </Space>
            )
          }}
          sticky
          rowKey="uid"
          columns={columns}
          dataSource={data || []}
          scroll={{
            x: 600,
            y: '75vh',
          }}
        />
      </ConfigProvider>
    </Card>
  )
}

function mapStateToProps({
  users = {},
  session: { loading, user },
  agencies: { data: agenciesData = [] },
  teams: { data: teamsData = [] },
}) {
  const currentUserType = user?.attributes?.type
  const currentUserUid = user?.uid
  return {
    loading,
    teams: teamsData,
    agencies: agenciesData,
    currentUserType,
    data: (users.data || []).filter((user) => {
      if (user.uid === currentUserUid) {
        return false
      }
      if (currentUserType === ADMIN_USER_TYPE) {
        return user
      }
      if (
        currentUserType === ADVISOR_USER_TYPE ||
        currentUserType === AGENCY_ADMIN_USER_TYPE
      ) {
        return (
          user?.type !== ADMIN_USER_TYPE &&
          user?.type !== AGENCY_ADMIN_USER_TYPE &&
          user?.advisorUid === currentUserUid
        )
      } else {
        return user
      }
    }),
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatus)
