import {
  SET_QUARTERS_BEGIN,
  SET_QUARTERS_SUCCESS,
  SET_QUARTERS_ERROR,
  SET_CONVERSATION_SUCCESS,
  ADD_ADVISOR_VIDEO_SUCCESS,
  SET_MARKETING_MATERIALS_SUCCESS,
  EDIT_QUARTER_BEGIN,
  EDIT_QUARTER_SUCCESS,
  EDIT_QUARTER_ERROR,
} from './types'

export function setMarketingMaterialsSuccess(payload) {
  return {
    type: SET_MARKETING_MATERIALS_SUCCESS,
    payload,
  }
}

export function setQuartersBegin() {
  return {
    type: SET_QUARTERS_BEGIN,
  }
}

export function setQuartersSuccess(quarters = {}) {
  return {
    type: SET_QUARTERS_SUCCESS,
    payload: quarters,
  }
}

export function setQuartersError() {
  return {
    type: SET_QUARTERS_ERROR,
  }
}

export function setConversationSuccess(payload) {
  return {
    type: SET_CONVERSATION_SUCCESS,
    payload,
  }
}

export function addAdvisorVideoSuccess(
  currentUserUid,
  quarterUid,
  fileType,
  url
) {
  return {
    type: ADD_ADVISOR_VIDEO_SUCCESS,
    payload: {
      currentUserUid,
      quarterUid,
      fileType,
      url,
    },
  }
}

export function editQuarterBegin() {
  return {
    type: EDIT_QUARTER_BEGIN,
  }
}

export function editQuarterSuccess(payload) {
  return {
    type: EDIT_QUARTER_SUCCESS,
    payload,
  }
}

export function editQuarterError() {
  return {
    type: EDIT_QUARTER_ERROR,
  }
}
