import axiosInstance from '../../../bootstrap/axios'
import FirebaseManager from '../../../FirebaseManager'
import {
  SET_USER,
  SET_CURRENT_USER_ATTRIBUTES,
  SET_ACCOUNTS_SUCCESS,
  LOGOUT,
  SET_CURRENT_USER_ONBOARDING,
  SET_REMEMBER_ME,
  FETCH_APP_DATA_BEGIN,
  FETCH_APP_DATA_SUCCESS,
} from './types'

export function setRememberMe(bool) {
  return {
    type: SET_REMEMBER_ME,
    payload: bool,
  }
}

export function fetchAppDataBegin() {
  return {
    type: FETCH_APP_DATA_BEGIN,
  }
}

export function fetchAppDataSuccess() {
  return {
    type: FETCH_APP_DATA_SUCCESS,
  }
}

export function setUser(user) {
  if (user && !user?.attributes) {
    console.warn('Setting a user with null attributes')
  }
  FirebaseManager.setUser(user)
  return {
    type: SET_USER,
    payload: user,
  }
}

export function setCurrentUserAttributes(payload) {
  return {
    type: SET_CURRENT_USER_ATTRIBUTES,
    payload,
  }
}

export function setCurrentUserOnboarding(bool) {
  return {
    type: SET_CURRENT_USER_ONBOARDING,
    payload: bool,
  }
}

export function setAccountsSuccess(payload) {
  return {
    type: SET_ACCOUNTS_SUCCESS,
    payload,
  }
}

export function logout() {
  FirebaseManager.setUser(null)
  axiosInstance.defaults.headers.common.Authorization = null
  return {
    type: LOGOUT,
  }
}
