import axiosInstance from '../../../bootstrap/axios'
import Config from '../../config'

export function requestVerificationRequest(values) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/agencies/request-domain-verification`,
      data: values,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function confirmVerificationRequest(values) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/agencies/confirm-domain-verification`,
      data: values,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function addTeamToAgencyRequest(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/agencies/${payload.agencyUid}/teams/create`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function editTeamRequest(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'PATCH',
      url: `${Config.BASE_API_URL}/agencies/${payload.agencyUid}/teams/${payload.uid}/edit`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function addAdvisorToTeamRequest(teamId, agencyUid, advisorUid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/agencies/${agencyUid}/teams/${teamId}/add`,
      data: {
        advisorUid,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAllTeamsRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/teams`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAgencyTeamsRequest(teamUid, agencyUid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/agencies/${agencyUid}/teams/${teamUid}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAgenciesRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/agencies/`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAgencyByUidRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/agencies/${uid}`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function createAgencyRequest(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/agencies/create/`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function editAgencyRequest(uid, payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'PATCH',
      url: `${Config.BASE_API_URL}/agencies/${uid}/edit/`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}
