import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export function transformMarketingMaterials(materials) {
  return materials.items.map(transformMarketingMaterial)
}

export function transformQuarterMarketingMaterials(materials = []) {
  return materials.map(transformQuarterMarketingMaterial)
}

export function transformQuarters(quarters) {
  return quarters.map(transformQuarter)
}

export function transformQuarterMarketingMaterial(material) {
  return {
    description: transformRichText(material?.fields?.description),
    contentType: material.fields.file.contentType,
    name: material.fields.title,
    url: `https:${material.fields.file.url}`,
  }
}

export function transformMarketingMaterial(material) {
  if (material?.fields) {
    return {
      description: transformRichText(material?.fields?.description),
      contentType: material.fields?.file?.fields?.file?.contentType,
      name: material.fields?.name || '',
      url: `https:${material.fields?.file?.fields?.file?.url}`,
    }
  } else {
    return null
  }
}

export function transformQuarter(quarter) {
  return {
    uid: quarter?.sys?.id,
    name: quarter.fields.name,
    quarter: quarter.fields.quarter,
    year: quarter.fields.year,
    welcomeScript: transformRichText(quarter.fields.welcomeScript),
    description: transformRichText(quarter.fields.description),
    modulesDescription: transformRichText(quarter.fields.modulesDescription),
    modules: transformModules(quarter.fields.modules),
    bookOfTheQuarter: transformBookOfTheQuarter(
      quarter.fields.bookOfTheQuarter
    ),
    articles: transformArticles(quarter.fields.articles),
    learnMe: quarter.fields.learnMe
      ? transformLearnMe(quarter.fields.learnMe)
      : null,
    mindsetMe: transformMindsetMe(quarter.fields.mindsetMe),
    welcomeScriptPdf: transformMarketingMaterial(
      quarter.fields.welcomeScriptPdf
    ),
  }
}

export function transformArticles(articles) {
  return {
    title: articles.fields.title,
    description: transformRichText(articles.fields.description),
    articles: articles.fields.articles.map(transformArticle),
  }
}

export function transformArticle(article) {
  return {
    title: article.fields.title,
    publisher: article.fields.publisher,
    url: article.fields.url,
    image: transformImage(article.fields.image),
    buttonText: article.fields.buttonText,
  }
}

export function transformModules(modules) {
  return modules.map(transformModule)
}

export function transformModule(module) {
  return {
    title: module.fields.title,
    description: module.fields.description,
    buttonText: module.fields.buttonText,
    image: transformImage(module.fields.image),
    trainingVideo: transformVideo(module.fields.trainingVideo),
    marketingMaterials: transformQuarterMarketingMaterials(
      module.fields.marketingMaterials
    ),
  }
}

export function transformBookOfTheQuarter(book) {
  return {
    title: book.fields.title,
    author: book.fields.author,
    description: transformRichText(book.fields.description),
    url: book.fields.url,
    audioBookUrl: book.fields.audioBookUrl,
    buttonText: book.fields.buttonText,
    sparknotes: book.fields.sparknotes,
    image: transformImage(book.fields.image),
  }
}

export function transformMindsetMe(mindsetMe) {
  if (mindsetMe?.fields) {
    return {
      title: mindsetMe.fields.title,
      author: mindsetMe.fields.author,
      embed: mindsetMe.fields.embed,
      videoUrl: transformVideo(mindsetMe.fields.videoUrl),
      videoThumbnail: transformImage(mindsetMe.fields.videoThumbnail),
      description: transformRichText(mindsetMe.fields.description),
    }
  } else {
    return {}
  }
}

export function transformLearnMe(learnMe) {
  if (!learnMe) {
    return {}
  }
  return {
    title: learnMe.fields.title,
    videoUrl: transformVideo(learnMe.fields.videoUrl),
    videoThumbnail: transformImage(learnMe.fields.videoThumbnail),
    description: transformRichText(learnMe.fields.description),
  }
}

export function transformImage(image) {
  if (image) {
    return {
      src: image.fields.file.url,
      width: image.fields.file.details?.image?.width,
      height: image.fields.file.details?.image?.height,
    }
  } else {
    return {}
  }
}

export function transformVideo(video) {
  if (video) {
    return {
      src: `https:${video.fields.file.url}`,
      contentType: video.fields.file.contentType,
      fileName: video.fields.file.fileName,
    }
  } else {
    return {}
  }
}

export function transformRichText(description) {
  if (description) {
    return documentToHtmlString(description)
  } else {
    return ''
  }
}
