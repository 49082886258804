import React from 'react'

import { Card, Col, Row } from 'antd'
import './styles.css'
import { useCalendlyEventListener } from 'react-calendly'
import { connect } from 'react-redux'

import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import ModuleCard from '../../../../shared/components/ModuleCard'

function returnStrFromInt(int) {
  switch (int) {
    case 0:
      return 'Conversation One'
    case 1:
      return 'Conversation Two'
    case 2:
      return 'Conversation Three'
    case 3:
      return 'Conversation Four'
    default:
      return undefined
  }
}

function Conversations({ modules, modulesDescription, quarter, year }) {
  useCalendlyEventListener({
    onProfilePageViewed: () => logCalendlyEvent('onProfilePageViewed'),
    onDateAndTimeSelected: () => logCalendlyEvent('onDateAndTimeSelected'),
    onEventTypeViewed: () => logCalendlyEvent('onEventTypeViewed'),
    onEventScheduled: (e) => logCalendlyEvent(e),
  })

  function logCalendlyEvent(e) {
    FirebaseManager.logEvent(e?.data?.event, e?.data?.payload)
  }

  if (!modules?.length) {
    return <FPEEmptyState description={'No content yet'} />
  }
  return (
    <Card title={`Quarter ${quarter}, ${year} Conversations`}>
      <p
        style={{ marginBottom: 20 }}
        dangerouslySetInnerHTML={{
          __html: modulesDescription,
        }}
      />
      <Row gutter={[16, 16]} align="center" style={{ marginTop: 30 }}>
        {modules?.map((module, index) => {
          return (
            <Col xs={24} xl={9} key={`module_${module.title}_${index}`}>
              <ModuleCard
                cardTitle={`${returnStrFromInt(index)}`}
                key={module.title}
                data={module}
              />
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

function mapStateToProps({ quarters }) {
  const { activeQuarterContent } = quarters
  return {
    quarter: activeQuarterContent?.quarter,
    year: activeQuarterContent?.year,
    modulesDescription: activeQuarterContent?.modulesDescription,
    modules: activeQuarterContent?.modules || null,
  }
}

export default connect(mapStateToProps)(Conversations)
