import { current, produce } from 'immer'

import {
  SET_QUARTERS_BEGIN,
  SET_QUARTERS_SUCCESS,
  SET_QUARTERS_ERROR,
  ADD_ADVISOR_VIDEO_SUCCESS,
  SET_MARKETING_MATERIALS_SUCCESS,
  EDIT_QUARTER_BEGIN,
  EDIT_QUARTER_SUCCESS,
  EDIT_QUARTER_ERROR,
} from './types'

const INITIAL_STATE = {
  loading: false,
  activeQuarterUid: null,
  activeQuarterMetadata: [],
  activeQuarterContent: [],
  quarterMetadata: [],
  marketingMaterials: [],
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_QUARTERS_BEGIN:
      draft.loading = true
      return draft
    case SET_QUARTERS_SUCCESS:
      draft.loading = false
      draft.activeQuarterMetadata =
        action.payload.quarterMetadata.find((quarter) => {
          return quarter.activeForAdvisors && quarter.activeForClients
        }) || null
      draft.activeQuarterContent =
        action.payload.quarterContent.find((quarter) => {
          return quarter.uid === draft.activeQuarterMetadata?.uid
        }) || null
      draft.quarterContent = action.payload.quarterContent
      draft.quarterMetadata = action.payload.quarterMetadata
      return draft
    case SET_QUARTERS_ERROR:
      draft.loading = false
      return draft
    case ADD_ADVISOR_VIDEO_SUCCESS:
      const d = current(draft)
      if (!draft.activeQuarterMetadata?.advisorVideos) {
        draft.activeQuarterMetadata = {
          ...(draft.activeQuarterMetadata || {}),
          advisorVideos: [],
        }
      }
      draft.activeQuarterMetadata.advisorVideos.push({
        advisorUid: action.payload.currentUserUid,
        contentType: action.payload.fileType,
        url: action.payload.url,
      })
      const index = draft.quarterMetadata.findIndex((quarter) => {
        return quarter.uid === action.payload?.quarterUid
      })
      if (index === -1) {
        console.warn('Could not find quarterMetadata to add video to')
      } else {
        if (!draft.quarterMetadata[index].advisorVideos) {
          draft.quarterMetadata[index].advisorVideos = []
        }
        draft.quarterMetadata[index].advisorVideos.push({
          advisorUid: action.payload.currentUserUid,
          contentType: action.payload.fileType,
          url: action.payload.url,
        })
      }
      return draft
    case SET_MARKETING_MATERIALS_SUCCESS:
      draft.marketingMaterials = action.payload
      return draft
    case EDIT_QUARTER_BEGIN:
      return draft
    case EDIT_QUARTER_SUCCESS:
      const quarterIndex = draft.quarterMetadata.findIndex((quarter) => {
        return quarter.uid === action.payload?.uid
      })
      if (quarterIndex !== -1) {
        draft.quarterMetadata[quarterIndex] = action.payload
      } else {
        console.warn('Could not find a quarter to edit')
      }
      return draft
    case EDIT_QUARTER_ERROR:
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
