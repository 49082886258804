import React from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function AgencyLink({ agency, disable = false, external = false }) {
  if (agency) {
    if (disable) {
      return <p>{agency.name}</p>
    } else {
      if (external) {
        if (agency.website) {
          return (
            <a href={agency.website} target="_blank" rel="noreferrer">
              {agency.name}
            </a>
          )
        } else {
          return <Link to={`/admin/firms/${agency.uid}`}>{agency.name}</Link>
        }
      } else {
        return <Link to={`/admin/firms/${agency.uid}`}>{agency.name}</Link>
      }
    }
  } else {
    return null
  }
}

function mapStateToProps({ agencies = {} }, { uid }) {
  if (!uid) {
    return {}
  } else {
    const agency = agencies.data?.find?.((agency) => {
      return agency.uid === uid
    })
    return {
      agency,
    }
  }
}

export default connect(mapStateToProps)(React.memo(AgencyLink))
