import axiosInstance from '../../../bootstrap/axios'
import Config from '../../config'

export function getQuartersRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/quarters/`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function sendMailchimpCampaignsRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/quarters/campaigns/send`,
      data: {
        uid,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function createMailChimpCampaignsRequest(uid) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/quarters/campaigns/`,
      data: {
        uid,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function quarterPatchRequest(uid, agencyUid, payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'PATCH',
      url: `${Config.BASE_API_URL}/quarters/${uid}/`,
      data: {
        agencyUid,
        payload,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function addAdvisorVideoToQuarterRequest(
  userId,
  userName,
  quarterUid,
  fileType,
  url,
  thumbnailUrl
) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/quarters/${quarterUid}/videos/add`,
      data: {
        userId,
        userName,
        url,
        fileType,
        thumbnailUrl,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export function getAllBooksRequest() {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'GET',
      url: `${Config.BASE_API_URL}/books`,
    })
      .then(resolve)
      .catch(reject)
  })
}

export function requestBookRequest(payload) {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: 'POST',
      url: `${Config.BASE_API_URL}/requestBook`,
      data: payload,
    })
      .then(resolve)
      .catch(reject)
  })
}
