import { useSelector } from 'react-redux'

function useAuth() {
  const user = useSelector((state) => {
    return state.session.user
  })
  return {
    user,
  }
}

export default useAuth
