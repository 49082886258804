import React from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import FirebaseManager from '../../../../../../FirebaseManager'
import UserLink from '../../../../../../shared/components/Links/UserLink'

function ClientUserDetail({ user }) {
  const [emailStats, setEmailStats] = React.useState(null)

  React.useEffect(() => {
    FirebaseManager.getEmailAnalyticsForUser(user.uid)
      .then((data) => {
        setEmailStats(data)
      })
      .catch(console.warn)
  }, [user.uid])

  function renderUserDetails() {
    if (user) {
      return (
        <div>
          <p>
            <strong>Financial Planner:</strong>{' '}
            {user?.advisor ? (
              <UserLink uid={user?.advisor?.uid} />
            ) : (
              'Unassigned'
            )}
          </p>
          <p>
            <strong>Onboarded:</strong> {(!!user.onboarded).toString()}
          </p>
        </div>
      )
    } else {
      return null
    }
  }
  return null
}

function mapStateToProps({ users = {} }, { user }) {
  if (user) {
    if (user.advisorUid) {
      const advisor = users.data?.find?.((u) => {
        return u.uid === user.advisorUid
      })
      return {
        user: {
          ...user,
          advisor,
        },
      }
    } else {
      return {}
    }
  } else {
    return {}
  }
}

export default connect(mapStateToProps)(ClientUserDetail)
