import React from 'react'

import {
  Button,
  Card,
  ConfigProvider,
  Descriptions,
  PageHeader,
  Space,
  Table,
  Tabs,
} from 'antd'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  ADMIN_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
} from '../../../../features/session/redux/types'
import FPECsvLink from '../../../../shared/components/FPECsvLink'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import TeamLink from '../../../../shared/components/Links/TeamLink'
import UserLink from '../../../../shared/components/Links/UserLink'
import { formatUrl } from '../../../../shared/helpers/linkingHelpers'

const { TabPane } = Tabs

function AgencyDetail({ data, currentUser }) {
  const navigate = useNavigate()

  function handleDeleteTeam() {
    console.log('Deleting team')
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      uid: 'name',
      sorter: (a, b) => {
        return a.name - b.name
      },
      render: (text, team) => {
        return <TeamLink uid={team.uid} />
      },
    },
    {
      title: 'Financial Planners',
      dataIndex: 'advisors',
      uid: 'advisors',
      render: (advisors /* team */) => {
        return advisors.map((uid, index) => {
          return (
            <UserLink
              key={uid}
              uid={uid}
              comma={index !== advisors.length - 1}
            />
          )
        })
      },
    },
    // {
    //   title: 'Action',
    //   uid: 'action',
    //   render: (text, team) => {
    //     return (
    //       <Space size="middle">
    //         <a
    //           onClick={() => {
    //             return handleDeleteTeam(team)
    //           }}
    //         >
    //           Delete
    //         </a>
    //       </Space>
    //     )
    //   },
    // },
  ]

  function addTeam() {
    navigate(`/admin/firms/${data.uid}/teams/create`)
  }

  const extraContent = (
    <Space>
      {(data?.teams || []).length ? (
        <FPECsvLink
          filename={`${data.name}_teams.csv`}
          data={data?.teams || []}
        />
      ) : null}
      <Button type="primary" onClick={addTeam}>
        Add Team
      </Button>
    </Space>
  )

  function handleEditFirm() {
    navigate(`/admin/firms/${data.uid}/edit`)
  }

  if (!data) {
    return null
  }

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={data.name}
        style={{ padding: 0, paddingBottom: 16 }}
        extra={
          [ADMIN_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
            currentUser?.attributes?.type
          ) ? (
            <Button type="primary" onClick={handleEditFirm}>
              Edit Firm
            </Button>
          ) : null
        }
        footer={
          [ADMIN_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
            currentUser?.attributes?.type
          ) ? (
            <Tabs defaultActiveKey="1" tabBarExtraContent={extraContent}>
              <TabPane tab="Teams" key="1">
                <ConfigProvider
                  renderEmpty={() => (
                    <FPEEmptyState description="No teams yet" />
                  )}
                >
                  <Table
                    rowKey="uid"
                    columns={columns}
                    dataSource={data?.teams || []}
                    scroll={{
                      x: 600,
                    }}
                  />
                </ConfigProvider>
              </TabPane>
            </Tabs>
          ) : null
        }
      >
        <img
          src={data.logoUrl}
          style={{ height: 50, objectFit: 'contain', marginBottom: 20 }}
        />
        <Descriptions
          size="small"
          column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}
        >
          {data.adminUid ? (
            <Descriptions.Item label="Admin">
              <UserLink uid={data.adminUid} />
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Address">
            <a
              target="_blank"
              href={`http://maps.google.com/?q=${data?.address}`}
              rel="noreferrer"
            >
              {data?.address}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Office Phone">
            <a href={`tel:${data?.officePhoneNumber}`}>
              {data?.officePhoneNumber}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Website">
            <a target="_blank" href={formatUrl(data?.website)} rel="noreferrer">
              {formatUrl(data?.website)}
            </a>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
    </Card>
  )
}

function mapStateToProps({ session: { user }, agencies = {}, teams = {} }) {
  const paths = window.location.pathname.split('/')
  const uid = paths[paths.length - 1]
  let data =
    agencies.data?.find?.((agency) => {
      return agency.uid === uid
    }) || null
  if (data) {
    data = { ...data }
  }
  if (data) {
    if (teams.data?.length) {
      let teamsMap = {}
      teams.data.forEach((team) => {
        if (team) {
          teamsMap[team.uid] = team
        }
      })
      if (data.teams?.length) {
        data.teams = data.teams
          .map((teamUid) => {
            if (teamUid && teamsMap[teamUid]) {
              return teamsMap[teamUid]
            } else {
              return null
            }
          })
          .filter((x) => x)
      }
    }
  } else {
    console.warn(`Could not find firm ID ${uid}`)
  }
  return {
    data,
    currentUser: user,
  }
}

export default connect(mapStateToProps)(AgencyDetail)
