/* eslint-disable arrow-body-style */
import React from 'react'

import { UserOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  message,
  Modal,
  Result,
} from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Header } from 'antd/lib/layout/layout'
import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import {
  logout,
  setRememberMe,
  setUser,
} from '../../features/session/redux/actions'
import {
  ADVISOR_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../features/session/redux/types'
import FirebaseManager from '../../FirebaseManager'
import { returnMessageForFirebaseError } from '../../FirebaseManager/helpers'
import { openLink } from '../../shared/helpers/linkingHelpers'

const LOGO_SOURCE = '/logo.png'
const SMALL_LOGO_SOURCE = '/fpe_small_logo.png'
const MAILCHIMP_LOGO_SOURCE = '/mailchimp.png'

const layout = {}

const ALLOW_SIGN_UP = false

const tailLayout = {
  wrapperCol: { span: 16 },
}

export default function SignUp() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [searchParams /* setSearchParams */] = useSearchParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [verificationCode, setVerificationCode] = React.useState('')
  const [waitingForVerification, setWaitingForVerification] =
    React.useState(false)
  const [unverifiedDomain, setUnverifiedDomain] = React.useState(null)

  const newUser = searchParams.get('newUser')

  React.useEffect(() => {
    ReactGA.event({
      category: 'Sign Up View',
      action: 'sign up',
    })
    FirebaseManager.logEvent('Sign Up View')
    dispatch(logout())
    dispatch(setUser(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const from = location.state?.from?.pathname || '/'

  const signup = (values, comingFromDomainVerification = false) => {
    FirebaseManager.signUpWithEmailAndPassword({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      type: ADVISOR_USER_TYPE,
    })
      .then((user) => {
        setButtonLoading(false)
        setWaitingForVerification(false)
        setVerificationCode('')
        setUnverifiedDomain(null)
        if (comingFromDomainVerification) {
          message.success(
            "You've completed registration with a verified domain!"
          )
        } else {
          message.success("You've completed registration!")
        }
        dispatch(setRememberMe(values.rememberMe))
        dispatch(setUser(user))
        navigate('/admin/users', { replace: true })
      })
      .catch((error) => {
        console.warn('Error signing up', error)
        setLoading(false)
        message.error(returnMessageForFirebaseError(error?.response?.data))
      })
  }

  const onFinish = (values) => {
    setLoading(true)
    if (newUser) {
      FirebaseManager.changeUserPassword(
        values.email,
        values.currentPassword,
        values.newPassword
      )
        .then((user) => {
          dispatch(setRememberMe(values.rememberMe))
          dispatch(setUser(user))
          if (user.attributes.type === CLIENT_USER_TYPE) {
            const redirect = searchParams.get('redirect')
            navigate(redirect || '/', { replace: true })
          } else {
            navigate('/admin/users', { replace: true })
          }
        })
        .catch((error) => {
          console.warn(error)
          message.error(returnMessageForFirebaseError(error))
          setLoading(false)
        })
    } else {
      FirebaseManager.getSupportedDomains()
        .then((domains) => {
          const emailDomain = values.email.split('@')[1]
          const verifiedDomain = domains.find((d) => {
            return d.domain?.includes(emailDomain) && d.domain?.verified
          })
          if (verifiedDomain) {
            signup()
          } else {
            setLoading(false)
            setVerificationCode('')
            setWaitingForVerification(false)
            setUnverifiedDomain(emailDomain)
          }
        })
        .catch((error) => {
          console.warn(error)
          message.error('Unable to sign up. Please try again later.')
        })
    }
  }

  return (
    <div>
      <Header
        className="landing-page-header"
        style={{
          padding: '25px 50px',
          height: 85,
          position: 'fixed',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 900,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img
              src={LOGO_SOURCE}
              style={{ width: 250, objectFit: 'contain' }}
            />
          </a>
        </div>
      </Header>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '100vh' }}
      >
        <Col lg={12} xs={24}>
          <Card bordered title="Welcome!">
            {ALLOW_SIGN_UP ? (
              <Form {...layout} form={form} onFinish={onFinish}>
                <Form.Item
                  name="email"
                  labelCol={{ span: 24 }}
                  labelWrap
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email.',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  labelCol={{ span: 24 }}
                  labelWrap
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name.',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  labelCol={{ span: 24 }}
                  labelWrap
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your last name.',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                {newUser ? (
                  <>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      labelWrap
                      name="currentPassword"
                      label="Current Password"
                      rules={[
                        {
                          required: true,
                          secure: true,
                          message: 'Please enter your current password.',
                        },
                        {
                          min: 6,
                          message: 'Password must be at least 6 characters.',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      labelCol={{ span: 24 }}
                      labelWrap
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your new password.',
                        },
                        {
                          min: 6,
                          message: 'Password must be at least 6 characters.',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmNewPassword"
                      label="Confirm New Password"
                      labelCol={{ span: 24 }}
                      labelWrap
                      dependencies={['newPassword']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your new password.',
                        },
                        {
                          min: 6,
                          message: 'Password must be at least 6 characters.',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                'The passwords that you entered do not match.'
                              )
                            )
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="password"
                      label="Password"
                      labelCol={{ span: 24 }}
                      labelWrap
                      rules={[
                        {
                          required: true,
                          secure: true,
                          message: 'Please enter your password.',
                        },
                        {
                          min: 6,
                          message: 'Password must be at least 6 characters.',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      labelCol={{ span: 24 }}
                      labelWrap
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password.',
                        },
                        {
                          min: 6,
                          message: 'Password must be at least 6 characters.',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                'The passwords that you entered do not match.'
                              )
                            )
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  {...tailLayout}
                  name="rememberMe"
                  initialValue={{
                    checked: true,
                  }}
                  valuePropName="checked"
                >
                  <Checkbox>Remember Me</Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Sign Up
                  </Button>
                </Form.Item>
                {newUser ? null : (
                  <div>
                    Already have an account? <Link to="/login">Log In</Link>
                  </div>
                )}
              </Form>
            ) : (
              <div>
                <p>
                  Please contact us to gain access to the Financial Planning
                  Experience platform. If you are having trouble accessing your
                  account, we can help. If you have questions about our services
                  or want to chat about anything else, just reach out to be
                  connected with our sales team. Thank you for your interest!
                </p>
                <Button
                  type="primary"
                  onClick={() =>
                    openLink('mailto:info@financialplanningexperience.com')
                  }
                >
                  Contact Us
                </Button>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        visible={!!unverifiedDomain}
        closable={false}
        maskClosable={false}
        afterClose={() => {
          setVerificationCode('')
          setButtonLoading(false)
          setWaitingForVerification(false)
        }}
        okButtonProps={{ loading: buttonLoading }}
        okText={
          waitingForVerification ? 'Verify Domain' : 'Request Verification'
        }
        cancelText="Cancel"
        onOk={() => {
          setButtonLoading(true)
          if (waitingForVerification) {
            const values = form.getFieldsValue()
            const comingFromDomainVerification = true
            signup(values, comingFromDomainVerification)
          } else {
            setWaitingForVerification(false)
            const values = form.getFieldsValue()
            FirebaseManager.requestVerification({
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              displayName: `${values.firstName} ${values.lastName}`,
              domain: unverifiedDomain,
            })
              .then(() => {
                message.success(
                  `A verification email has been sent to ${values.email}!`
                )
                setWaitingForVerification(true)
                setButtonLoading(false)
              })
              .catch((error) => {
                console.warn(error)
                navigate('/')
                setButtonLoading(false)
                setWaitingForVerification(false)
                setUnverifiedDomain(null)
                message.error(
                  'We were not able to request verification. Please contact the Financial Planning Experience team.'
                )
              })
          }
        }}
        onCancel={() => {
          setButtonLoading(false)
          setWaitingForVerification(false)
          setUnverifiedDomain(null)
        }}
      >
        <Result
          icon={
            <Row
              align="center"
              style={{
                alignItems: 'center',
              }}
            >
              <img
                src={SMALL_LOGO_SOURCE}
                style={{ width: 100, height: 75, objectFit: 'contain' }}
              />
              <span
                style={{
                  fontSize: 100,
                  color: 'rgba(0,0,0,0.25)',
                  lineHeight: 'normal',
                }}
              >
                /
              </span>
              <img
                src={MAILCHIMP_LOGO_SOURCE}
                style={{ width: 90, height: 90, objectFit: 'contain' }}
              />
            </Row>
          }
          title={
            waitingForVerification
              ? 'Enter Verification Code'
              : 'Email Verification Required'
          }
          subTitle={
            waitingForVerification ? (
              <div>
                <Input
                  style={{ margin: '24px 0' }}
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value)
                  }}
                />
                <p style={{ margin: 0 }}>
                  Clicking "Verify Domain" will complete your registration and
                  give you access to our platform. FPE will never send emails on
                  your behalf without your permission, and you can adjust your
                  communication preferences at any time.
                </p>
              </div>
            ) : (
              <div>
                <p style={{ marginTop: 20 }}>
                  FPE uses{' '}
                  <a
                    href="https://mailchimp.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mailchimp
                  </a>{' '}
                  (an email marketmarking management system) to communicate with
                  your members, but we need to make sure you own the email
                  account before we send messages on your behalf.
                </p>
                <p style={{ margin: 0 }}>
                  To verify you are who you say you are, click "Request
                  Verification" below, and Mailchimp will send you a verifcation
                  code which you can enter on the next screen.
                </p>
              </div>
            )
          }
        />
      </Modal>
    </div>
  )
}
