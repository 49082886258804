import React from 'react'

import { UploadOutlined } from '@ant-design/icons'
import {
  Upload,
  Button,
  Space,
  message,
  Table,
  Alert,
  ConfigProvider,
  Modal,
  Result,
  Card,
} from 'antd'
import Papa from 'papaparse'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { fetchAllAppData } from '../../../../features/session/redux/operations'
import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'

const ACCOUNT_EXISTS = 'Account already exists'
const EMAIL_IS_REQUIRED = 'Email is required'
const EMAIL_IS_DUPLICATED = 'Email is duplicated'

const uuidv4 = require('uuid').v4

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

function RemoveUsersForm({ fetchAllAppData, currentUser }) {
  const [loading, setLoading] = React.useState(false)
  const [validating, setValidating] = React.useState(false)
  const [remoteErrors, setRemoteErrors] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [usersList, setUsersList] = React.useState([])
  const [result, setResult] = React.useState(null)
  const [emailErrors, setEmailErrors] = React.useState({})
  const [numberOfErrors, setNumberOfErrors] = React.useState(0)

  const navigate = useNavigate()

  const columns = [
    {
      title: 'Email',
      dataIndex: 'Email',
      uid: 'Email',
    },
  ]

  // https://www.papaparse.com/docs#config
  const csvConfig = {
    header: true,
    skipEmptyLines: true,
    error: () => {
      message.error('Unable to parse CSV')
      setLoading(false)
      setValidating(false)
    },
    complete: (results /* file */) => {
      setLoading(false)
      preValidateUsersList(results.data || []).catch((error) => {
        console.warn(error)
        message.error(
          'Unable to validate CSV. Please make sure your CSV format matches the sample CSV.'
        )
        setValidating(false)
        setLoading(false)
      })
    },
  }

  function postValidateUsersList(data) {
    let errorCount = 0
    data = data.map((user) => {
      user.error = {}
      return user
    })
    if (errorCount) {
      setUsersList(data)
      return true
    } else {
      return false
    }
  }

  async function preValidateUsersList(data, shouldSetLoading = true) {
    return new Promise((resolve, reject) => {
      setValidating(true)
      setRemoteErrors(null)
      try {
        const localEmailErrors = {}
        data = data.map((user) => {
          return {
            uid: uuidv4(),
            ...user,
          }
        })
        let errorCount = 0
        data = data.map((user) => {
          user.error = {}
          if (!user.Email) {
            localEmailErrors[user.uid] = {
              ...user,
              error: EMAIL_IS_REQUIRED,
            }
          }
          if (!localEmailErrors[user.uid]) {
            if (!validateEmail(user.Email)) {
              errorCount += 1
              user.error = {
                ...user.error,
                Email: 'Email is invalid',
              }
            }
          }
          return user
        })
        setValidating(false)
        if (shouldSetLoading) {
          setLoading(false)
        }
        if (Object.keys(localEmailErrors)?.length) {
          setEmailErrors(localEmailErrors)
          data = data.filter((d) => {
            return !localEmailErrors[d.uid]
          })
        } else if (errorCount > 0) {
          message.warn('Please correct the errors below.')
        }
        setUsersList(data)
        resolve(data)
      } catch (error) {
        console.warn(error)
        reject(error)
      }
    })
  }

  function beginImport() {
    setLoading(true)
    preValidateUsersList(usersList, false)
      .then(() => {
        const finalUsersList = usersList.map((user) => {
          return {
            email: user.Email,
          }
        })

        FirebaseManager.bulkDeleteUsers(finalUsersList)
          .then((response) => {
            if (response.rejectedUsers?.length) {
              setResult(response)
              setNumberOfErrors(0)
              setLoading(false)
              setUsersList([])
              fetchAllAppData()
              setValidating(false)
            } else {
              message.success(
                `${finalUsersList.length} user${
                  finalUsersList.length === 1 ? '' : 's'
                } ${finalUsersList.length === 1 ? 'has' : 'have'} been removed!`
              )
              setUsersList([])
              fetchAllAppData()
              navigate('/admin/users')
            }
          })
          .catch((error) => {
            console.warn(error)
            setLoading(false)
            setValidating(false)
            message.error("We were't unable to remove your users")
          })
      })
      .catch((error) => {
        message.error(
          'Unable to validate CSV. Please make sure your CSV format matches the sample CSV.'
        )
        console.warn(error)
        setLoading(false)
        setValidating(false)
      })
  }

  const onFinish = (/* values */) => {
    const hasErrors = postValidateUsersList(usersList)
    if (!hasErrors) {
      Modal.confirm({
        title: 'Warning',
        content: (
          <div>
            <p>Are you sure you want to begin removing these users?</p>
          </div>
        ),
        onOk() {
          beginImport()
        },
        cancelText: 'Cancel',
        okText: 'Proceed',
        onCancel() {},
      })
    }
  }

  const onReset = () => {
    setFile(null)
    setUsersList(null)
    // setNumberOfErrors(0)
    setRemoteErrors(null)
    setLoading(false)
    setValidating(false)
  }

  const readyToUpload = usersList?.length > 0 && !numberOfErrors

  function returnErrorBannerCopy() {
    if (remoteErrors) {
      return (
        <div>
          We tried to remove everyone, but some users were unsuccessful. Please
          see the below list of errors. We would recommend ensuring your CSV
          format matches the sample CSV, then trying again.
          {remoteErrors.map((error) => {
            return (
              <div key={`error_${error.index}`} style={{ marginTop: 5 }}>
                <strong>{error.user.email}</strong>: {error.error}
              </div>
            )
          })}
        </div>
      )
    } else {
      const errorCountGreaterThanOne = numberOfErrors > 1
      return `There ${
        errorCountGreaterThanOne ? 'are' : 'is'
      } ${numberOfErrors} error${
        errorCountGreaterThanOne ? 's' : ''
      } in your CSV. Highlighted below is user info that needs to be corrected before you remove these users.`
    }
  }

  function renderImportErrors() {
    return (
      <ul>
        {(result?.rejectedUsers || []).map((user, index) => {
          return (
            <li key={`import_error_${index}`}>
              <strong>
                {`${user?.user?.email}: ${
                  user.error || 'Could not be imported'
                }`.toLowerCase()}
              </strong>
            </li>
          )
        })}
      </ul>
    )
  }

  function renderEmailErrors() {
    const els = []
    var index = 0
    for (var error in emailErrors) {
      if (emailErrors[error].error === ACCOUNT_EXISTS) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error].Email} is already registered`}</strong>
          </li>
        )
      } else if (emailErrors[error].error === EMAIL_IS_REQUIRED) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error]['First Name'] || 'A user'}${
              emailErrors[error]['First Name'] &&
              emailErrors[error]['Last Name']
                ? ` ${emailErrors[error]['Last Name']}`
                : ''
            } does not have an email`}</strong>
          </li>
        )
      } else if (emailErrors[error].error === EMAIL_IS_DUPLICATED) {
        els.push(
          <li key={`email_error_${index}`}>
            <strong>{`${emailErrors[error]['Email']} exists twice in your CSV. Both records have been removed, so please try removing the duplicate and re-uploading.`}</strong>
          </li>
        )
      }
      index++
    }
    if (els?.length) {
      return <ul style={{ textAlign: 'left' }}>{els}</ul>
    } else {
      return null
    }
  }

  const [windowInnerHeight, setWindowInnerHeight] = React.useState(undefined)
  function handleResize() {
    setWindowInnerHeight(window.innerHeight - 200)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function renderPreview() {
    return (
      <>
        {numberOfErrors > 0 || remoteErrors ? (
          <div style={{ marginBottom: 15 }}>
            <Alert type="error" message={returnErrorBannerCopy()} />
          </div>
        ) : null}
        <ConfigProvider
          renderEmpty={() => (
            <FPEEmptyState description="Upload a CSV to get started" />
          )}
        >
          <Table
            loading={validating}
            rowKey="uid"
            dataSource={usersList}
            columns={columns}
            pagination={false}
            scroll={{
              x: 600,
              y: windowInnerHeight,
            }}
            title={() => {
              return (
                <Space
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h1
                    style={{
                      margin: 0,
                      fontSize: 19,
                      fontWeight: 'bold',
                    }}
                  >
                    Bulk Remove Users
                  </h1>
                  <Space>
                    {readyToUpload ? null : (
                      <Link
                        to={'/example_remove_users.csv'}
                        target="_blank"
                        download
                      >
                        Download Sample CSV
                      </Link>
                    )}
                    <Button
                      onClick={onReset}
                      disabled={!usersList?.length || validating || loading}
                    >
                      Reset
                    </Button>
                    {readyToUpload ? (
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={onFinish}
                      >
                        Remove Users
                      </Button>
                    ) : (
                      <Upload
                        onRemove={() => {
                          setFile(null)
                        }}
                        showUploadList={false}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        valuePropName="fileList"
                        beforeUpload={(f) => {
                          setFile(f)
                          Papa.parse(f, csvConfig)
                          return false
                        }}
                        fileList={file ? [file] : []}
                        disabled={loading}
                      >
                        <Button
                          type="primary"
                          loading={validating}
                          icon={<UploadOutlined />}
                        >
                          Select CSV File
                        </Button>
                      </Upload>
                    )}
                  </Space>
                </Space>
              )
            }}
          />
        </ConfigProvider>
        <Modal
          visible={!!result}
          centered
          closable={false}
          maskClosable={false}
          okText="Ok"
          onOk={() => navigate('/admin/users')}
        >
          <Result
            status="warning"
            title="Warning"
            subTitle={
              <div>
                <p>
                  {`${result?.users?.length} user${
                    result?.users?.length === 1 ? '' : 's'
                  } ${
                    result?.users?.length === 1 ? 'has' : 'have'
                  } been uploaded, but ${result?.rejectedUsers?.length} user${
                    result?.rejectedUsers?.length === 1 ? '' : 's'
                  } ${
                    result?.rejectedUsers?.length === 1 ? 'was' : 'were'
                  } not able to be uploaded.`}
                </p>
                {renderImportErrors()}
              </div>
            }
          />
        </Modal>
        <Modal
          visible={Object.keys(emailErrors)?.length}
          centered
          closable
          maskClosable
          cancelText="Close"
          onOk={() => setEmailErrors({})}
          onCancel={() => setEmailErrors({})}
        >
          <Result
            title="Warning"
            subTitle={
              <div>
                <p>
                  Some users cannot be imported{' '}
                  <strong>and have been removed</strong>:
                </p>
                {renderEmailErrors()}
              </div>
            }
          />
        </Modal>
      </>
    )
  }

  return <Card bodyStyle={{ padding: 0 }}>{renderPreview()}</Card>
}

function mapStateToProps({ session: { user = {} } }) {
  return {
    currentUser: user,
  }
}

const mapDispatchToProps = {
  fetchAllAppData,
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveUsersForm)
