export const SET_TEAMS_SUCCESS = 'SET_TEAMS_SUCCESS'

export const ADD_TEAM_BEGIN = 'ADD_TEAM_BEGIN'
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS'
export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR'

export const EDIT_TEAM_BEGIN = 'EDIT_TEAM_BEGIN'
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS'
export const EDIT_TEAM_ERROR = 'EDIT_TEAM_ERROR'

export const GET_ALL_TEAMS_BEGIN = 'GET_ALL_TEAMS_BEGIN'
export const GET_ALL_TEAMS_SUCCESS = 'GET_ALL_TEAMS_SUCCESS'
export const GET_ALL_TEAMS_ERROR = 'GET_ALL_TEAMS_ERROR'

export const ADD_ADVISOR_TO_TEAM_BEGIN = 'ADD_ADVISOR_TO_TEAM_BEGIN'
export const ADD_ADVISOR_TO_TEAM_SUCCESS = 'ADD_ADVISOR_TO_TEAM_SUCCESS'
export const ADD_ADVISOR_TO_TEAM_ERROR = 'ADD_ADVISOR_TO_TEAM_ERROR'
