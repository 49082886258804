//@refresh reset
import React from 'react'

import { Avatar, Layout, Menu, message, Popover } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { PopupModal } from 'react-calendly'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride'
import { connect } from 'react-redux'
import { useLocation, Routes, Link, Route, useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import BookOfTheQuarter from './components/BookOfTheQuarter'
import CatchMeUp from './components/CatchMeUp'
import Contact from './components/Contact'
import Conversations from './components/Conversations'
import MindsetMe from './components/MindsetMe'
import OnboardingCarousel from './components/OnboardingCarousel'
import Welcome from './components/Welcome'
import { persistor } from '../../bootstrap/redux'
import { setCalendlyVisibility } from '../../features/calendly/redux/actions'
import { setQuarters } from '../../features/quarters/redux/operations'
import './styles.css'
import { setCurrentUserOnboarding } from '../../features/session/redux/actions'
import { fetchAllAppData } from '../../features/session/redux/operations'
import { CLIENT_USER_TYPE } from '../../features/session/redux/types'
import { editUser } from '../../features/users/redux/operations'

import {
  MenuOutlined,
  UserOutlined,
  HomeOutlined,
  LinkOutlined,
  PhoneOutlined,
  BulbOutlined,
  BookOutlined,
} from '@ant-design/icons'
import { Header } from 'antd/lib/layout/layout'

import { formatUrl } from '../../shared/helpers/linkingHelpers'
import usePrimaryColor from '../../shared/hooks/usePrimaryColor'
import EditProfile from '../AdminDashboard/components/EditProfile'

function renderStepContent(title, description) {
  return (
    <div>
      <h3>{title}</h3>
      {description ? <p>{description}</p> : null}
    </div>
  )
}

const defaultSteps = [
  {
    content: renderStepContent("Let's give you a quick tour 👀"),
    locale: { skip: <strong aria-label="skip">SKIP</strong> },
    placement: 'center',
    target: 'body',
    disableBeacon: true,
  },
  {
    target: '#menu_item_home',
    content: renderStepContent(
      'Home',
      'Find here an overview of the important topics you will want to discuss with your financial planner this quarter'
    ),
    disableBeacon: true,
  },
  {
    target: '#quick-links',
    content: renderStepContent(
      'Platform Links',
      'Use the quick links found here to access your account information online.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_content',
    content: renderStepContent(
      'Conversations',
      "In this section, you will find an overview of the current quarter's conversations."
    ),
    disableBeacon: true,
  },
  // {
  //   target: '#menu_item_learn_me',
  //   content: renderStepContent(
  //     'Learn Me',
  //     "Review key concepts here related to the current quarter's conversations; the “Learn Me” section will reinforce lessons from an area of focus. This section will deepen and widen your understanding of this quarter's theme through Behavioral Finance concepts."
  //   ),
  //   disableBeacon: true,
  // },
  {
    target: '#menu_item_catch_me_up',
    content: renderStepContent(
      'Catch Me Up',
      'Here, your financial planner will curate relevant news articles timely to changes in the financial planning landscape.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_book_of_the_quarter',
    content: renderStepContent(
      'Book of The Quarter',
      'Each quarter, there will be a new book selected that we believe will be of value to you. Request a copy of the book be sent to you directly by your financial planner.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_mindset_training',
    content: renderStepContent(
      'Mindset Training',
      'Financial wellness begins with mindset training. Every quarter, your financial planner will highlight an area of focus beneficial to your overall well being.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_contact',
    content: renderStepContent(
      'Contact',
      'Get in touch with your financial planner or find their contact information here.'
    ),
    disableBeacon: true,
  },
]

const { Content, Sider } = Layout

// https://codesandbox.io/s/animated-routes-demo-react-router-v6-6l1li

const routes = [
  { path: '/', name: 'Welcome', Component: Welcome },
  { path: '/content', name: 'Content', Component: Conversations },
  // { path: '/learn-me', name: 'Learn Me', Component: LearnMe },
  { path: '/catch-me-up', name: 'Catch Me Up', Component: CatchMeUp },
  {
    path: '/book-of-the-quarter',
    name: 'Book of the Quarter',
    Component: BookOfTheQuarter,
  },
  { path: '/mindset-training', name: 'Mindset Training', Component: MindsetMe },
  { path: '/contact', name: 'Contact', Component: Contact },
  {
    path: '/editProfile',
    name: 'View Profile',
    Component: EditProfile,
    breadcrumbName: 'View Profile',
  },
]

function Dashboard({
  currentUser,
  editUser,
  primarySchedulingUrl,
  calendly,
  setCalendlyVisibility,
  agency,
  team,
  setCurrentUserOnboarding,
  fetchAllAppData,
}) {
  const [showSecondaryOnboarding, setShowSecondaryOnboarding] =
    React.useState(false)
  const [stepIndex, setStepIndex] = React.useState(0)

  const [onboardingModalVisible, setOnboardingModalVisible] =
    React.useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  function logout() {
    persistor.purge()
  }

  function closeModal(e) {
    e?.preventDefault()
    setOnboardingModalVisible(false)
    setShowSecondaryOnboarding(true)
    editUser(currentUser?.uid, {
      onboarded: true,
    }).catch((error) => {
      console.warn('Could not set current user', error)
    })
  }

  React.useEffect(() => {
    if (currentUser?.attributes && !currentUser?.attributes?.onboarded) {
      setOnboardingModalVisible(true)
    }
  }, [currentUser])

  React.useEffect(() => {
    fetchAllAppData()
  }, [])

  function hideCalendly() {
    setCalendlyVisibility({ isOpen: false })
  }

  const onboardingComplete = React.useRef(false)
  const handleJoyrideCallback = React.useCallback(
    (data) => {
      const { action, type, status, index } = data
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
      if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
        onboardingComplete.current = true
        setStepIndex(0)
        setShowSecondaryOnboarding(false)
        navigate('/')
        message.success("You've completed onboarding!")
      } else if (
        type === EVENTS.STEP_AFTER ||
        type === EVENTS.TARGET_NOT_FOUND
      ) {
        const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        switch (stepIndex) {
          case 1:
          case 2:
            navigate('/')
            break
          case 3:
            navigate('/content')
            break
          case 4:
            navigate('/learn-me')
            break
          case 5:
            navigate('/catch-me-up')
            break
          case 6:
            navigate('/book-of-the-quarter')
            break
          case 7:
            navigate('/mindset-training')
            break
          case 8:
            navigate('/contact')
            break
          default:
            // do nothing
            break
        }
        setStepIndex(stepIndex)
      }
    },
    [stepIndex]
  )

  const primaryColor = usePrimaryColor()

  const joyride = React.useMemo(() => {
    return (
      <Joyride
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        run={showSecondaryOnboarding}
        steps={defaultSteps}
        showProgress
        showSkipButton
        disableOverlayClose
        continuous
        disableScrolling
        styles={{
          options: {
            primaryColor: primaryColor,
            overlayColor: `rgba(0,0,0,0.1)`,
          },
        }}
      />
    )
  }, [handleJoyrideCallback, primaryColor, showSecondaryOnboarding, stepIndex])

  const menuItems = [
    {
      label: 'Home',
      key: '/',
      id: 'menu_item_home',
      onClick: () => navigate('/'),
      icon: <HomeOutlined />,
    },
    {
      label: 'Conversations',
      icon: <PhoneOutlined />,
      id: 'menu_item_content',
      onClick: () => navigate('/content'),
      key: '/content',
    },
    // {
    //   label: 'Learn Me',
    //   icon: <VideoCameraOutlined />,
    //   id: 'menu_item_learn_me',
    //   onClick: () => navigate('/learn-me'),
    //   key: '/learn-me',
    // },
    {
      label: 'Catch Me Up',
      icon: <LinkOutlined />,
      id: 'menu_item_catch_me_up',
      onClick: () => navigate('/catch-me-up'),
      key: '/catch-me-up',
    },
    {
      label: 'Book of The Quarter',
      icon: <BookOutlined />,
      id: 'menu_item_book_of_the_quarter',
      onClick: () => navigate('/book-of-the-quarter'),
      key: '/book-of-the-quarter',
    },
    {
      label: 'Mindset Training',
      icon: <BulbOutlined />,
      id: 'menu_item_mindset_training',
      onClick: () => navigate('/mindset-training'),
      key: '/mindset-training',
    },
    {
      label: 'Contact',
      icon: <UserOutlined />,
      id: 'menu_item_contact',
      onClick: () => navigate('/contact'),
      key: '/contact',
    },
  ]

  function returnDefaultSelectedKeys() {
    const keys = []

    menuItems.forEach((item) => {
      if (location.pathname === item.key) {
        keys.push(item.key)
      }
      if (item.children?.length) {
        item.children.forEach((subItem) => {
          if (location.pathname === subItem.key) {
            keys.push(subItem.key)
          }
        })
      }
    })
    return keys
  }

  function takeATour(e) {
    e.preventDefault()
    setCurrentUserOnboarding(false)
    setTimeout(() => {
      setShowSecondaryOnboarding(true)
    }, 500)
  }

  function renderLogo() {
    if (team?.logoUrl) {
      return (
        <div className="logo">
          <img
            src={team?.logoUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )
    } else if (agency?.logoUrl) {
      return (
        <div className="logo">
          <img
            src={agency?.logoUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )
    } else {
      return null
    }
  }

  const firmName = agency?.name
  const firmAddress = agency?.address
  const firmNumber = agency?.officePhoneNumber

  return (
    <>
      <Layout hasSider style={{ minHeight: '100vh' }}>
        <Sider breakpoint="md" collapsedWidth="0">
          {renderLogo()}
          <div
            className="sider-container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Menu
              triggerSubMenuAction="click"
              theme="light"
              defaultSelectedKeys={returnDefaultSelectedKeys()}
              mode="vertical"
              items={menuItems}
            />
            <div
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: 10,
                paddingBottom: 13,
              }}
            >
              <div style={{ color: 'grey', fontSize: 12 }}>POWERED BY</div>
              <img
                src="/logo.png"
                style={{
                  width: '90%',
                  objectFit: 'contain',
                }}
              />
            </div>
          </div>
        </Sider>
        {joyride}
        <Content>
          <Header
            breadcrumb={{ routes }}
            trigger={
              <div style={{ backgroundColor: primaryColor }}>
                <MenuOutlined color="white" />
              </div>
            }
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Popover
              placement="bottomRight"
              title={
                currentUser?.displayName ? (
                  <strong>{currentUser?.displayName}</strong>
                ) : undefined
              }
              trigger="hover"
              style={{ cursor: 'pointer' }}
              content={
                <div>
                  <div>
                    <Link to="/editProfile">View Profile</Link>
                  </div>
                  <a href="" onClick={takeATour}>
                    Take a Tour
                  </a>
                  <div>
                    <Link onClick={logout} to="/login">
                      Logout
                    </Link>
                  </div>
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 15,
                }}
              >
                <h3
                  style={{
                    cursor: 'pointer',
                    color: primaryColor,
                    margin: 0,
                    paddingRight: 10,
                  }}
                >
                  My Account
                </h3>
                {currentUser?.attributes?.type === CLIENT_USER_TYPE ? null : (
                  <Avatar
                    src={currentUser?.photoURL || global.DEFAULT_PHOTO_URL}
                  >
                    {currentUser?.firstName?.[0]}
                    {currentUser?.lastName?.[0]}
                  </Avatar>
                )}
              </div>
            </Popover>
          </Header>
          <TransitionGroup component={null}>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={250}
              unmountOnExit
            >
              <Routes location={location}>
                {routes.map(({ path, Component }) => {
                  return (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <div className="quarter-with-sider">
                          <Component />
                          <div
                            style={{
                              width: '60%',
                              paddingTop: 25,
                              color: 'grey',
                              margin: 'auto',
                              textAlign: 'center',
                              fontSize: 16,
                              fontFamily: 'CormorantGaramond-MediumItalic',
                            }}
                          >
                            Securities, investment advisory services and
                            financial planning are offered through qualified
                            registered representatives of MML Investors
                            Services, LLC, Member SIPC{' '}
                            <a
                              href="https://www.sipc.org"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (www.sipc.org)
                            </a>
                            . {firmName} is not a subsidiary or affiliate of MML
                            Investors Services, LLC or its affiliated companies.
                            Supervisory office: {firmAddress} Tel:{' '}
                            <a href={`tel:${firmNumber}`}>{firmNumber}</a>.
                            CRN202507-2702274.
                          </div>
                        </div>
                      }
                    />
                  )
                })}
                <Route
                  path="*"
                  element={
                    <div className="quarter-with-sider">
                      <Welcome />
                      <div
                        style={{
                          width: '75%',
                          paddingTop: 25,
                          color: 'grey',
                          margin: 'auto',
                          textAlign: 'center',
                          fontSize: 16,
                          fontFamily: 'CormorantGaramond-MediumItalic',
                        }}
                      >
                        Securities, investment advisory services and financial
                        planning are offered through qualified registered
                        representatives of MML Investors Services, LLC, Member
                        SIPC{' '}
                        <a
                          href="https://www.sipc.org"
                          target="_blank"
                          rel="noreferrer"
                        >
                          (www.sipc.org)
                        </a>
                        . {firmName} is not a subsidiary or affiliate of MML
                        Investors Services, LLC or its affiliated companies.
                        Supervisory office: {firmAddress} Tel:{' '}
                        <a href={`tel:${firmNumber}`}>{firmNumber}</a>.
                        CRN202507-2702274.
                      </div>
                    </div>
                  }
                />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </Content>
        <Modal
          visible={!onboardingComplete.current && onboardingModalVisible}
          centered
          closable={false}
          maskClosable={false}
          footer={null}
          width={'75%'}
          height={'75%'}
          style={{ padding: 0, height: '100%' }}
          bodyStyle={{ padding: 0, height: '100%' }}
        >
          <OnboardingCarousel onClose={closeModal} />
        </Modal>
        {primarySchedulingUrl ? (
          <PopupModal
            url={formatUrl(primarySchedulingUrl)}
            pageSettings={
              {
                // backgroundColor: 'ffffff',
                // hideEventTypeDetails: false,
                // hideLandingPageDetails: false,
                // primaryColor,
                // textColor: primaryColor,
              }
            }
            utm={
              {
                // utmCampaign: 'Spring Sale 2019',
                // utmContent: 'Shoe and Shirts',
                // utmMedium: 'Ad',
                // utmSource: 'Facebook',
                // utmTerm: 'Spring',
              }
            }
            prefill={{
              name: currentUser?.attributes?.displayName,
              email: currentUser?.attributes?.email,
              customAnswers: {
                a1: calendly?.notes,
              },
            }}
            onModalClose={hideCalendly}
            open={calendly?.isOpen}
            rootElement={document.getElementById('root')}
          />
        ) : null}
      </Layout>
    </>
  )
}

function mapStateToProps({
  session: { user },
  users: { data: usersData },
  agencies: { data: agenciesData },
  teams: { data: teamsData },
  calendly,
}) {
  const advisorUid = user?.attributes?.advisorUid
  const advisor = usersData.find((user) => {
    return user.uid === advisorUid
  })
  const team =
    teamsData.find((team) => {
      return team.uid === advisor?.teamUid
    }) || null
  const primarySchedulingUrl = advisor?.primarySchedulingUrl
  const agency =
    agenciesData?.find((agency) => {
      return agency?.uid === advisor?.agencyUid
    }) || null
  return {
    primarySchedulingUrl,
    calendly,
    currentUser: user,
    agency,
    team,
  }
}

const mapDispatchToProps = {
  setQuarters,
  editUser,
  fetchAllAppData,
  setCurrentUserOnboarding,
  setCalendlyVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
