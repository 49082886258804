import React from 'react'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  message,
  Card,
  Upload,
  Col,
  Row,
  Alert,
  Switch,
  Modal,
  Divider,
  Typography,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'
import Compress from 'client-compress'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OAuth2Login from 'react-simple-oauth2-login'

import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
  CLIENT_USER_TYPE,
} from '../../../../features/session/redux/types'
import {
  createUser,
  editUser,
} from '../../../../features/users/redux/operations'
import FirebaseManager from '../../../../FirebaseManager'
import config from '../../../../FirebaseManager/config'
import CalendlyEventList from '../../../../shared/components/CalendlyEventsList'
import { returnUserTypeBasedOnCurrentUserRole } from '../../../../shared/helpers/returnUserTypeBasedOnCurrentUserRole'
import TeamCreateForm from '../TeamCreateForm'

const { Option } = Select

const CALENDLY_REDIRECT_URI =
  config.ENVIRONMENT === 'dev'
    ? 'https://fpe-dev.herokuapp.com/admin'
    : 'https://www.financialplanningexperience.com/admin'
// https://fpe-prod.herokuapp.com/admin
const CALENDLY_CLIENT_ID =
  config.ENVIRONMENT === 'dev'
    ? 'TdMvQozkIqWz85qAKsQDLEvQpwlAiyWWQgarBGaFDWo'
    : 'vYImcSFZr7FCSxguSFfBzpAjQh5PmsUy3xfH0S8lokM'
// s1mjEt8Bzo3yEzv0R8S1Fq5UpTEhAgL0GWpNMdBupgE

const CALENDLY_CLIENT_SECRET =
  config.ENVIRONMENT === 'dev'
    ? 'W3dDm1OdYWDIB0JI14vA8h-Pa-v1NToY9Aqzz0lI5ts'
    : 'HC1XKJRidE4R-kkuOLFb5PctY4fpDWkemEeiWnzIQV0'
// UsFHVcewlX2vCxzhDi4ynEvp9O7Y58IGbe1-c0q7jjg

const compress = new Compress({
  targetSize: 2,
})

const layout = {
  // labelCol: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
  // wrapperCol: { xs: 24, sm: 14, md: 12, lg: 10, xl: 8 },
}

const tailLayout = {
  wrapperCol: { span: 16 },
}

const uploadImage = async ({ file, filename, onProgress = () => {} }) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      console.warn('No images to compress -- skipping')
      resolve('')
    } else {
      compress
        .compress([file])
        .then((conversions) => {
          const { photo, info } = conversions[0]
          console.log(conversions[0])
          console.log(
            `${file.uid} compressed (${info.startSizeMB} => ${info.endSizeMB})`
          )
          FirebaseManager.uploadFile(photo.data, filename, onProgress)
            .then((photoURL) => {
              resolve(photoURL)
            })
            .catch((error) => {
              console.warn(error)
              reject(error)
            })

          return {
            abort() {
              console.log('upload progress is aborted.')
            },
          }
        })
        .catch((error) => {
          console.warn('Unable to compress image', error)
        })
    }
  })
}

const UserCreateForm = React.forwardRef(
  (
    {
      agencies,
      advisors,
      currentUserTeamUid,
      currentUserAgencyUid,
      currentUserType,
      currentUserUid,
      createUser,
      editUser,
      editingCurrentUserProfile,
      editing = false,
      finishingRegistration = false,
      editingUser,
      onFinishCallback,
      currentUserName,
      currentUserPhotoUrl,
      currentUserEmail,
      accounts = [],
      teams = [],
      showCard = true,
    },
    ref
  ) => {
    const [form] = Form.useForm()

    const [inlineTeamCreateAgencyUid, setInlineTeamCreateAgencyUid] =
      React.useState(null)
    const [calendlyEvents, setCalendlyEvents] = React.useState(null)
    const [calendlyTokenInfo, setCalendlyTokenInfo] = React.useState(null)
    const [calendlyUser, setCalendlyUser] = React.useState(null)
    const [calendlyButtonsLoading, setCalendlyButtonsLoading] =
      React.useState(false)

    const [logo, setLogo] = React.useState(
      editing ? editingUser?.photoURL : null
    )
    const [calendlyButtonLoading, setCalendlyButtonLoading] =
      React.useState(false)

    const navigate = useNavigate()

    const [loading, setLoading] = React.useState(false)

    React.useImperativeHandle(ref, () => {
      return {
        submitForm() {
          return new Promise((resolve, reject) => {
            form
              .validateFields()
              .then(() => {
                form.submit()
                resolve()
              })
              .catch(reject)
          })
        },
      }
    })

    const [fetchingCalendlyEvents, setFetchingCalendlyEvents] =
      React.useState(false)

    const didFetchCalendlyEvents = React.useRef(false)

    function setPrimaryCalendlyEvent() {
      const selectedEvent = (calendlyEvents || []).find((event) => {
        return event.selected
      })
      if (selectedEvent) {
        setCalendlyButtonsLoading(true)
        editUser(editingUser.uid, {
          primarySchedulingUrl: selectedEvent.scheduling_url,
        })
          .then(() => {
            console.log('Primary Calendly event set!')
            setCalendlyButtonsLoading(false)
            message.success('Primary Calendly event set!')
          })
          .catch((error) => {
            console.warn('Unable to set primary Calendly event', error)
            setCalendlyButtonsLoading(false)
            message.error(
              'Unable to set your primary Calendly event. Please contact the Financial Planning Experience team to get this set up later.'
            )
          })
      } else {
        Modal.warning({
          title: 'Please select a primary Calendly event!',
        })
      }
    }

    const getCalendlyEvents = React.useCallback(
      async (e) => {
        if (e) {
          e?.preventDefault?.()
        }
        if (calendlyUser?.uri) {
          try {
            setFetchingCalendlyEvents(true)
            const Authorization = `Bearer ${calendlyTokenInfo?.access_token}`
            const events = await axios.get(
              `https://api.calendly.com/event_types?count=100&sort=name:asc&user=${calendlyUser?.uri}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization,
                },
              }
            )
            console.log('Calendly events', events.data?.collection || [])
            setCalendlyEvents(events.data?.collection || [])
            setFetchingCalendlyEvents(false)
          } catch (error) {
            setFetchingCalendlyEvents(true)
            console.error('Unable to get calendly events', error)
            message.error('Could not get Calendly events')
          }
        }
      },
      [calendlyTokenInfo?.access_token, calendlyUser?.uri]
    )

    React.useEffect(() => {
      if (
        editingUser?.type === ADVISOR_USER_TYPE &&
        !editingUser?.primarySchedulingUrl &&
        calendlyTokenInfo
      ) {
        if (!didFetchCalendlyEvents.current) {
          didFetchCalendlyEvents.current = true
          getCalendlyEvents()
        }
      }
    }, [
      editingUser?.type,
      editingUser?.primarySchedulingUrl,
      calendlyTokenInfo,
      getCalendlyEvents,
    ])

    function returnAdditionalParamsForUserType(values = {}, photoURL) {
      let type = editing ? editingUser?.type : values.type
      if (!editing && currentUserType === ADVISOR_USER_TYPE) {
        type = CLIENT_USER_TYPE
        values.type = type
      }
      values.sendWelcomeEmail =
        values.sendWelcomeEmail?.checked === undefined
          ? true
          : !!values.sendWelcomeEmail?.checked
      values.shouldRetargetMembers =
        values.shouldRetargetMembers?.checked === undefined
          ? true
          : !!values.shouldRetargetMembers?.checked
      delete values.photoURL
      switch (type) {
        case AGENCY_ADMIN_USER_TYPE:
          return {
            ...values,
            photoURL,
          }
        case ADVISOR_USER_TYPE:
          if (currentUserType === ADMIN_USER_TYPE) {
            return {
              ...values,
              photoURL,
            }
          } else {
            return {
              ...values,
              agencyUid: currentUserAgencyUid,
              teamUid: values.teamUid || currentUserTeamUid,
              photoURL,
            }
          }
        case CLIENT_USER_TYPE:
          const isAdvisor = currentUserType === ADVISOR_USER_TYPE
          if (isAdvisor) {
            values.advisorUid = currentUserUid
          }
          const agencyUid = isAdvisor
            ? currentUserAgencyUid
            : advisors.find((x) => {
                return x.uid === values.advisorUid
              })?.agencyUid
          return {
            ...values,
            agencyUid,
            photoURL,
          }
        default:
          // admin
          return {
            ...values,
            photoURL,
          }
      }
    }

    function onFinishInterceptor(values) {
      if (values.type === CLIENT_USER_TYPE) {
        Modal.confirm({
          title:
            'Please be advised that by adding this party to your Member list, you are verifying that this Member is under an active and signed MMLIS Financial Planning Services Agreement.',
          onOk: () => onFinish(values),
          okText: 'Add Member',
        })
      } else {
        onFinish(values)
      }
    }

    const onFinish = async (values) => {
      setLoading(true)
      const filename = `profile_photo_${editingUser.uid}.jpeg`
      let photoURL
      let shouldContinue = true
      if (logo && logo?.lastModified) {
        try {
          photoURL = await uploadImage({ file: logo, filename })
        } catch (error) {
          shouldContinue = false
          console.warn(error)
          message.error(
            'Unable to create firm with the supplied photo. Please try a different photo.'
          )
          setLoading(false)
        }
      }
      if (shouldContinue) {
        const payload = returnAdditionalParamsForUserType(values, photoURL)
        if (editing) {
          editUser(editingUser.uid, payload)
            .then((/* user */) => {
              message.success('User updated!')
              // console.log('Profile Updated!', user)
              onFinishCallback?.(payload)
            })
            .catch((error) => {
              console.warn(error)
              message.error('Unable to edit user. Please try again later.')
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          console.log('Creating user', payload)
          createUser({
            ...payload,
            invitingUserDisplayName: currentUserName,
            invitingUserPhotoUrl: currentUserPhotoUrl,
            invitingUserEmail: currentUserEmail,
            isCreatedByAdmin: currentUserType === ADMIN_USER_TYPE,
          })
            .then((user) => {
              message.success('User Created!')
              console.log('User created!', user)
              onReset()
              onFinishCallback?.(payload)
              navigate(`/admin/users/${user.uid}`)
            })
            .catch((error) => {
              console.warn(error)
              message.error(
                error.response?.data?.message ||
                  'Unable to create user. Please try again later.'
              )
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }
    }

    const onReset = () => {
      setLogo(null)
      form.resetFields()
    }

    function renderAdvisors() {
      return advisors.map((advisor) => {
        return (
          <Option key={advisor.uid} value={advisor.uid}>
            {advisor.displayName}{' '}
            {advisor?.agency ? `(${advisor?.agency.name})` : ''}
          </Option>
        )
      })
    }

    function renderAgencies() {
      return agencies.map((agency) => {
        return (
          <Option key={agency.uid} value={agency.uid}>
            {agency.name}
          </Option>
        )
      })
    }

    function renderTeamsForAgency(agencyUid) {
      const filteredTeams = (teams || []).filter((team) => {
        return team.agencyUid === agencyUid
      })
      return filteredTeams.map((team) => {
        return (
          <Option key={team.uid} value={team.uid}>
            {team.name}
          </Option>
        )
      })
    }

    function renderUserRoles() {
      const roles = [
        // {
        //   type: ADMIN_USER_TYPE,
        //   name: 'FPE Admin',
        //   allowedRoles: [ADMIN_USER_TYPE],
        // },
        {
          type: AGENCY_ADMIN_USER_TYPE,
          name: 'Firm/Team Admin',
          allowedRoles: [ADMIN_USER_TYPE, AGENCY_ADMIN_USER_TYPE],
        },
        {
          type: ADVISOR_USER_TYPE,
          name: 'Financial Planner',
          allowedRoles: [ADMIN_USER_TYPE, AGENCY_ADMIN_USER_TYPE],
        },
        {
          type: CLIENT_USER_TYPE,
          name: 'Member',
          allowedRoles: [
            ADMIN_USER_TYPE,
            AGENCY_ADMIN_USER_TYPE,
            ADVISOR_USER_TYPE,
          ],
        },
      ]
      return roles
        .map(({ type, name, allowedRoles = [] }) => {
          if (allowedRoles.includes(currentUserType)) {
            return (
              <Option key={`user_role_${type}`} value={type}>
                {name}
              </Option>
            )
          } else {
            return null
          }
        })
        .filter((x) => {
          return x
        })
    }
    function onCalendlySuccess(e) {
      try {
        const params = new URLSearchParams()
        params.append('client_secret', CALENDLY_CLIENT_SECRET)
        params.append('grant_type', 'authorization_code')
        params.append('client_id', CALENDLY_CLIENT_ID)
        params.append('code', e.code)
        params.append('redirect_uri', CALENDLY_REDIRECT_URI)

        fetch('https://auth.calendly.com/oauth/token', {
          method: 'POST',
          body: params,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then((res) => res.json())
          .then((tokenInfo) => {
            setCalendlyTokenInfo(tokenInfo)
            axios
              .get('https://api.calendly.com/users/me', {
                headers: {
                  Authorization: `Bearer ${tokenInfo.access_token}`,
                },
              })
              .then(async (response) => {
                setCalendlyUser(response.data.resource)
                console.log(
                  'Received Calendly user info',
                  response.data.resource
                )
                const events = await axios.get(
                  `https://api.calendly.com/event_types?count=100&sort=name:asc&user=${response.data.resource.uri}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${tokenInfo.access_token}`,
                    },
                  }
                )
                editUser(editingUser.uid, {
                  calendlyUsername: response.data.resource.slug,
                })
                  .then(() => {
                    console.log('Saved user Calendly info')
                    setCalendlyEvents(events.data?.collection || [])
                    setCalendlyButtonLoading(false)
                    message.success('We linked your Calendly account!')
                  })
                  .catch((error) => {
                    console.warn('Unable to save user Calendly info', error)
                    setCalendlyButtonLoading(false)
                    message.error(
                      'Unable to connect your Calendly profile. Please contact the Financial Planning Experience team to get this set up later.'
                    )
                  })
              })
              .catch((error) => {
                console.warn('Unable to get your Calendly user', error)
                setCalendlyButtonLoading(false)
                message.error(
                  'Unable to get your Calendly user. Please contact the Financial Planning Experience team to get this set up later.'
                )
              })
          })
          .catch((error) => {
            console.warn('Unable to authenticate with Calendly', error)
            setCalendlyButtonLoading(false)
            message.error(
              'Unable to authenticate with Calendly. Please contact the Financial Planning Experience team to get this set up later.'
            )
          })
      } catch (error) {
        message.error(
          'Unknown error connecting to Calendly. Please contact the Financial Planning Experience team to get this set up later.'
        )
        console.warn('Unknown error in Calendly Oauth2 flow', error)
        setCalendlyButtonLoading(false)
      }
    }

    function onCalendlyFailure(error) {
      console.warn(error)
      setCalendlyButtonLoading(false)
      message.error(
        'Unable to reach Calendly. Please contact the Financial Planning Experience team to get this set up later.'
      )
    }

    function renderCalendlyConnectButton({ onClick }) {
      return (
        <a
          href="#"
          className="calendly-button"
          onClick={() => {
            setCalendlyButtonLoading(true)
            onClick()
          }}
          loading={calendlyButtonLoading}
        >
          Connect Calendly
        </a>
      )
    }

    function handleSendWelcomeEmailChange(value) {
      if (!value) {
        Modal.warn({
          title: 'Warning',
          content: (
            <div>
              <p>
                Sending a welcome email is the only way a user can get their
                password. This feature is only available for quietly importing
                v1 FPE users into the v2 platform.
              </p>
            </div>
          ),
          onOk() {},
        })
      }
    }

    function handleAddNewTeam(uid) {
      setInlineTeamCreateAgencyUid(uid)
    }

    function getAddressFromAgency(uid) {
      if (!uid) {
        return null
      } else {
        const agency = agencies.find((agency) => {
          return agency.uid === uid
        })
        return agency?.address
      }
    }

    function handleFirmChange(value) {
      const address = getAddressFromAgency(value)
      if (address && !form.getFieldValue(address)) {
        form.setFieldsValue({
          address,
        })
      }
    }

    function handleUserTypeChange(value) {
      form.setFieldsValue({
        address: '',
      })
    }

    function handleCalendlyEventSelection(uri) {
      setCalendlyEvents((prevEvents) => {
        if (prevEvents) {
          prevEvents = prevEvents.map((e) => {
            e.selected = false
            return e
          })
          const event = prevEvents.find((e) => {
            return e.uri === uri
          })
          if (event) {
            if (event.active) {
              event.selected = !event.selected
            }
          }
          return [...prevEvents]
        } else {
          return prevEvents
        }
      })
    }

    return (
      <Card
        style={{
          boxShadow: showCard ? '0 0 10px rgb(0 0 0 / 5%)' : 'none',
          height: finishingRegistration ? '90%' : undefined,
        }}
        bordered={!finishingRegistration}
        bodyStyle={{
          height: finishingRegistration ? '100%' : undefined,
          overflowY: finishingRegistration ? 'scroll' : 'hidden',
          padding: finishingRegistration ? '0 16px 0 0' : undefined,
        }}
        title={
          finishingRegistration
            ? undefined
            : editing
            ? editingCurrentUserProfile
              ? 'Edit Profile'
              : 'Edit User'
            : `Create New ${returnUserTypeBasedOnCurrentUserRole(
                currentUserType
              )}`
        }
      >
        <Col>
          <Form
            {...layout}
            form={form}
            onFinish={onFinishInterceptor}
            scrollToFirstError
            initialValues={{
              type: editing ? editingUser?.type : undefined,
            }}
          >
            {editing ||
            finishingRegistration ||
            currentUserType === ADVISOR_USER_TYPE ? null : (
              <Form.Item
                labelWrap
                labelCol={{ span: 24 }}
                label="User Role"
                name="type"
                initialValue={
                  editing
                    ? editingUser?.type
                    : editingUser?.type === ADVISOR_USER_TYPE
                    ? 'client'
                    : undefined
                }
                rules={[
                  { required: true, message: 'Please provide a user type.' },
                ]}
              >
                <Select
                  onChange={handleUserTypeChange}
                  disabled={currentUserType === ADVISOR_USER_TYPE}
                  placeholder="Select a user role"
                  allowClear
                >
                  {renderUserRoles()}
                </Select>
              </Form.Item>
            )}
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  labelWrap
                  label="First Name"
                  labelCol={{ span: 24 }}
                  initialValue={editing ? editingUser?.firstName : undefined}
                  name="firstName"
                  rules={[
                    {
                      required: editingUser?.type !== ADMIN_USER_TYPE,
                      message: 'Please provide a first name.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  labelWrap
                  label="Last Name"
                  initialValue={editing ? editingUser?.lastName : undefined}
                  labelCol={{ span: 24 }}
                  name="lastName"
                  rules={[
                    {
                      required: editingUser?.type !== ADMIN_USER_TYPE,
                      message: 'Please provide a last name.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              labelWrap
              label="Email"
              labelCol={{ span: 24 }}
              name="email"
              initialValue={editing ? editingUser?.email : undefined}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please provide a valid email.',
                },
              ]}
            >
              <Input disabled={editing || finishingRegistration} />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (
                  prevValues['title'] !== currentValues['title'] ||
                  prevValues['type'] !== currentValues['type']
                )
              }}
            >
              {({ getFieldValue }) => {
                return [ADVISOR_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
                  getFieldValue('type')
                ) ? (
                  <Form.Item
                    name="title"
                    label="Title"
                    initialValue={editing ? editingUser?.title : undefined}
                    labelCol={{ span: 24 }}
                    labelWrap
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }}
            </Form.Item>
            {editing || finishingRegistration ? null : (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues['type'] !== currentValues['type']
                }}
              >
                {({ getFieldValue }) => {
                  return [ADVISOR_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
                    getFieldValue('type')
                  ) && currentUserType === ADMIN_USER_TYPE ? (
                    <Form.Item
                      name="agencyUid"
                      label="Firm"
                      labelCol={{ span: 24 }}
                      labelWrap
                      rules={[
                        { required: true, message: 'Please select a firm.' },
                      ]}
                    >
                      <Select
                        onChange={handleFirmChange}
                        placeholder="Select a firm"
                        allowClear
                      >
                        {renderAgencies()}
                      </Select>
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            )}
            {[ADVISOR_USER_TYPE, ADMIN_USER_TYPE].includes(
              editingUser?.type
            ) ? (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues['type'] !== currentValues['type'] ||
                    prevValues['agencyUid'] !== currentValues['agencyUid']
                  )
                }}
              >
                {({ getFieldValue }) => {
                  return currentUserType === ADMIN_USER_TYPE ||
                    [ADVISOR_USER_TYPE, AGENCY_ADMIN_USER_TYPE].includes(
                      getFieldValue('type')
                    ) ? (
                    <Form.Item
                      name="teamUid"
                      label="Team"
                      labelCol={{ span: 24 }}
                      initialValue={editing ? editingUser?.teamUid : undefined}
                      labelWrap
                    >
                      <Select
                        disabled={
                          currentUserType !== ADMIN_USER_TYPE &&
                          !getFieldValue('agencyUid') &&
                          !currentUserAgencyUid
                        }
                        placeholder="Select a team"
                        allowClear
                        dropdownRender={(menu) => (
                          <>
                            <Space
                              align="center"
                              style={{ padding: '5px 10px 7px 10px' }}
                            >
                              <Typography.Link
                                onClick={() => {
                                  handleAddNewTeam(getFieldValue('agencyUid'))
                                }}
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <PlusOutlined /> Add Team
                              </Typography.Link>
                            </Space>
                            <Divider style={{ margin: 0 }} />
                            {menu}
                          </>
                        )}
                      >
                        {renderTeamsForAgency(
                          getFieldValue('agencyUid') ||
                            editingUser?.agencyUid ||
                            currentUserAgencyUid
                        )}
                      </Select>
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            ) : null}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues['type'] !== currentValues['type']
                  }}
                >
                  {({ getFieldValue }) => {
                    return (editing &&
                      editingUser?.type === ADVISOR_USER_TYPE) ||
                      getFieldValue('type') === ADVISOR_USER_TYPE ? (
                      <Form.Item
                        name="officePhoneNumber"
                        label="Office Phone"
                        initialValue={
                          editing ? editingUser?.officePhoneNumber : undefined
                        }
                        labelCol={{ span: 24 }}
                        labelWrap
                        rules={[
                          {
                            required: editing || finishingRegistration,
                            message: 'Please enter an office phone number.',
                          },
                        ]}
                      >
                        <Input addonBefore="+1" />
                      </Form.Item>
                    ) : null
                  }}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues['type'] !== currentValues['type']
                  }}
                >
                  {({ getFieldValue }) => {
                    return (editing &&
                      editingUser?.type === ADVISOR_USER_TYPE) ||
                      getFieldValue('type') === ADVISOR_USER_TYPE ? (
                      <Form.Item
                        name="cellPhoneNumber"
                        initialValue={
                          editing ? editingUser?.cellPhoneNumber : undefined
                        }
                        label="Cell Phone"
                        labelCol={{ span: 24 }}
                        labelWrap
                      >
                        <Input addonBefore="+1" />
                      </Form.Item>
                    ) : null
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['type'] !== currentValues['type']
              }}
            >
              {({ getFieldValue }) => {
                return (editing && editingUser?.type === ADVISOR_USER_TYPE) ||
                  getFieldValue('type') === ADVISOR_USER_TYPE ? (
                  <Form.List
                    name="accounts"
                    noStyle
                    initialValue={editing ? editingUser?.accounts : undefined}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Row
                            style={{
                              backgroundColor: 'rgba(0,0,0,0.025)',
                              padding: '12px 24px 24px 24px',
                              borderRadius: 2,
                              marginBottom: 24,
                            }}
                            gutter={16}
                          >
                            <Col xs={24} sm={12}>
                              <Form.Item
                                {...field}
                                label="Account Type"
                                labelCol={{ span: 24 }}
                                labelWrap
                                name={[field.name, 'type']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select an account type.',
                                  },
                                ]}
                              >
                                <Select>
                                  {(accounts || [])?.map?.((item) => {
                                    return (
                                      <Option
                                        key={item.name}
                                        value={item.name}
                                      />
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                {...field}
                                label="Account URL"
                                labelCol={{ span: 24 }}
                                labelWrap
                                name={[field.name, 'url']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter a valid URL.',
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col
                              span={24}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                type="dashed"
                                danger
                                onClick={() => remove(field.name)}
                                block
                                icon={<MinusCircleOutlined />}
                              >
                                Remove Account
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        {fields?.length === 0 ? (
                          <Alert
                            message="Member platform links appear as bookmarks on their
                          home screen."
                          />
                        ) : null}
                        <Form.Item colon={false} style={{ marginTop: 12 }}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Member Platform Link
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ) : null
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['type'] !== currentValues['type']
              }}
            >
              {({ getFieldValue }) => {
                return (editing && editingUser?.type === ADVISOR_USER_TYPE) ||
                  getFieldValue('type') === ADVISOR_USER_TYPE ? (
                  <Form.Item
                    name="website"
                    initialValue={editing ? editingUser?.website : undefined}
                    label="Website"
                    labelCol={{ span: 24 }}
                    labelWrap
                    // rules={[
                    //   {
                    //     required: finishingRegistration,
                    //     message: 'Please add a website URL.',
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (
                  prevValues['type'] !== currentValues['type'] ||
                  prevValues['agencyUid'] !== currentValues['agencyUid']
                )
              }}
            >
              {({ getFieldValue }) => {
                return (editing && editingUser?.type === ADVISOR_USER_TYPE) ||
                  getFieldValue('type') === ADVISOR_USER_TYPE ? (
                  <Form.Item
                    name="address"
                    label="Address"
                    initialValue={editing ? editingUser?.address : undefined}
                    labelCol={{ span: 24 }}
                    labelWrap
                    rules={[
                      {
                        required: editing || finishingRegistration,
                        message:
                          'Please add a mailing address. This is likely the same address as your firm.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }}
            </Form.Item>
            {currentUserType === ADMIN_USER_TYPE ? (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues['type'] !== currentValues['type']
                }}
              >
                {({ getFieldValue }) => {
                  return (editing && editingUser?.type === ADVISOR_USER_TYPE) ||
                    getFieldValue('type') === ADVISOR_USER_TYPE ? (
                    <Form.Item
                      name="calendlyUsername"
                      label="Calendly Username"
                      initialValue={
                        editing ? editingUser?.calendlyUsername : undefined
                      }
                      labelCol={{ span: 24 }}
                      labelWrap
                    >
                      <Input />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            ) : null}
            {currentUserType === ADMIN_USER_TYPE ? (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues['type'] !== currentValues['type']
                }}
              >
                {({ getFieldValue }) => {
                  return (editing && editingUser?.type === ADVISOR_USER_TYPE) ||
                    getFieldValue('type') === ADVISOR_USER_TYPE ? (
                    <Form.Item
                      name="primarySchedulingUrl"
                      label="Calendly Url"
                      initialValue={
                        editing ? editingUser?.primarySchedulingUrl : undefined
                      }
                      labelCol={{ span: 24 }}
                      labelWrap
                    >
                      <Input />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            ) : null}
            {editing ? null : (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues['type'] !== currentValues['type']
                }}
              >
                {({ getFieldValue }) => {
                  return (editing && editingUser?.type === CLIENT_USER_TYPE) ||
                    getFieldValue('type') === CLIENT_USER_TYPE ? (
                    <Form.Item
                      name="advisorUid"
                      label="Financial Planner"
                      labelCol={{ span: 24 }}
                      labelWrap
                      initialValue={
                        currentUserType === ADVISOR_USER_TYPE
                          ? currentUserUid
                          : undefined
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Please select a financial planner.',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a financial planner."
                        allowClear
                        disabled={currentUserType === ADVISOR_USER_TYPE}
                      >
                        {renderAdvisors()}
                      </Select>
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            )}
            {editingUser?.type === ADVISOR_USER_TYPE &&
            editing &&
            !finishingRegistration ? (
              <>
                <div>
                  Calendly:
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: editingUser?.primarySchedulingUrl
                        ? '#7cb305'
                        : '#cf1322',
                    }}
                  >
                    {' '}
                    {editingUser?.primarySchedulingUrl
                      ? 'Connected'
                      : 'Disconnected'}
                  </span>
                </div>
                {editingUser?.primarySchedulingUrl ? (
                  <Input
                    style={{ marginBottom: 20 }}
                    disabled
                    value={editingUser?.primarySchedulingUrl}
                  />
                ) : calendlyTokenInfo ? (
                  <div>
                    <CalendlyEventList
                      compact
                      loading={fetchingCalendlyEvents}
                      events={calendlyEvents}
                      handleCalendlyEventSelection={
                        handleCalendlyEventSelection
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 20,
                      }}
                    >
                      <Button
                        loading={
                          calendlyButtonsLoading || fetchingCalendlyEvents
                        }
                        onClick={getCalendlyEvents}
                      >
                        Refresh Events
                      </Button>
                      <Button
                        type="primary"
                        style={{ marginLeft: 10 }}
                        loading={
                          calendlyButtonsLoading || fetchingCalendlyEvents
                        }
                        onClick={setPrimaryCalendlyEvent}
                      >
                        Set Primary Event
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: 7.5,
                      marginBottom: 20,
                    }}
                  >
                    <OAuth2Login
                      authorizationUrl="https://auth.calendly.com/oauth/authorize"
                      responseType="code"
                      render={renderCalendlyConnectButton}
                      clientId={CALENDLY_CLIENT_ID}
                      redirectUri={CALENDLY_REDIRECT_URI}
                      onSuccess={onCalendlySuccess}
                      onFailure={onCalendlyFailure}
                    />
                  </div>
                )}
              </>
            ) : null}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues['type'] !== currentValues['type']
              }}
            >
              {({ getFieldValue }) => {
                if (
                  (!getFieldValue('type') ||
                    getFieldValue('type') === CLIENT_USER_TYPE) &&
                  !(
                    editing &&
                    ![CLIENT_USER_TYPE, ADMIN_USER_TYPE].includes(
                      editingUser?.type
                    )
                  )
                ) {
                  return null
                }

                return (
                  <Form.Item
                    name="photoURL"
                    label="Profile Photo"
                    initialValue={editing ? editingUser?.photoURL : undefined}
                    labelCol={{ span: 24 }}
                    labelWrap
                    rules={[
                      (
                        {
                          /* getFieldValue */
                        }
                      ) => ({
                        required:
                          ![CLIENT_USER_TYPE, ADMIN_USER_TYPE].includes(
                            currentUserType
                          ) &&
                          (editing || finishingRegistration),
                        validator(/* _, value */) {
                          if (
                            (!editing && !finishingRegistration) ||
                            logo ||
                            currentUserType === CLIENT_USER_TYPE
                          ) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please add a profile photo.')
                          )
                        },
                      }),
                    ]}
                  >
                    <ImgCrop modalTitle="Crop Photo">
                      <Upload
                        accept="image/*"
                        onRemove={() => {
                          setLogo(null)
                        }}
                        listType="picture-card"
                        valuePropName="fileList"
                        beforeUpload={(file) => {
                          file.thumbUrl = URL.createObjectURL(file)
                          setLogo(file)
                          form.validateFields(['photoURL'])
                          return false
                        }}
                        disabled={loading}
                        fileList={
                          logo
                            ? [
                                {
                                  uid: '-1',
                                  name: logo.name,
                                  status: 'done',
                                  url: logo,
                                  thumbUrl: logo.thumbUrl,
                                },
                              ]
                            : []
                        }
                      >
                        {logo ? null : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                )
              }}
            </Form.Item>
            {currentUserType !== ADMIN_USER_TYPE ? null : (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues['sendWelcomeEmail'] !==
                    currentValues['sendWelcomeEmail']
                  )
                }}
              >
                {() => {
                  return currentUserType === ADMIN_USER_TYPE && !editing ? (
                    <Form.Item
                      name="sendWelcomeEmail"
                      label="Send Welcome Email"
                      labelCol={{ span: 24 }}
                      tooltip="This controls whether welcome emails will be sent to this user. This is useful for onboarding users from the FPE v1 platform."
                      initialValue={{
                        checked: true,
                      }}
                      valuePropName="checked"
                      labelWrap
                    >
                      <Switch onChange={handleSendWelcomeEmailChange} />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            )}
            {currentUserType === ADVISOR_USER_TYPE && editing ? (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues['shouldRetargetMembers'] !==
                    currentValues['shouldRetargetMembers']
                  )
                }}
              >
                {({ getFieldValue }) => {
                  return getFieldValue('type') === ADVISOR_USER_TYPE ? (
                    <Form.Item
                      name="shouldRetargetMembers"
                      label="Would you like to automatically send re-target emails to members who have not engaged with new quarter content?"
                      labelCol={{ span: 24 }}
                      tooltip="These are automated, personalized emails to re-engage with members who have not previously opened this current quarter's content emails."
                      initialValue={{
                        checked:
                          editingUser?.sendRetargetEmails === undefined
                            ? true
                            : editingUser?.sendRetargetEmails,
                      }}
                      valuePropName="checked"
                      labelWrap
                    >
                      <Switch />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            ) : null}
            {finishingRegistration ? null : (
              <Form.Item {...tailLayout}>
                <Space>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button loading={loading} htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            )}
          </Form>
        </Col>
        <Modal
          visible={inlineTeamCreateAgencyUid}
          centered
          onCancel={() => setInlineTeamCreateAgencyUid(null)}
          destroyOnClose
          footer={null}
          width={'75%'}
          height={'75%'}
          style={{ padding: 0, height: '100%' }}
          bodyStyle={{ padding: 0, height: '100%' }}
        >
          <TeamCreateForm
            closeInlineAgencyCreateModal={(newTeamUid) => {
              form.setFieldsValue({ teamUid: newTeamUid })
              setInlineTeamCreateAgencyUid(null)
            }}
            inlineAgencyUid={inlineTeamCreateAgencyUid}
          />
        </Modal>
      </Card>
    )
  }
)

const mapDispatchToProps = {
  createUser,
  editUser,
}

function mapStateToProps(
  {
    session: { user, accounts },
    agencies = {},
    teams: { data: teamsData },
    users = {},
  },
  { user: editingUser }
) {
  const advisors = users.data?.filter?.((user) => {
    return user.type === ADVISOR_USER_TYPE
  })
  return {
    editingCurrentUserProfile: editingUser?.uid === user.uid,
    accounts,
    editingUser: editingUser || user?.attributes,
    currentUserType: user.attributes.type,
    currentUserTeamUid: user.attributes.teamUid,
    currentUserName: user.attributes.displayName,
    currentUserPhotoUrl: user.photoURL,
    currentUserEmail: user.email,
    currentUserUid: user.attributes.uid,
    currentUserAgencyUid: user.attributes.agencyUid,
    agencies: agencies.data || [],
    advisors: advisors || [],
    teams: teamsData || [],
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserCreateForm)
