import { produce } from 'immer'

import {
  SET_USERS_SUCCESS,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  EDIT_USER_BEGIN,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  HANDLE_DELETE_USERS_SUCCESS,
} from './types'

const INITIAL_STATE = {
  data: [],
  loading: false,
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case HANDLE_DELETE_USERS_SUCCESS:
      const map = {}
      action.payload?.forEach?.((user) => {
        map[user?.uid] = user
      })
      draft.data = draft.data.filter((user) => {
        return !map[user?.uid]
      })
      return draft
    case SET_USERS_SUCCESS:
      draft.data = action.payload
      return draft
    case CREATE_USER_BEGIN:
      return draft
    case CREATE_USER_SUCCESS:
      if (action.payload) {
        draft.data.push(action.payload)
      }
      return draft
    case CREATE_USER_ERROR:
      return draft
    case DELETE_USER_BEGIN:
      return draft
    case DELETE_USER_SUCCESS:
      draft.data = draft.data.filter((user) => {
        return user.uid !== action.payload
      })
      return draft
    case DELETE_USER_ERROR:
      return draft
    case EDIT_USER_BEGIN:
      return draft
    case EDIT_USER_SUCCESS:
      const index = draft.data.findIndex((user) => {
        return user.uid === action.payload.uid
      })
      if (index !== -1) {
        draft.data[index] = action.payload
      } else {
        console.warn('Could not find a user to edit')
      }
      return draft
    case EDIT_USER_ERROR:
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
