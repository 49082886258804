import React from 'react'

import {
  UserOutlined,
  MenuOutlined,
  DatabaseOutlined,
  SettingFilled,
  ShareAltOutlined,
  HomeOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Avatar, Layout, Menu, message, Popover } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride'
import { connect } from 'react-redux'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { persistor } from '../../bootstrap/redux'
import { setCurrentUserOnboarding } from '../../features/session/redux/actions'
import { fetchAllAppData } from '../../features/session/redux/operations'
import {
  ADMIN_USER_TYPE,
  ADVISOR_USER_TYPE,
  AGENCY_ADMIN_USER_TYPE,
} from '../../features/session/redux/types'
import { editUser } from '../../features/users/redux/operations'
import GetHelp from '../../shared/components/GetHelp'
import { returnUserTypeBasedOnCurrentUserRole } from '../../shared/helpers/returnUserTypeBasedOnCurrentUserRole'
import usePrimaryColor from '../../shared/hooks/usePrimaryColor'
import AdminAnalytics from './components/AdminAnalytics'
import AdminTools from './components/AdminTools'
import AdvisorOnboardingCarousel from './components/AdvisorOnboardingCarousel'
import AgenciesView from './components/AgenciesView'
import AgencyCreateForm from './components/AgencyCreateForm'
import AgencyDetail from './components/AgencyDetail'
import AgencyEditForm from './components/AgencyEditForm'
import Analytics from './components/Analytics'
import EditProfile from './components/EditProfile'
import EditUser from './components/EditUser'
import ImportUsersForm from './components/ImportUsersForm'
import MarketingMaterials from './components/MarketingMaterials'
import QuarterDetail from './components/QuarterDetail'
import QuartersView from './components/QuartersView'
import RemoveUsers from './components/RemoveUsers'
import SyncMailchimp from './components/SyncMailchimp'
// import Settings from './components/Settings'
import TeamAddForm from './components/TeamAddForm'
import TeamCreateForm from './components/TeamCreateForm'
import TeamDetail from './components/TeamDetail'
import TeamEditForm from './components/TeamEditForm'
import UserCreateForm from './components/UserCreateForm'
import UserDetail from './components/UserDetail'
import UserStatus from './components/UserStatus'
import UsersView from './components/UsersView'

const LOGO_SOURCE = '/cfg.png'

function renderStepContent(title, description) {
  return (
    <div>
      <h3>{title}</h3>
      {description ? <p>{description}</p> : null}
    </div>
  )
}

const defaultSteps = [
  {
    content: renderStepContent("Let's give you a quick tour 👀"),
    locale: { skip: <strong aria-label="skip">SKIP</strong> },
    placement: 'center',
    target: 'body',
    disableBeacon: true,
  },
  {
    target: '#sub_menu_users',
    content: renderStepContent(
      'Members List',
      'Click here to manage your members list.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_content',
    content: renderStepContent(
      'Conversations',
      "This section houses the current and upcoming quarter's conversations. When prompted by the Financial Planning Experience team to do so, you may add your Welcome Video here as well."
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_analytics',
    content: renderStepContent(
      'Analytics',
      'Are your clients engaged in the platform? View the analytics for the emails your clients are receiving.'
    ),
    disableBeacon: true,
  },
  {
    target: '#menu_item_marketing',
    content: renderStepContent(
      'Marketing Materials',
      'Here you will find the pre-, during, and post-client meeting materials to prepare you for the conversations you will be having each quarter.'
    ),
    disableBeacon: true,
  },
]

const routes = [
  {
    path: '/users',
    name: 'Users',
    Component: UsersView,
    breadcrumbName: 'All User',
  },
  {
    path: '/users/create',
    name: 'Create User',
    Component: UserCreateForm,
    breadcrumbName: 'Create User',
  },
  {
    path: '/users/import',
    name: 'Bulk Import Users',
    Component: ImportUsersForm,
    breadcrumbName: 'Bulk Import Users',
  },
  {
    path: '/help',
    name: 'Get Help',
    Component: GetHelp,
    breadcrumbName: 'Get Help',
  },
  {
    path: '/users/:id',
    name: 'User Detail',
    Component: UserDetail,
    breadcrumbName: 'User Detail',
  },
  {
    path: '/users/:id/edit',
    name: 'User Edit',
    Component: EditUser,
    breadcrumbName: 'User Edit',
  },
  {
    path: '/marketing',
    name: 'Marketing',
    Component: MarketingMaterials,
    breadcrumbName: 'Marketing',
  },
  {
    path: '/editProfile',
    name: 'Edit Profile',
    Component: EditProfile,
    breadcrumbName: 'Edit Profile',
  },
  {
    path: '/firms',
    name: 'Firms',
    Component: AgenciesView,
    breadcrumbName: 'All Firms',
  },
  {
    path: '/firms/:uid/edit',
    name: 'Edit Firm',
    Component: AgencyEditForm,
    breadcrumbName: 'Edit Firm',
  },
  {
    path: '/firms/create',
    name: 'Create Firm',
    Component: AgencyCreateForm,
    breadcrumbName: 'Create Firm',
  },
  {
    path: '/firms/:id',
    name: 'Firm Detail',
    Component: AgencyDetail,
    breadcrumbName: 'Firm Detail',
  },
  {
    path: '/firms/:id/teams/create',
    name: 'Create Firm Team',
    Component: TeamCreateForm,
    breadcrumbName: 'Update Team',
  },
  {
    path: '/firms/:id/teams/:teamId/add',
    name: 'Add Firm Team',
    Component: TeamAddForm,
    breadcrumbName: 'Create Team',
  },
  {
    path: '/firms/:id/teams/:teamId/edit',
    name: 'Edit Firm Team',
    Component: TeamEditForm,
    breadcrumbName: 'Edit Team',
  },
  {
    path: '/firms/:id/teams/:teamId',
    name: 'Team Detail',
    Component: TeamDetail,
    breadcrumbName: 'Team Detail',
  },
  {
    path: '/content',
    name: 'Content',
    Component: QuartersView,
    breadcrumbName: 'Content',
  },
  {
    path: '/content/:uid',
    name: 'Quarter Detail',
    Component: QuarterDetail,
    breadcrumbName: 'Quarter Detail',
  },
  {
    path: '/analytics',
    name: 'Analytics',
    Component: Analytics,
    breadcrumbName: 'Analytics',
  },
  {
    path: '/tools',
    name: 'Admin Tools',
    Component: AdminTools,
    breadcrumbName: 'Admin Tools',
  },
  {
    path: '/user-analytics',
    name: 'Admin Analytics',
    Component: AdminAnalytics,
    breadcrumbName: 'Admin Analytics',
  },
  {
    path: '/tools/mailchimp-sync',
    name: 'Sync Mailchimp',
    Component: SyncMailchimp,
    breadcrumbName: 'Sync Mailchimp',
  },
  {
    path: '/tools/remove-users',
    name: 'Remove Users',
    Component: RemoveUsers,
    breadcrumbName: 'Remove Users',
  },
  {
    path: '/users/status',
    name: 'User Status',
    Component: UserStatus,
    breadcrumbName: 'User Status',
  },
]

const { Content, Sider } = Layout

function AdminDashboard({
  currentUser,
  fetchAllAppData,
  setCurrentUserOnboarding,
  editUser,
  agency,
  team,
  advisorVideo,
}) {
  const [collapsed, setCollapsed] = React.useState(false)

  const [showSecondaryOnboarding, setShowSecondaryOnboarding] =
    React.useState(false)
  const [stepIndex, setStepIndex] = React.useState(0)

  const [onboardingModalVisible, setOnboardingModalVisible] =
    React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    fetchAllAppData()
  }, [])

  React.useEffect(() => {
    if (
      currentUser?.attributes &&
      !currentUser?.attributes?.onboarded &&
      currentUser?.attributes?.type === ADVISOR_USER_TYPE
    ) {
      setOnboardingModalVisible(true)
    }
  }, [currentUser])

  function closeModal(e) {
    e?.preventDefault()
    setOnboardingModalVisible(false)
    if (
      currentUser?.attributes?.type === ADVISOR_USER_TYPE ||
      currentUser?.attributes?.type === AGENCY_ADMIN_USER_TYPE
    ) {
      setShowSecondaryOnboarding(true)
    }
    editUser(currentUser?.uid, {
      onboarded: true,
    }).catch((error) => {
      console.warn('Could not set current user', error)
    })
  }

  function logout() {
    persistor.purge()
  }

  function renderLogo() {
    if (currentUser?.attributes?.type === ADMIN_USER_TYPE) {
      return (
        <div className="logo" style={{ color: 'rgba(0,0,0,0.85)' }}>
          FPE ADMIN
        </div>
      )
    } else if (currentUser?.attributes?.type === ADVISOR_USER_TYPE && team) {
      return (
        <div className="logo">
          <img
            src={team?.logoUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )
    } else if (agency?.logoUrl) {
      return (
        <div className="logo">
          <img
            src={agency?.logoUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )
    } else {
      return (
        <div className="logo">
          <img
            src={LOGO_SOURCE}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      )
    }
  }

  function handleJoyrideCallback(data) {
    const { action, type, status, index } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setStepIndex(0)
      setShowSecondaryOnboarding(false)
      navigate('/')
      message.success("You've completed onboarding!")
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      console.log('next index', stepIndex)
      setStepIndex(stepIndex)
      switch (stepIndex) {
        case 0:
        case 1:
          break
        case 2:
          navigate('/admin/content')
          break
        case 3:
          navigate('/admin/analytics')
          break
        case 4:
          navigate('/admin/marketing')
          break
        default:
          // do nothing
          break
      }
    }
  }

  function renderMyFirm() {
    if (
      [AGENCY_ADMIN_USER_TYPE, ADVISOR_USER_TYPE].includes(
        currentUser?.attributes?.type
      ) &&
      currentUser?.attributes?.agencyUid &&
      currentUser?.attributes?.teamUid
    ) {
      return {
        label: 'My Team',
        id: 'menu_item_my_team',
        icon: <HomeOutlined />,
        onClick: () =>
          navigate(
            `/admin/firms/${agency?.uid}/teams/${currentUser?.attributes?.teamUid}`
          ),
        key: '/admin/firms/:id/teams/:teamUid',
      }
    } else if (currentUser?.attributes?.type !== ADMIN_USER_TYPE) {
      return {
        label: 'My Firm',
        id: 'menu_item_my_firm',
        icon: <HomeOutlined />,
        onClick: () => navigate(`/admin/firms/${agency?.uid}`),
        key: '/admin/firms/:id',
      }
    } else {
      return null
    }
  }

  const menuItems = [
    {
      label: `${returnUserTypeBasedOnCurrentUserRole(
        currentUser?.attributes?.type
      )}s`,
      id: 'sub_menu_users',
      key: 'users',
      icon: <UserOutlined />,
      children: [
        {
          label: `Create ${returnUserTypeBasedOnCurrentUserRole(
            currentUser?.attributes?.type
          )}`,
          onClick: () => navigate('/admin/users/create'),
          key: '/admin/users/create',
        },
        // {
        //   label: `Bulk Import ${returnUserTypeBasedOnCurrentUserRole(
        //     currentUser?.attributes?.type
        //   )}s`,
        //   onClick: () => navigate('/admin/users/import'),
        //   key: '/admin/users/import',
        // },
        {
          label: `View All ${returnUserTypeBasedOnCurrentUserRole(
            currentUser?.attributes?.type
          )}s`,
          onClick: () => navigate('/admin/users'),
          key: '/admin/users',
        },
        currentUser?.attributes?.type === ADMIN_USER_TYPE
          ? {
              label: `User Debug View`,
              onClick: () => navigate('/admin/users/status'),
              key: '/admin/users/status',
            }
          : null,
      ].filter((x) => x),
    },
    {
      label: 'Firms',
      key: 'firms',
      icon: <TeamOutlined />,
      children: [
        {
          label: 'Create Firm',
          onClick: () => navigate('/admin/firms/create'),
          key: '/admin/firms/create',
        },
        {
          label: 'View All Firms',
          onClick: () => navigate('/admin/firms'),
          key: '/admin/firms',
        },
      ],
    },
    // agency?.uid &&
    //   team?.uid &&
    //   currentUser?.attributes?.type === ADVISOR_USER_TYPE && {
    //     label: 'My Team',
    //     id: 'menu_item_team',
    //     icon: <TeamOutlined />,
    //     onClick: () =>
    //       navigate(`/admin/firms/${agency?.uid}/teams/${team?.uid}`),
    //     key: '/admin/firms/:id/teams/:teamId',
    //   },
    renderMyFirm(),
    {
      label: (
        <span>
          <span>Conversations</span>
          {!advisorVideo &&
          currentUser?.attributes?.type === ADVISOR_USER_TYPE ? (
            <ExclamationCircleOutlined
              style={{ marginLeft: 7, color: 'red' }}
            />
          ) : null}
        </span>
      ),
      id: 'menu_item_content',
      icon: <CalendarOutlined />,
      onClick: () => navigate('/admin/content'),
      key: '/admin/content',
    },
    {
      label: 'Analytics',
      id: 'menu_item_analytics',
      icon: <DatabaseOutlined />,
      onClick: () => navigate('/admin/analytics'),
      key: '/admin/analytics',
    },
    {
      label: 'Marketing Materials',
      id: 'menu_item_marketing',
      icon: <ShareAltOutlined />,
      onClick: () => navigate('/admin/marketing'),
      key: '/admin/marketing',
    },
    currentUser?.attributes?.type === ADMIN_USER_TYPE
      ? {
          label: 'Admin Tools',
          id: 'tools',
          icon: <SettingFilled />,
          onClick: () => navigate('/admin/tools'),
          key: '/admin/tools',
        }
      : null,
  ].filter((x) => {
    if (x) {
      if (['firms', '/admin/tools'].includes(x.key)) {
        return currentUser?.attributes?.type === ADMIN_USER_TYPE
      } else {
        return true
      }
    } else {
      return false
    }
  })

  function returnDefaultSelectedKeys() {
    const keys = []

    menuItems.forEach((item) => {
      if (location.pathname === item.key) {
        keys.push(item.key)
      }
      if (item.children?.length) {
        item.children.forEach((subItem) => {
          if (location.pathname === subItem.key) {
            keys.push(subItem.key)
          }
        })
      }
    })
    return keys
  }

  const primaryColor = usePrimaryColor()

  function takeATour(e) {
    e.preventDefault()
    setCurrentUserOnboarding(false)
    setTimeout(() => {
      setShowSecondaryOnboarding(true)
    }, 500)
  }

  return (
    <Layout>
      <Joyride
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        run={showSecondaryOnboarding}
        steps={defaultSteps}
        showProgress
        showSkipButton
        disableOverlayClose
        continuous
        disableScrolling
        styles={{
          options: {
            primaryColor: primaryColor,
            overlayColor: `rgba(0,0,0,0.1)`,
          },
        }}
      />
      <Sider
        trigger={
          <div style={{ backgroundColor: primaryColor }}>
            <MenuOutlined color="white" />
          </div>
        }
        breakpoint="md"
        collapsedWidth="0"
        onBreakpoint={setCollapsed}
      >
        {renderLogo()}
        <div
          className="sider-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Menu
            triggerSubMenuAction="click"
            theme="light"
            defaultSelectedKeys={returnDefaultSelectedKeys()}
            mode="vertical"
            overflowedIndicator={null}
            items={menuItems}
          />
          <div
            style={{
              // backgroundColor: '#B4C2C4',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: 10,
              paddingBottom: 13,
            }}
          >
            <div style={{ color: 'grey', fontSize: 12 }}>POWERED BY</div>
            <img
              src="/logo.png"
              style={{
                width: '90%',
                objectFit: 'contain',
              }}
            />
          </div>
        </div>
      </Sider>
      <Content>
        <Header
          breadcrumb={{ routes }}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Popover
            placement="bottomRight"
            title={<strong>{currentUser?.attributes?.displayName}</strong>}
            trigger="hover"
            content={
              <div>
                {/* <div>
                  <Link to="/admin/profile">View Profile</Link>
                </div> */}
                <div>
                  <Link to="/admin/editProfile">View Profile</Link>
                </div>
                {currentUser?.attributes?.type === ADMIN_USER_TYPE ? null : (
                  <a href="" onClick={takeATour}>
                    Take a Tour
                  </a>
                )}
                {/* <PermissionHOC
                  allowedRoles={[AGENCY_ADMIN_USER_TYPE, ADVISOR_USER_TYPE]}
                >
                  <div>
                    <Link to="/admin/settings">Settings</Link>
                  </div>
                </PermissionHOC> */}
                <div>
                  <Link to="/admin/help">Get Help</Link>
                </div>
                <div>
                  <Link onClick={logout} to="/login">
                    Logout
                  </Link>
                </div>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                right: 15,
              }}
            >
              <h3
                style={{
                  cursor: 'pointer',
                  color: primaryColor,
                  margin: 0,
                  paddingRight: 10,
                }}
              >
                My Account
              </h3>
              <Avatar
                src={currentUser?.photoURL || currentUser?.attributes?.photoURL}
              >
                {currentUser?.attributes?.firstName?.[0]}
                {currentUser?.attributes?.lastName?.[0]}
              </Avatar>
            </div>
          </Popover>
        </Header>
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            timeout={250}
            unmountOnExit
          >
            <Routes location={location}>
              {routes.map(({ path, Component }) => {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <div
                        className="quarter-with-sider"
                        style={{ minWidth: collapsed ? '100vw' : undefined }}
                      >
                        <Component />
                      </div>
                    }
                  />
                )
              })}
              <Route
                path="*"
                element={
                  <div
                    className="quarter-with-sider"
                    style={{ minWidth: collapsed ? '100vw' : undefined }}
                  >
                    <UsersView />
                  </div>
                }
              />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </Content>
      <Modal
        visible={onboardingModalVisible}
        centered
        closable={false}
        maskClosable={false}
        footer={null}
        width={'75%'}
        height={'75%'}
        style={{ padding: 0, height: '100%' }}
        bodyStyle={{ padding: 0, height: '100%' }}
      >
        <AdvisorOnboardingCarousel onClose={closeModal} />
      </Modal>
    </Layout>
  )
}
function mapStateToProps({
  session: { user },
  agencies: { data },
  teams: { data: teamsData },
  quarters = {},
}) {
  const agency = data.find((agency) => {
    return agency.uid === user?.attributes?.agencyUid
  })
  const team = teamsData.find((team) => {
    return team.uid === user?.attributes?.teamUid
  })
  const advisorVideo = (
    quarters?.activeQuarterMetadata?.advisorVideos || []
  ).find((video) => {
    return video.advisorUid?.toLowerCase?.() === user?.uid?.toLowerCase?.()
  })
  return {
    agency,
    team,
    advisorVideo,
    currentUser: user,
  }
}

const mapDispatchToProps = {
  fetchAllAppData,
  setCurrentUserOnboarding,
  editUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
