import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import {
  Alert,
  Card,
  message,
  Row,
  Spin,
  Button,
  Select,
  ConfigProvider,
} from 'antd'
import { connect } from 'react-redux'

import { ADVISOR_USER_TYPE } from '../../../../features/session/redux/types'
import FirebaseManager from '../../../../FirebaseManager'
import FPEEmptyState from '../../../../shared/components/FPEEmptyState'
import IndividualEmailAnalytics from '../../../../shared/components/IndividualEmailAnalytics'
import { returnUserTypeBasedOnCurrentUserRole } from '../../../../shared/helpers/returnUserTypeBasedOnCurrentUserRole'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function Analytics({ currentUser, groupedAdvisors }) {
  const [loading, setLoading] = React.useState(true)
  const [generating, setGenerating] = React.useState(false)
  const [uid, setUid] = React.useState(
    currentUser.attributes.type === 'admin' ? null : currentUser.uid
  )
  const [emailAnalytics, setEmailAnalytics] = React.useState([])

  const getReports = React.useCallback(() => {
    setGenerating(true)
    FirebaseManager.getEmailAnalyticsForClients(uid)
      .then((response) => {
        setEmailAnalytics(
          (response || []).filter((x) => {
            const name = x.metadata.name || ''
            return !!name && !name?.includes('Advisor V2 Transition')
          })
        )
      })
      .catch((error) => {
        message.error('Could not load email analytics')
        console.warn(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [uid])

  function renderData() {
    return emailAnalytics.map((item, index) => {
      return (
        <IndividualEmailAnalytics
          key={`email_analytics_${index}`}
          index={index}
          data={item}
        />
      )
    })
  }

  function renderAdvisorOptions() {
    const els = []
    for (var agencyName in groupedAdvisors) {
      const agency = groupedAdvisors[agencyName]
      els.push(
        <Select.OptGroup
          key={`advisor_dropdown_${agencyName}`}
          label={agencyName}
        >
          {agency.advisors.map((advisor) => {
            return (
              <Select.Option
                key={`advisor_dropdown_${advisor.uid}`}
                value={advisor.uid}
              >
                {advisor.displayName}
              </Select.Option>
            )
          })}
        </Select.OptGroup>
      )
    }
    return els
  }

  return (
    <Card title="Analytics">
      {Object.keys(emailAnalytics)?.length ? null : (
        <Alert
          style={{ marginBottom: 20 }}
          message={
            currentUser?.attributes?.type === 'admin'
              ? "Selecting an advisor will generate an analytics report for number of campaign email opens and clicks specific to the advisor's client list."
              : `Generating an analytics report will return click and open rates for all account-related emails, and all campaign-related emails sent to your ${returnUserTypeBasedOnCurrentUserRole(
                  currentUser?.attributes?.type
                ).toLowerCase()}s.`
          }
          type="info"
        />
      )}
      {!generating ? (
        <>
          {currentUser?.attributes?.type === 'admin' ? (
            <ConfigProvider
              renderEmpty={() => (
                <FPEEmptyState description="Could not find any financial planners" />
              )}
            >
              <Select
                style={{ width: '100%', marginBottom: 15 }}
                placeholder="Select a financial planner"
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (option.children) {
                    return option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                      ? true
                      : false
                  } else if (option.label) {
                    return option.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                      ? true
                      : false
                  }
                }}
                onChange={(value) => {
                  setUid(value)
                }}
              >
                {renderAdvisorOptions()}
              </Select>
            </ConfigProvider>
          ) : null}
          <Button type="primary" onClick={getReports}>
            Generate Report
          </Button>
        </>
      ) : loading ? (
        <Spin
          tip="Generating report..."
          size="small"
          indicator={antIcon}
          style={{ width: '100%', color: '#000' }}
        />
      ) : !Object.keys(emailAnalytics)?.length ? (
        <FPEEmptyState
          description={'No data yet'}
          style={{ marginBottom: 0 }}
        />
      ) : null}
      <Row gutter={[16, 16]}>{renderData()}</Row>
    </Card>
  )
}

function mapStateToProps({
  session: { user },
  agencies: { data: agenciesData = [] },
  users: { data: usersData = [] },
}) {
  const agenciesMap = {}
  agenciesData.forEach((agency) => {
    agenciesMap[agency.uid] = agency
  })
  const advisors = usersData.filter((user) => {
    return user.type === ADVISOR_USER_TYPE
  })
  const groupedAdvisors = {}
  advisors.forEach((advisor) => {
    const agencyName = agenciesMap[advisor.agencyUid]?.name || 'Unassigned'
    if (!groupedAdvisors[agencyName]) {
      groupedAdvisors[agencyName] = {
        advisors: [advisor],
      }
    } else {
      groupedAdvisors[agencyName] = {
        ...groupedAdvisors[agencyName],
        advisors: [...(groupedAdvisors[agencyName].advisors || []), advisor],
      }
    }
  })

  return {
    groupedAdvisors: groupedAdvisors || {},
    currentUser: user,
  }
}

export default connect(mapStateToProps)(Analytics)
