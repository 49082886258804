import React from 'react'

import { Card, Col, Descriptions, Popconfirm, Row, Tag } from 'antd'

import { CLIENT_USER_TYPE } from '../../../features/session/redux/types'
import { formatUrl, openLink } from '../../helpers/linkingHelpers'
import UserLink from '../Links/UserLink'

function AdvisorContactCard({
  data,
  isPrimaryAdvisor,
  hasTitle = true,
  bordered = true,
  isClient = false,
  isLastItem = true,
}) {
  return (
    <Card
      bordered={bordered}
      style={{ marginBottom: isLastItem ? 0 : 24, boxShadow: 'none' }}
      bodyStyle={bordered ? undefined : { padding: '15px 0 0 0' }}
      title={
        hasTitle ? (
          <div>
            <span style={{ marginRight: 10 }}>{data.displayName}</span>
            {isPrimaryAdvisor ? (
              <Tag>Primary Financial Planner</Tag>
            ) : data?.title ? (
              <Tag>{data?.title}</Tag>
            ) : null}
          </div>
        ) : null
      }
    >
      <Row gutter={16}>
        {data?.type === CLIENT_USER_TYPE ? null : (
          <Col flex={'200px'}>
            <img
              src={data?.photoURL || global.DEFAULT_PHOTO_URL}
              style={{
                maxWidth: 200,
                width: '100%',
                aspectRatio: 1,
                objectFit: 'cover',
              }}
            />
          </Col>
        )}
        <Col xs={24} sm={16} md={18}>
          <Descriptions
            size="small"
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3 }}
          >
            {/* {data?.agencyUid ? (
              <Descriptions.Item label="Firm">
                <AgencyLink external={isClient} uid={data?.agencyUid} />
              </Descriptions.Item>
            ) : null} */}
            {data?.advisorUid ? (
              <Descriptions.Item label="Financial Planner">
                <UserLink uid={data?.advisorUid} />
              </Descriptions.Item>
            ) : null}
            {/* {data?.teamUid ? (
              <Descriptions.Item label="Team">
                <TeamLink disable={isClient} uid={data?.teamUid} />
              </Descriptions.Item>
            ) : null} */}
            <Descriptions.Item label="Email">
              <Popconfirm
                title="Open email client?"
                onConfirm={() => {
                  return openLink(`mailto:${data.email}`)
                }}
                okText="Open"
              >
                <a href="#">{data.email}</a>
              </Popconfirm>
            </Descriptions.Item>
            {data?.advisor ? (
              <Descriptions.Item label="Financial Planner">
                {data?.advisor ? (
                  <UserLink uid={data?.advisor?.uid} />
                ) : (
                  'Unassigned'
                )}
              </Descriptions.Item>
            ) : null}
            {data?.officePhoneNumber ? (
              <Descriptions.Item label="Office Phone">
                <a href={`tel:${data?.officePhoneNumber}`}>
                  {data?.officePhoneNumber}
                </a>
              </Descriptions.Item>
            ) : null}
            {data?.cellPhoneNumber ? (
              <Descriptions.Item label="Cell Phone">
                <a href={`tel:${data?.cellPhoneNumber}`}>
                  {data?.cellPhoneNumber}
                </a>
              </Descriptions.Item>
            ) : null}
            {data?.address ? (
              <Descriptions.Item label="Address">
                <a
                  target="_blank"
                  href={`http://maps.google.com/?q=${data?.address}`}
                  rel="noreferrer"
                >
                  {data?.address}
                </a>
              </Descriptions.Item>
            ) : null}
            {data?.website ? (
              <Descriptions.Item label="Website">
                <a
                  target="_blank"
                  href={formatUrl(data?.website)}
                  rel="noreferrer"
                >
                  {formatUrl(data?.website)}
                </a>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Col>
      </Row>
      {/* {renderAccounts()} */}
    </Card>
  )
}

export default AdvisorContactCard
